var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"d-flex py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"title my-auto"},[_vm._v("Daftar grup soal")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-actions',{staticClass:"py-0"},[_c('v-btn',_vm._g(_vm._b({staticClass:"color-navy-soft text-capitalize",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Sort by "),_c('v-icon',{attrs:{"right":""}},[_vm._v("$arrdown")])],1)],1)]}}])}),_c('v-text-field',{staticClass:"pt-1 mr-3 color-navy-soft mx-width-85 search-field",attrs:{"label":"Search","hide-details":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchGroup()},"click:append":function($event){return _vm.fetchGroup()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-icon',{staticClass:"color-navy-soft",attrs:{"slot":"append"},slot:"append"},[_vm._v("$search")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"background":"#fee9d3"},attrs:{"text":"","color":"primary"},on:{"click":_vm.back}},[_vm._v(" Kembali ")]),_c('v-spacer',{staticClass:"px-1 d-inline-block"}),(!_vm._.isEmpty(_vm.selected))?_c('v-btn',{attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.importGroups}},[_vm._v(" Add group to test ")]):_vm._e()],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"spacing-y-5 transparent",attrs:{"mobile-breakpoint":0,"hide-default-header":"","item-key":"id","single-expand":"","server-items-length":_vm.groups.total,"items-per-page":_vm.limit,"page":_vm.page,"headers":[{ value: 'text' }],"items":_vm.filteredGroups,"loading":_vm.table_loading},on:{"update:itemsPerPage":function($event){_vm.limit=$event},"update:items-per-page":function($event){_vm.limit=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',{staticClass:"flat-b-1 rounded-lg"},[_c('td',{staticClass:"white rounded-l-lg"},[_c('v-simple-checkbox',{attrs:{"color":"primary"},on:{"click":function($event){return select(!isSelected)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1),_c('td',{staticClass:"white"},[_c('div',{staticClass:"text-truncate",class:!!item.text || 'color--text text--lighten-4',style:(("width: " + _vm.contentWidth))},[_vm._v(" "+_vm._s(item.text || "(No instruction)")+" ")])]),_c('td',{staticClass:"white rounded-r-lg"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{style:({ transform: isExpanded ? 'rotate(-180deg)' : '' })},[_vm._v("$expand")])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"flat-b-1 white rounded-lg",attrs:{"colspan":"3"}},[_c('v-col',[_c('epot-input-grup-soal',{attrs:{"data":item,"disabled":""}})],1)],1)])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }