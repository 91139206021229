<template>
  <v-card class="shadow-0" elevation="0" style="background: transparent">

    <!-- NOTIFICATION -->
    <v-snackbar :color="notification.color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- CONFIRMATION DELETE -->
    <v-dialog v-model="dialog.confirmation" width="500">
      <v-form ref="destroyForm" @submit.prevent="destroy()">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Hapus Member</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            Apakah anda ingin menghapus member ini ?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="
                dialog.confirmation = false;
                form.id = '';
              "
              >Batal</v-btn
            >
            <v-btn @click="deleteMemberInstitution()" color="primary" elevation="0">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-row>
      <v-col cols="12" md="6" class="waiting__title">
        <h2 class="waiting__h4 text-capitalize"> {{ detailInstitution.name }} </h2>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn @click="actionExport()" class="pt-0 mb-3 mr-7" dense elevation="0">
          <v-icon color="#4CAF50" left>ri-file-excel-2-line</v-icon>
          Export Excel
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog.detail" persistent max-width="600px">
      <v-card class="rounded-lg">
        <v-card-title>
          Dialog Detail
          <v-spacer></v-spacer>
          <v-icon color="red" @click="close_dialog()">ri-close-line</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-simple-table class="px-3">
            <template>
              <tbody>
                <tr>
                  <th class="text-second pl-0" width="30%">Nama</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-left text-second">
                    {{ detail.fullname }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Email</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-left text-second">
                    {{ detail.email }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">No. Telepon</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-left text-second">
                    {{ detail.phone_number }}
                  </td>
                </tr>
                <tr>
                  <th class="text-second pl-0" width="30%">Lisensi</th>
                  <td width="5%" class="text-center text-second">:</td>
                  <td class="text-left text-second">
                    <v-row>
                      <v-col>
                        <v-chip class="font-weight-bold">
                          {{ detail.institusion.license == '' ? '-' : detail.institusion.license }}
                        </v-chip>
                      </v-col>
                      <v-col>

                        <input type="hidden" id="code-license" ref="link-referal" :value="`${detail.institusion.license}`">
                        <v-btn @click="copyLisensi()" color="primary" text small>
                          <v-icon left>ri-file-copy-line</v-icon>
                          copy
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row no-gutters class="py-0">
      <v-col cols="12" md="5">
        <v-card class="waiting__card" elevation="0">
          <div class="waiting__divider"></div>
          <v-card-title  class="waiting__title">
            <v-row no-gutters>
              <v-col cols="12">
                <h4 style="color: #652065;">Form Tambah Peserta </h4>
              </v-col>
              <v-col cols="12">
                <div style="background-color:#D4E5FE" class="pa-1 pl-3 rounded-lg mt-3">
                  <span style="color:#204DD4;font-size: 15px">Silahkan tambahkan data peserta dibawah</span>
                </div>
                <!-- <v-alert color="#D4E5FE">
                </v-alert> -->
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="
                overflow-y: auto;
                overflow-x: hidden;
                height: calc(100vh - 260px);
              ">
            <ValidationObserver ref="observer">
              <v-form>
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <p class="mb-1">Name</p>
                    <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                      <v-text-field
                        v-model="form.name"
                        filled
                        required
                        outlined
                        counter="50"
                        :error-messages="errors"
                        hide-details="auto">
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <p class="mb-1">Email</p>
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field
                        v-model="form.email"
                        filled
                        required
                        outlined
                        counter="30"
                        hide-details="auto"
                        :error-messages="errors">
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <p class="mb-1">Password</p>
                    <ValidationProvider name="Password" rules="required|min:8" v-slot="{ errors }">
                      <v-text-field
                        v-model="form.password"
                        filled
                        required
                        outlined
                        counter="20"
                        :type="show_password ? 'text' : 'password'"
                        :append-icon="show_password ? 'ri-eye-line' : 'ri-eye-off-line'"
                        @click:append="show_password = !show_password"
                        hide-details="auto"
                        :error-messages="errors">
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <p class="mb-1">No. Telepon</p>
                    <ValidationProvider name="Phone" rules="required|min:10|max:14" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.phone_number"
                      :error-messages="errors"
                      v-on:keyup.enter="saveMemberInstitution()"
                      filled
                      required
                      outlined
                      counter="15"
                      hide-details="auto">
                    </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              width="100"
              elevation="0"
              class="text-capitalize"
              :loading="process.run"
              :disabled="process.run"
              @click="resetForm(undefined, false)">
              Clear
            </v-btn>
            <v-btn 
              width="100"
              elevation="0"
              color="#652065"
              class="text-capitalize white--text"
              :loading="process.run"
              :disabled="process.run"
              @click="saveMemberInstitution()">
              Simpan
            </v-btn>
          </v-card-actions>
          <div class="waiting__divider"></div>
        </v-card>
      </v-col>
      <v-col cols="12" md="7" class="px-5">
        <div class="text-center mt-7" v-if="process.run == false && listData.total < 1">
          <div class="mt-5">
            <v-img :src="require('@/assets/image/data_empty.png')" width="200" class="mx-auto"></v-img>
          </div>
          <div class="mt-3">Data Peserta tes Lembaga Kosong</div>
          <div class="mt-2">Silahkan tambahkan pada form di sebelah kiri</div>
          <div class="mt-2"></div>
        </div>

        <div v-else class="waiting__bg">
          <v-card class="waiting__card" elevation="0">
            <div class="waiting__divider"></div>
            <v-card-title class="waiting__title">
              <v-row no-gutters>
                <v-col cols="12">
                  <h4 class="waiting__h4">List Peserta Tes {{ detailInstitution.name }}</h4>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="searching"
                    outlined
                    class="mt-3"
                    autocomplete="off"
                    dense
                    v-on:keyup.enter="initialize(searching,'')"
                    prepend-inner-icon="ri-search-line"
                    placeholder="Cari berdasarkan Nama . . ."
                    hide-details="auto">
                    <template v-slot:append v-if="searching">
                      <v-icon @click="searching = '';initialize()">ri-close-line</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text
              style="
                overflow-y: auto;
                overflow-x: hidden;
                height: calc(100vh - 250px);
              "
            >
              <div v-if="process.run" class="mt-3 mb-8">
                <div v-for="n in 10" :key="n">
                  <v-skeleton-loader
                    class="ma-auto my-3"
                    :loading="process.run"
                    :tile="false"
                    transition="scale-transition"
                    type="list-item-three-line">
                  </v-skeleton-loader>
                </div>
              </div>
              <div v-else>
                <v-card
                  class="my-2 rounded-lg"
                  style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px"
                  v-for="(item, index) in listData.list"
                  :key="index"
                >
                  <v-card-title>
                    <v-list-item class="px-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title>
                          <div
                            class="rounded-lg text-center mr-2"
                            :style="`max-width:100px !important; padding:.2rem .6rem; display: inline-block`"
                          >
                            {{ item.email }}
                          </div>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          class="subtitle-1 grey--text text--darken-1"
                          style=" padding:.2rem .6rem;"
                        >
                          <v-chip class="font-weight-bold">
                            {{ item.institusion.license == '' ? '-' : item.institusion.license }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content
                        class="text-right py-0"
                        style="max-width: 30%"
                      >
                        <v-list-item-title
                          class="
                            subtitle
                            grey--text
                            text--darken-1
                            font-weight-bold
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon small @click="showDetail(item)" v-bind="attrs" v-on="on" color="primary">
                                <v-icon>ri-eye-line</v-icon>
                              </v-btn>
                            </template>
                            <span>Lihat</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon small @click="deleteMember(item.id)" v-on="on" v-bind="attrs" color="red">
                                <v-icon>$delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-title>
                </v-card>
              </div>
            </v-card-text>
            <v-card-text
              :class="$vuetify.breakpoint.name === 'xs' ? 'pt-0' : ''"
            >
              <v-row
                justify="end"
                align="center"
                v-if="listData.total_page > 0"
              >
                <v-col
                  cols="12"
                  md="12"
                  class="d-flex align-center justify-end py-0"
                >
                  <div>
                    <v-btn text small @click="initialize()" color="green">
                      <v-icon left>ri-refresh-line</v-icon>
                      Refresh Data
                    </v-btn>
                  </div>
                  <div>
                    <v-subheader>
                      Total Data Peserta
                      {{ listData.total }}
                    </v-subheader>
                  </div>
                  <v-btn
                    icon
                    elevation="0"
                    class="text-capitalize px-0"
                    :disabled="listData.page == 1"
                    @click="listData.page--"
                  >
                    <v-icon>$prev</v-icon>
                  </v-btn>
                  <div class="mx-5" style="width: 75px">
                    <v-select
                      v-model="listData.page"
                      color="#30041c"
                      :items="
                        Array.from(
                          {
                            length: Math.ceil(
                              listData.total / listData.limit
                            ),
                          },
                          (_, i) => i + 1
                        )
                      "
                      auto-select-first
                      outlined
                      dense
                      single-line
                      hide-details
                      hide-selected
                    >
                    </v-select>
                  </div>
                  <v-btn
                    icon
                    elevation="0"
                    class="text-capitalize px-0"
                    :disabled="
                      listData.page ==
                      Math.ceil(listData.total / listData.limit)
                    "
                    @click="listData.page++"
                  >
                    <v-icon>$next</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <div class="waiting__divider"></div>
          </v-card>
        </div>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
import XLSX from 'xlsx'
export default {
  data() {
    return {
      detail: {
        institusion: { 
          license: ""
        }
      },
      detailInstitution: {},
      searching: "",
      show_password: false,
      form: {
        filter_place: "",
        // #Field Add
        id: "",
        form_status: 'add',
        name: "",
        email: "",
        password: "",
        phone_number: "",
      },
      dialog: {
        filtered: false,
        detail: false,
        confirmation: false,
      },
      process: {
        run: false,
      },
      listData: {
        list: [],
        limit: 10,
        page: 1,
        total: 10,
      },
      notification: {
        state: false,
        text: "",
        color: ""
      },
    }
  },
  watch: {
    "listData.limit": function () {
      this.initialize();
    },
    "listData.page": function () {
      this.initialize();
    },
  },
  components: {},
  computed: {},
  created() {
    if (this.$route.name.includes("member-lembaga")) {
      this.$emit("page-changed", 1, { title: "Member Lembaga", link: "#" });
    }
  },
  mounted(){
    this.initialize()
  },
  methods: {
    hashColor(str) {
      let hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      let c = (hash & 0x00ffffff).toString(16).toUpperCase();

      return "00000".substring(0, 6 - c.length) + c;
    },
    showDetail(item){
      this.detail = item
      this.detail.institusion.license = item.institusion.license
      this.dialog.detail = true
    },
    close_dialog(){
      this.dialog.detail = false
      this.detail = {
        institusion: {
          license: ''
        }
      }
    },
    copyLisensi(){
      /* Get the text field */
      var copyText = document.getElementById("code-license");

      /* Select the text field */
      copyText.select();
      // copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);
      
      /* Alert the copied text */
      this.notification.state = true;
      this.notification.color = "#4CAF50"
      this.notification.text = "Copied the text: " + copyText.value
    },

    // # METHOD EXPORT EXCEL
    ExportExcel() { // On Click Excel download button
      // console.log(this.listData);
      let json = []
      this.listData.list.forEach((item) => {
        json.push({
          "Nama": item.fullname,
          "Email": item.email,
          "No Telepon": item.phone_number,
          "Kode Lisensi": item.institusion.license,
        })
      })

      var wscols = [
      { width: 25 },  // first column
      { width: 25 }, // second column
      { width: 15 }, //...
      { width: 10 },
    ];
    
      // export json to Worksheet of Excel
      // only array possible
      var PoinWS = XLSX.utils.json_to_sheet(json) 
      PoinWS['!cols'] = wscols;
      // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons) 

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, PoinWS, 'animals') // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')   

      // export Excel file
      XLSX.writeFile(wb, `DATA_PESERTA_TES_${this.detailInstitution.name}.xlsx`) // name of the file is 'book.xlsx'
      this.notification.state = true,
      this.notification.color = "#4CAF50"
      this.notification.text =  "Export Excel Berhasil",
      this.listData.page = 1
      this.listData.limit = 10
      this.initialize('')
    },
    actionExport(){
      this.listData.limit = this.listData.total; 
      this.initialize('','export')
    },

    // # METHOD RESET FORM
    resetForm(item){

      this.form = {
        id: item == undefined ? "" : item.id,
        form_status:  item == undefined ? "add" : "update",
        name: item == undefined ? "" : item.name,
        phone_number: item == undefined ? "" : item.phone_number,
        email: item == undefined ? "" : item.email,
      }

       this.$refs.observer.reset()
    },

    deleteMember(id){
      this.form.id = id
      this.dialog.confirmation = true
    },

    // # METHOD API AXIOS
    async deleteMemberInstitution(){
      this.process.run = true;
      //let params = {'aku': 'kamu'}// institusion_ids: [this.$route.params.id]
      await this.axios.delete(`/users/v1/admin/member/delete/${this.form.id}`).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.process.run = false
          this.dialog.confirmation = false;
          this.notification.state = true;
          this.notification.color = "#4CAF50";
          this.notification.text = "Member berhasil di hapus";
          this.resetForm(undefined)
          this.initialize()
        }else {
          this.dialog.confirmation = false;
          this.process.run = false
        }
      }).catch(error => {
        this.process.run = false
        let message = error.message;
        if (error.response) {
          message = error.response.data.message;
        }

        this.dialog.confirmation = false;
        this.notification.state = true;
        this.notification.color = "error";
        this.notification.text = "Error: " + message;
        console.error(error);
      })
    },

    async saveMemberInstitution(){
      this.process.run = true;
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        let data = {
          institusion_id: this.$route.params.id,
          name: this.form.name,
          email: this.form.email,
          password: this.form.password,
          phone_number: this.form.phone_number,
        }
        await this.axios.post(`/users/v1/admin/institusion/create-member`, data).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.process.run = false
            this.notification.state = true;
            this.notification.color = "#4CAF50";
            this.notification.text = "Lembaga berhasil di tambahkan";
            this.resetForm(undefined)
            this.initialize()
          }else {
            this.process.run = false
          }
        }).catch(error => {
          this.process.run = false
          let message = error.message;
          if (error.response) {
            message = error.response.data.message;
          }

          this.notification.state = true;
          this.notification.color = "error";
          this.notification.text = "Error: " + message;
          console.error(error);
        })
      }else{
        this.process.run = false
      }
    },

    async initialize(searching,state){
      this.dialog.filtered = false;
      this.process.run = true;

      await this.axios.get("/users/v1/admin/member", {
          params: {
            q: searching,
            limit: this.listData.limit,
            page: this.listData.page,
            institusion_id: this.$route.params.id
          },
        })
        .then((res) => res.data)
        .then((res) => {
          res.data.list = res.data.list == null ? [] : res.data.list;
          this.listData = res.data;
          this.axios.get(`/institusion/v1/get/institusion/detail/${this.$route.params.id}`)
          .then((response) => {
            let res = response.data
            if (res.status == 200) {
              this.detailInstitution = res.data
            }
          }).catch((error) => {
            console.error(error);
            this.process.run = false;
            this.notification.state = true;
            this.notification.color = "#4CAF50";
            this.notification.text = "Pengambilan data detail Lembaga Gagal";
          })

          if (res.data.total == this.listData.list.length) {
            if (state == 'export') {
              this.ExportExcel()
              this.process.run = false;
            }
            this.process.run = false;
          }
          this.process.run = false;
        })
        .catch((error) => {
          console.error(error);
          this.process.run = false;
          this.dialog.filtered = false;
          this.certifLoading = false;
        });
    }
  }
}
</script>
<style lang="scss">
.waiting {
  &__card {
    // min-height: 80vh;
    // overflow-y: scroll;
    // height: calc(100vh - 430px);
    background: #f6f6f6 !important;
  }

  &__divider {
    width: 100%;
    padding: 0;
    border-top: 4px solid #652065 !important;
  }
  &__title {
    border-bottom: solid 1px #dedede;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
  }

  &__h4 {
    color: #652065;
  }

  .rotate {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
  }
}
</style>