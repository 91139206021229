<template>
  <v-col :id="`id-${_uid}`" class="py-0">
    <v-radio-group hide-details="auto" class="mt-0">
      <div
        class="d-flex answer"
        v-for="(opt, index) in options"
        :key="index"
        style="min-height: 40px"
      >
        <v-checkbox class="my-auto" disabled hide-details="auto"></v-checkbox>
        <v-text-field
          class="my-0 pt-0 option-field"
          v-if="opt.isEdit"
          v-model="opt.text"
          hide-details="auto"
          dense
          @keydown.enter="handlerEnter"
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon x-small color="primary" v-bind="attrs" v-on="on">
                  <v-icon size="24">ri-question-line</v-icon>
                </v-btn>
              </template>
              <span>
                Enter: Simpan<br />
                CTRL + Enter: Tambah baru
              </span>
            </v-tooltip>

            <v-btn icon x-small color="success" @click="saveOpt">
              <v-icon size="24">ri-checkbox-circle-line</v-icon>
            </v-btn>
            <v-btn icon x-small color="error" @click="delOpt(index)">
              <v-icon size="24">$delete</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <div
          class="d-flex"
          v-if="!opt.isEdit"
          @mouseover="showDelOpt(index)"
          @mouseleave="hideDelOpt(index)"
          style="width: 100%"
        >
          <div class="my-auto mr-2" @click="editOpt(index)">
            {{ opt.text }}
          </div>
          <v-tooltip v-if="isDuplicate(opt)" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="24" color="warning" v-bind="attrs" v-on="on">
                ri-alert-line
              </v-icon>
            </template>
            <span>Peringatan! Opsi duplikat</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn
            v-if="options[index].hover"
            icon
            x-small
            color="error"
            @click="delOpt(index)"
          >
            <v-icon size="24">$delete</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-if="!disabled" class="d-flex" style="min-height: 40px">
        <v-checkbox class="my-auto" disabled hide-details="auto"></v-checkbox>
        <div class="grey--text d-flex" @click="addOpt">
          <div class="my-auto">Tambah opsi</div>
        </div>
      </div>
    </v-radio-group>

    <v-row>
      <v-col pb-0>
        <label>Jawaban yang benar</label>
        <v-select
          filled
          outlined
          :disabled="disabled"
          :items="options"
          v-model="correct"
          hide-details="auto"
          multiple
        ></v-select>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "multiple-choice",
  data() {
    return {
      options: [],
      correct: [],
    };
  },
  props: {
    disabled: { type: Boolean, default: false },
    config: Object,
  },
  created() {
    if (this.config.options != undefined) {
      this.options = this.config.options;
    }
    if (this.config.correct != undefined) {
      this.correct = this.config.correct;
    }
  },
  methods: {
    addOpt() {
      this.saveOpt();
      this.options.push({
        text: "",
        isEdit: true,
      });
      setTimeout(() => {
        document
          .querySelector(`#id-${this._uid}  .option-field .v-text-field__slot input[type=text]`)
          .focus();
      }, 50);
    },
    editOpt(index) {
      if (!this.disabled) {
        this.saveOpt();
        this.options.forEach((opt) => (opt.isEdit = false));
        this.options[index].isEdit = true;
        setTimeout(() => {
          document
            .querySelector(".option-field .v-text-field__slot input[type=text]")
            .focus();
        }, 50);
      }
    },
    saveOpt() {
      this.options = this.options.filter((opt) => {
        opt.text = opt.text.trim();
        return opt.text != "";
      });
      this.options.forEach((opt) => (opt.isEdit = false));
    },
    delOpt(index) {
      this.options.splice(index, 1);
    },
    showDelOpt(index) {
      if (!this.disabled) {
        this.$set(this.options[index], "hover", true);
      }
    },
    hideDelOpt(index) {
      if (!this.disabled) {
        this.$set(this.options[index], "hover", false);
      }
    },
    isDuplicate(opt) {
      return (
        this.options.filter(
          (item) => item.text.toLowerCase() == opt.text.toLowerCase()
        ).length != 1
      );
    },
    handlerEnter(e) {
      if (e.ctrlKey) {
        this.addOpt();
      } else {
        this.saveOpt();
      }
    },
    updateConfig() {
      this.$emit("update", { options: this.options, correct: this.correct });
    },
  },
  watch: {
    options() {
      this.updateConfig();
    },
    correct() {
      this.updateConfig();
    },
    config: {
      handler: function () {
        if (this.config.options != undefined) {
          this.options = this.config.options;
        }
        if (this.config.correct != undefined) {
          this.correct = this.config.correct;
        }
      },
      deep: true,
    },
  },
};
</script>