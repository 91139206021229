<template>
  <v-row>
    <template v-if="loading">
      <v-col md="6">
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      </v-col>
    </template>
    <template v-else-if="error">
      <v-col>
        <div class="error--text">
          {{ error }}
          <v-btn x-small fab color="info" @click="fetchQuestion" elevation="0">
            <v-icon>$refresh</v-icon>
          </v-btn>
        </div>
      </v-col>
    </template>
    <template v-else>
      <v-col md="6">
        <p class="mb-2">Pertanyaan</p>
        <v-textarea
          :readonly="isDisabled"
          class="mb-2"
          outlined
          filled
          color="primary"
          v-model="question.question.text"
          hide-details="auto"
        >
        </v-textarea>
        <answer-type
          :disabled="isDisabled"
          :type="question.type"
          :data="question"
          @update="Object.assign(question, $event)"
        />
      </v-col>
      <v-col md="6">
        <div class="mb-2">Attachment</div>
        <v-row no-gutters style="max-width: 100%">
          <image-attachment
            v-if="isMediaSet('image')"
            :disabled="isDisabled"
            class="mr-2 d-inline-block"
            :src="question.question"
            @update="
              $set(question, 'question', {
                ...question.question,
                ...$event,
              })
            "
          />
          <audio-attachment
            v-if="isMediaSet('audio')"
            :disabled="isDisabled"
            class="d-inline-block"
            :src="question.question"
            @update="
              $set(question, 'question', {
                ...question.question,
                ...$event,
              })
            "
          />
          <span v-if="isDisabled && _.isEmpty(question.question.media)">
            {{ "No media" }}
          </span>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import AudioAttachment from "../../_inc/modal/AudioAttachment.vue";
import ImageAttachment from "../../_inc/modal/ImageAttachment.vue";
import AnswerType from "../../_inc/options/AnswerType.vue";
// import ValidationMixins from "@/views/_mixins/validation";

export default {
  // mixins: [ValidationMixins],
  components: {
    AnswerType,
    ImageAttachment,
    AudioAttachment,
  },
  props: {
    data: Object,
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      error: "",
      question: {},
    };
  },
  created() {
    this.fetchQuestion();
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        this.loading ||
        (this.data.status && this.data.status != "draft")
      );
    },
  },
  methods: {
    fetchQuestion() {
      this.loading = true;
      this.question = {
        question: {},
        type: "single_choice",
        options: [],
        correct_answer: "",
      };

      if (this.data.id) {
        this.$store
          .dispatch("question/detail", this.data.id)
          .then((res) => {
            let question = res.data;
            // question.question.with_media = !this._.isEmpty(
            //   question.question.media
            // );
            this.question = question;
            this.$emit("update", this.question);
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.error = error;
            this.loading = false;
          });
      } else {
        Object.assign(this.question, this.data);
        this.loading = false;
      }
    },
    isMediaSet(type) {
      let disabled = this.isDisabled;
      let notset =
        this.question.question.with_media ||
        (this.question.question.media && this.question.question.media.url);
      let isset =
        (this.question.question.with_media || (this.question.question.media && this.question.question.media.url)) &&
        (this.question.question.media && this.question.question.media.type == type)
      return !disabled && !notset || isset;
      // return (
      //   !this.isDisabled &&
      //   !(this.question.question.with_media || this.question.question.media.url) ||
      //   ((this.question.question.with_media || this.question.question.media.url) &&

      // );
    },
  },
  watch: {
    "data.id": function () {
      this.fetchQuestion();
      // handler: function () {
      // },
      // deep: true,
    },
    question: {
      handler: function (question) {
        if (!this.isDisabled) {
          this.$emit("update", question);
        }
      },
      deep: true,
    },
  },
};
</script>