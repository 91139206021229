export default { 
  Auth: require('./auth').default,
  Bank: require('./bank').default,
  Dashboard: require('./Dashboard').default,
  Errors: require('./errors').default,
  Class: require('./class').default,
  Epot: require('./epot').default,
  Certificate: require('./certificate').default,
  Lisence: require('./license').default,
  Order: require('./order').default,
  Member: require('./member').default,
  Institution: require('./institution').default,
  Capture_user: require('./capture_user').default,
  Import: require('./import').default,
  Maintenance: require('./maintenance').default
}
