<template>
  <v-col class="py-0">
    <v-combobox
      multiple
      v-model="words"
      :items="words"
      hide-selected
      hint="Masukkan masing-masing satu kata"
      persistent-hint
      chips
      filled
      outlined
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Tekan <kbd>Enter</kbd> untuk membuat kata
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </v-col>
</template>

<script>
export default {
  name: "sorting-answer",
  props: {
    config: Object,
  },
  data() {
    return {
      words: [],
    };
  },
  created() {
    if (this.config.words != undefined) {
      this.words = this.config.words;
    }
  },
  methods: {
    updateConfig() {
      this.$emit("update", { words: this.words });
    },
  },
  watch: {
    words() {
      this.updateConfig();
    },
  },
};
</script>
