import axios from 'axios'
import url from './api'

const offline = true

const state = () => {
  return {
    grouped_questions: []
  }
};

const mutations = {
  fetch(state, payload) {
    state.grouped_questions = payload.data
  },
  create(state, payload) {
    let grouped_questions = JSON.parse(localStorage.getItem('ytc_grouped_questions')) || [];
    state.grouped_questions.push(payload.data)
    grouped_questions.push(payload.data)
    localStorage.setItem('ytc_grouped_questions', JSON.stringify(grouped_questions))
  },
  // FORNEXT: Untuk pengembangan berikutnya yang bisa mengedit soal yang telah disimpan dalam draft
  update(state, payload) {
    let grouped_questions = JSON.parse(localStorage.getItem('ytc_grouped_questions')) || [];
    let new_grouped_questions = grouped_questions.map(question => question.id == payload.data.id ? Object.assign(question, payload.data) : question)
    state.grouped_questions = new_grouped_questions
    localStorage.setItem('ytc_grouped_questions', JSON.stringify(new_grouped_questions))
  },
  delete(state, id) {
    let index = state.grouped_questions.findIndex(question => question.id == id)
    if (index == -1) return
    state.grouped_questions.splice(index, 1)
  },
  clear(state) {
    state.grouped_questions = []
  }
};

const getters = {
  detail: state => id => {
    return state.grouped_questions.find(question => question.id == id)
  },
  list: () => params => {
    let offset = (params.page - 1) * params.page
    let grouped_questions = JSON.parse(localStorage.getItem('ytc_grouped_questions')) || [];
    return {
      limit: params.limit,
      list: grouped_questions.slice().splice(offset, params.limit),
      page: params.page,
      total: grouped_questions.length
    }
  }
};

const actions = {
  async list({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      if (offline) {
        setTimeout(() => {
          resolve({
            status: 200,
            message: 'success',
            data: getters.list(params)
          })
        }, 1000);
      } else {
        axios.get(url.question_list, {
          params: Object.assign({ page: 1, limit: 10 }, params)
        })
          .then(res => { commit('fetch', res.data); resolve(res.data) })
          .catch(error => { commit('clear'); reject(error) })
      }
    })
  },

  async detail({ commit, getters }, id) {
    return new Promise((resolve, reject) => {
      let data = getters.detail(id)
      if (data) {
        resolve(url.success(data))
      } else {
        if (offline) {
          setTimeout(() => {
            let grouped_questions = JSON.parse(localStorage.getItem('ytc_grouped_questions')) || []
            let question = grouped_questions.find(question => question.id == id)
            resolve(url.success(question))
          }, 500);
        } else {
          axios.get(url.question_detail.replace('{id}', id))
            .then(res => { commit('create', res.data); resolve(res.data) })
            .catch(error => { commit('error'); reject(error) })
        }
      }
    })
  },

  async detail_public({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(url.question_detail_public.replace('{id}', id))
        .then(res => { commit('create', res.data); resolve(res.data) })
        .catch(error => { commit('error'); reject(error) })
    })
  },

  async create({ commit }, group) {
    return new Promise((resolve, reject) => {
      if (offline) {
        try {
          group.id = url.get_id();
          commit('create', { data: group })
          resolve(url.success(group))
        } catch (error) {
          reject(url.error(error))
        }
      } else {
        axios.post(url.question_create, group)
          .then(res => { commit('create', res.data); resolve(res.data) })
          .catch(error => { commit('error'); reject(error) })
      }
    })
  },

  async update({ commit }, question) {
    return new Promise((resolve, reject) => {
      axios.put(url.question_update.replace('{id}', question.id), question)
        .then(res => { commit('update', res.data.data); resolve(res.data) })
        .catch(error => { commit('error'); reject(error) })
    })
  },

  async delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(url.question_delete.replace('{id}', id))
        .then(res => { commit('delete', id); resolve(res.data) })
        .catch(error => { commit('error'); reject(error) })
    })
  },
};

export default { namespaced: true, state, mutations, actions, getters }