import axios from "axios";
import url from "./api";

const access_key = process.env.VUE_APP_ACCESS_KEY;

const state = () => {
  return {
    user: null,
  };
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  },
};

const getters = {
  user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token,
};

const actions = {
  async login({ commit }, user) {
    return new Promise((resolve, reject) => {
      user.access_key = access_key;
      axios
        .post(url.admin_login, user, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => res.data)
        .then((res) => {
          let user = res.data;
          let token = `Bearer ${user.token}`;

          axios.defaults.headers.common["Authorization"] = token;
          localStorage.setItem("ytc_auth", JSON.stringify(user));
          commit("SET_USER", user);
          resolve("success");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async logout({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_USER");
      localStorage.removeItem("ytc_auth");
      delete axios.defaults.headers.common["Authorization"];
      resolve("logged out");
    });
  },

  // async update_password({ commit }, data) {
  //   return new Promise((resolve, reject) => {
  //     axios.put(url.admin_update_password, data)
  //       .then(res => { resolve(res.data) })
  //       .catch(error => { reject(error) })
  //   })
  // },

  async get_user({ commit }) {
    if (!localStorage.getItem("ytc_auth")) {
      localStorage.removeItem("ytc_auth");
      return;
    }
    let user = JSON.parse(localStorage.getItem("ytc_auth"));
    let token = `Bearer ${user.token}`;
    
    axios.defaults.headers.common["Authorization"] = token;
    commit("SET_USER", user);
    await axios
      .get("/users/v1/admin/detail")
      .then(() => {
        commit("SET_USER", user);
      })
      .catch((error) => {
        commit("RESET_USER");
        localStorage.removeItem("ytc_auth");
        delete axios.defaults.headers.common["Authorization"];
        console.error(error);
      });
  },
};

export default { state, mutations, getters, actions };
