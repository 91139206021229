var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data.list || [],"items-per-page":_vm.data.limit,"page":_vm.data.page,"server-items-length":_vm.data.total,"loading":_vm.dataLoading,"hide-default-footer":"","disable-filtering":"","disable-sort":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.data, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.data, "page", $event)}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("datetime")(item.created_at)))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('v-row',{staticClass:"px-4"},[_c('div',{staticClass:"pa-3"},[_c('v-row',{attrs:{"align":"center"}},[_c('div',[_c('v-subheader',{domProps:{"textContent":_vm._s('Row per page: ')}})],1),_c('div',[_c('v-select',{staticClass:"mt-0 pt-0",staticStyle:{"width":"4rem"},attrs:{"items":[10, 20, 50, 100],"hide-details":"auto"},model:{value:(_vm.data.limit),callback:function ($$v) {_vm.$set(_vm.data, "limit", $$v)},expression:"data.limit"}})],1)])],1),_c('v-col',[_c('v-row',{staticClass:"mx-3",attrs:{"justify":"end","align":"center"}},[_c('div',{staticClass:"d-flex flex-nowrap align-center"},[_c('v-subheader',{staticClass:"mr-2"},[_vm._v("Halaman:")]),_c('v-select',{staticClass:"pa-0 ma-0 mr-2",staticStyle:{"width":"5rem"},attrs:{"dense":"","solo":"","hide-details":"","items":Array.from(
                        { length: Math.ceil(_vm.data.total / _vm.data.limit) },
                        function (_, i) { return i + 1; }
                      )},model:{value:(_vm.data.page),callback:function ($$v) {_vm.$set(_vm.data, "page", $$v)},expression:"data.page"}}),_c('v-subheader',[_vm._v(" Menampilkan data ke "+_vm._s(props.pagination.pageStart + 1)+" sampai "+_vm._s(props.pagination.pageStop)+" dari "+_vm._s(props.pagination.itemsLength)+" ")])],1),_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.data.page == 1},on:{"click":function($event){_vm.data.page--}}},[_c('v-icon',[_vm._v("$prev")])],1),_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.data.page == Math.ceil(_vm.data.total / _vm.data.limit)},on:{"click":function($event){_vm.data.page++}}},[_c('v-icon',[_vm._v("$next")])],1)],1)],1)],1)]}}])}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-col',{attrs:{"md":"6","offset-md":"3"}},[_c('div',{staticClass:"title text-center"},[_c('v-img',{staticClass:"mb-4 mx-auto",attrs:{"src":require("@/assets/images/data_empty.png"),"width":"66.667%"}}),_vm._v(" Lisensi tidak ditemukan ")],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }