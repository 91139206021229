<template>
  <v-col class="py-0">
    <label>Izinkan jenis file tertentu</label>
    <v-row>
      <v-col
        class="py-0"
        v-for="format in conf.file_type"
        sm="4"
        :key="format.val"
      >
        <v-checkbox
          hide-details="auto"
          v-model="allowed"
          :label="format.text"
          :value="format.val"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col sm="6">
        <label>Ukuran file maksimal</label>
        <v-select
          outlined
          filled
          hide-details="auto"
          v-model="max_size"
          :items="conf.max_size"
          item-value="val"
          item-text="text"
        ></v-select>
      </v-col>

      <v-col sm="6">
        <label>Maksimal unggahan</label>
        <v-select
          outlined
          filled
          hide-details="auto"
          v-model="max_num"
          :items="conf.max_num"
        ></v-select>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "upload-file",
  data() {
    return {
      conf: {
        file_type: [
          { text: "Dokumen", val: "document" },
          { text: "Gambar", val: "picture" },
          { text: "Video", val: "video" },
          { text: "Audio", val: "audio" },
          { text: "PDF", val: "pdf" },
        ],
        max_num: [1, 2, 3, 4, 5],
        max_size: [
          { text: "500 KB", val: "0.5" },
          { text: "1 MB", val: "1" },
          { text: "2 MB", val: "2" },
          { text: "5 MB", val: "5" },
          { text: "10 MB", val: "10" },
        ],
      },
      allowed: [],
      max_num: null,
      max_size: null,
    };
  },
  props: {
    config: Object,
  },
  created() {
    if (this.config.allowed != undefined) {
      this.allowed = this.config.allowed;
    }
    if (this.config.max_num != undefined) {
      this.max_num = this.config.max_num;
    }
    if (this.config.max_size != undefined) {
      this.max_size = this.config.max_size;
    }
  },
  methods: {
    updateConfig() {
      this.$emit("update", {
        allowed: this.allowed,
        max_num: this.max_num,
        max_size: this.max_size,
      });
    },
  },
  watch: {
    allowed() {
      this.updateConfig();
    },
    max_num() {
      this.updateConfig();
    },
    max_size() {
      this.updateConfig();
    },
  },
};
</script>

<style>
</style>