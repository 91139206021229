<template>
  <v-row align-content="center">
    <v-col class="py-0" md="6">
      <v-row>
        <v-col class="white px-12" md="11" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <transition name="fade">
            <v-form class="px-8" v-if="!sent" @submit.prevent="submit">
              <div class="title mb-4">
                Masukan email anda dan kami akan mengirimkan link untuk
                memperbarui password anda
              </div>
              <v-row>
                <v-col>
                  <div class="subtitle-2 mb-2">Email</div>
                  <v-text-field
                    filled
                    v-model="email"
                    placeholder="Masukkan email"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-spacer class="py-2"></v-spacer>
              <v-row>
                <v-col>
                  <v-btn
                    large
                    elevation="0"
                    type="submit"
                    color="primary"
                    :loading="submiting"
                    block
                  >
                    Kirim link ke email
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <div class="px-8" v-else>
              <v-row class="mb-4" justify="center">
                <v-col sm="6" lg="4">
                  <v-img src="@/assets/images/link_sent.png"></v-img>
                </v-col>
              </v-row>
              <div class="headline mb-6 text-center">Link berhasil dikirim</div>
              <div class="subtitle-1 text-center">
                Cek email anda kemudian buka link yang telah kami kirimkan untuk
                melanjutkan proses
              </div>
            </div>
          </transition>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "forgot-password",
  data() {
    return {
      errors: [],
      email: "",
      submiting: false,
      sent: false,
    };
  },
  methods: {
    submit() {
      this.submiting = true;
      setTimeout(() => {
        // this.$store
        //   .dispatch("forgotPassword", this.email)
        //   .then(() => {
        //     this.submiting = false;

        //   })
        //   .catch((errors) => {
        //     this.submiting = false;
        //     // console.log(errors);
        //     if (errors.response != undefined) {
        //       this.errors = errors.response.data.errors;
        //     }
        //   });
        this.sent = true;
        this.submiting = false;
      }, 500);
    },
    hasError(input) {
      return this._.has(this.errors, input);
    },
  },
};
</script>

<style>
</style>