<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-tabs v-model="tabs" background-color="transparent">
          <v-tab class="text-capitalize">Pengaturan</v-tab>
          <v-tab class="text-capitalize" :disabled="!settingsFilled"
            >Soal</v-tab
          >
        </v-tabs>

        <v-tabs-items class="my-2 transparent" v-model="tabs">
          <v-tab-item>
            <v-card elevation="0" rounded="lg" outlined>
              <v-card-text class="pb-2">
                <form @submit.prevent="submit" class="pl-3 pr-3">
                  <v-row>
                    <!-- Nama paket (auto generated) -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Nama" rules="required"> -->
                      <p class="mb-2">Nama Paket</p>
                      <v-text-field
                        v-model="settings.name"
                        filled
                        required
                        outlined
                        autofocus
                        hide-details="auto"
                      ></v-text-field>
                      <!-- </validation-provider> -->
                    </v-col>
                    <!-- Timer -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Retake" rules="required"> -->
                      <p class="mb-2">Section</p>
                      <v-select
                        filled
                        v-model="settings.section"
                        :items="sections"
                        item-text="name"
                        item-value="name"
                        required
                        outlined
                        hide-details="auto"
                      >
                      </v-select>
                      <!-- </validation-provider> -->
                    </v-col>
                    <!-- Is question random -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Question" rules="required"> -->
                      <p class="mb-2">Select Part</p>
                      <v-select
                        filled
                        outlined
                        v-model="settings.part"
                        :items="
                          parts.filter(
                            (part) => part.section == settings.section
                          )
                        "
                        item-value="name"
                        item-text="name"
                        hide-details="auto"
                      >
                      </v-select>
                    </v-col>
                    <!-- Is option random -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Answer Choice" rules="required"> -->
                      <p class="mb-2">Random Question</p>
                      <v-select
                        :items="randoms"
                        item-value="val"
                        item-text="text"
                        filled
                        v-model="settings.random"
                        required
                        outlined
                        hide-details="auto"
                      ></v-select>
                      <!-- </validation-provider> -->
                    </v-col>
                    <!-- Section -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Question" rules="required"> -->
                      <div class="mb-2">Part direction</div>
                      <v-textarea
                        class=""
                        outlined
                        filled
                        color="primary"
                        v-model="settings.direction.text"
                        placeholder="Direction"
                        hide-details="auto"
                      >
                      </v-textarea>
                      <!-- </validation-provider> -->
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Answer Choice" rules="required"> -->
                      <div class="mb-2">Attachment</div>
                      <v-row no-gutters style="max-width: 100%">
                        <audio-attachment
                          :src="settings.direction.media"
                          @update="settings.direction.media = $event"
                        />
                      </v-row>
                      <!-- FORNEXT: Change set-attachment to update -->
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-2">Close statement</div>
                      <v-textarea
                        outlined
                        filled
                        color="primary"
                        v-model="settings.close_statement.text"
                        placeholder="Statement"
                        hide-details="auto"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-2">Attachment</div>
                      <v-row no-gutters style="max-width: 100%">
                        <audio-attachment
                          :src="settings.close_statement.media"
                          @update="settings.close_statement.media = $event"
                        />
                      </v-row>
                    </v-col>
                  </v-row>
                </form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="background pa-4">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  large
                  :disabled="!settingsFilled"
                  @click="tabs++"
                  elevation="0"
                  >Lanjut</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card elevation="0" color="transparent">
              <v-card-text class="pa-0">
                <v-row v-if="$route.name == 'epot-create'">
                  <v-col class="py-0">
                    <!-- <div class="col-md-12"> -->
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="(question, index) in questions"
                        cols="12"
                        class="pa-0 mb-4 rounded-lg flat-b-1 custom-panel"
                        :key="question.id || index"
                      >
                        <v-expansion-panel-header>
                          <div class="title">
                            {{
                              question.questions == undefined
                                ? "Nomor"
                                : "Group"
                            }}
                            {{ index + 1 }}
                          </div>
                          <v-spacer></v-spacer>
                          <div class="text-right">
                            <v-btn
                              icon
                              @click.stop="confirmDelete(question.id || index)"
                            >
                              <v-icon> $delete </v-icon>
                            </v-btn>
                          </div>
                          <template v-slot:actions>
                            <v-btn icon class="ml-2">
                              <v-icon>$expand</v-icon>
                            </v-btn>
                          </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <template v-if="question.questions == undefined">
                            <epot-input-soal
                              :data="question"
                              @update="$set(questions, index, $event)"
                            />
                          </template>

                          <template v-else>
                            <epot-input-grup-soal
                              :data="question"
                              :sn="index + 1"
                              @update="$set(questions, index, $event)"
                            />
                            <!-- input grup soal -->
                          </template>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- <inputSoal v-for="(soal, index) in soal" :key="index"/> -->
                    <!-- </div> -->

                    <v-dialog
                      v-model="delete_question.show"
                      persistent
                      max-width="600px"
                    >
                      <v-card>
                        <v-card-title>
                          <span>
                            <v-icon>$warning</v-icon> Hapus Pertanyaan
                          </span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <div>
                          <v-card-text>
                            Apakah anda ingin menghapus pertanyaan ini ?
                          </v-card-text>
                        </div>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            large
                            @click="cancelDelete()"
                          >
                            Batal
                          </v-btn>
                          <v-btn
                            class="white--text"
                            color="primary"
                            large
                            @click="deleteQuestion(delete_question.id)"
                          >
                            Hapus
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-row no-gutters>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                            class="primary--text mr-2"
                            elevation="0"
                          >
                            Tambah Soal
                            <v-icon class="ml-1">$arrdown</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="addQuestion">
                            <v-list-item-title>Buat Baru</v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item :to="{ name: 'import-questions' }">
                            <v-list-item-title
                              >Ambil dari bank soal</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                            class="primary--text"
                            elevation="0"
                          >
                            Tambah Group Soal<v-icon class="ml-1"
                              >$arrdown</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="addGroup">
                            <v-list-item-title>Buat Baru</v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item :to="{ name: 'import-question-groups' }">
                            <v-list-item-title
                              >Ambil dari bank soal</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-spacer></v-spacer>
                      <v-menu offset-y v-if="!!questions.length">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :loading="saving"
                            class="white--text"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            elevation="0"
                          >
                            Submit
                            <v-icon class="ml-2">$arrdown</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="save('published')">
                            <v-list-item-title
                              >Simpan dan Publish</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item @click="save('draft')">
                            <v-list-item-title
                              >Simpan di draft</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                  </v-col>
                </v-row>

                <!---------------------->

                <v-row v-else>
                  <v-col class="py-0">
                    <router-view @import-questions="add"></router-view>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-snackbar top v-model="snackbar.show">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import EpotInputSoal from "./components/EpotInputSoal";
import EpotInputGrupSoal from "./components/EpotInputGrupSoal.vue";
import AudioAttachment from "../_inc/modal/AudioAttachment.vue";

export default {
  name: "epot-create",
  components: {
    EpotInputSoal,
    EpotInputGrupSoal,
    AudioAttachment,
  },
  data() {
    return {
      tabs: null,
      saving: false,
      sections: [
        { id: 2, name: "Listening" },
        { id: 3, name: "Reading" },
        { id: 1, name: "Structure" },
      ],

      parts: [
        { id: 1, name: "Part A", section: "Structure" },
        { id: 2, name: "Part B", section: "Structure" },
        { id: 3, name: "Part A", section: "Listening" },
        { id: 4, name: "Part B", section: "Listening" },
        { id: 5, name: "Part C", section: "Listening" },
        { id: 6, name: "Tanpa Part", section: "Reading" },
      ],

      randoms: [
        { val: "yes", text: "Ya" },
        { val: "no", text: "Tidak" },
      ],

      settings: {
        name: null,
        section: null,
        part: null,
        random: null,
        direction: {
          text: null,
          with_media: false,
          media: null,
        },
        close_statement: {
          text: null,
          with_media: false,
          media: null,
        },
      },

      questions: [],

      delete_question: {
        show: false,
        id: null,
      },

      snackbar: {
        show: false,
        message: "",
      },
    };
  },
  computed: {
    settingsFilled() {
      let status = true;

      Object.keys(this.settings).forEach((key) => {
        if (key == "direction" || key == "close_statement") {
          if (!this.settings[key].text) {
            status = false;
          }
        } else if (!this.settings[key]) {
          status = false;
        }
      });

      return status;
    },
  },
  created() {
    this.settings = {
      name: "Paket",
      section: this.sections.find((section) => section.id == 1).name,
      part: this.parts.find((part) => part.id == 1).name,
      random: "yes",
      direction: {
        text: "Direction",
        with_media: false,
        media: null,
      },
      close_statement: {
        text: "Close statement",
        with_media: false,
        media: null,
      },
    };
  },
  methods: {
    confirmDelete(id) {
      this.delete_question.show = true;
      this.delete_question.id = id;
    },
    cancelDelete() {
      this.delete_question.show = false;
      this.delete_question.id = null;
    },
    deleteQuestion(id) {
      // FIXME: Lagi bisa yang index, yang id belum
      let index;

      index = this.questions.findIndex((question) => question.id == id);
      if (index == -1) {
        index = id;
      }
      this.questions.splice(index, 1);

      this.delete_question.show = false;
      this.delete_question.id = null;
    },
    save(status) {
      this.saving = true;
      this.$store
        .dispatch("epot/create", {
          ...this.settings,
          status: status,
          author: "Avatar Aang",
          // author: this.$store.getters.auth.id,
        })
        .then(() => {
          this.saving = false;
          this.$router.push({ name: "epot-list" });
        })
        .catch(() => {
          this.saving = false;
        });
    },
    add(question) {
      if (this.questions.find((val) => val.id == question.id) == undefined) {
        this.questions.push(question);
      } else {
        this.snackbar.show = true;
        this.snackbar.message = "Some questions already imported";
      }
    },
    addQuestion() {
      this.questions.push({});
    },
    addGroup() {
      this.questions.push({
        questions: [{}],
      });
    },
  },
};
</script>
