<template>
  <v-row>
    <v-col md="6">
      <div class="mb-2">Group instruction</div>
      <v-textarea
        :readonly="isDisabled"
        outlined
        v-model="data.text"
        filled
        placeholder="Write the instruction"
        hide-details="auto"
      >
      </v-textarea>
    </v-col>
    <v-col md="6">
      <!-- FORNEXT: Using media admin -->
      <div class="mb-2">Attachment</div>
      <v-row no-gutters style="max-width: 100%">
        <image-attachment
          v-if="isMediaSet('image')"
          :disabled="isDisabled"
          class="mr-2 d-inline-block"
          :src="data"
          @update="$emit('update', { ...data, ...$event })"
        />
        <audio-attachment
          v-if="isMediaSet('audio')"
          :disabled="isDisabled"
          class="d-inline-block"
          :src="data"
          @update="$emit('update', { ...data, ...$event })"
        />
      </v-row>
    </v-col>

    <template v-for="(question, index) in data.list_question">
      <v-col cols="12" :key="question.id || index">
        <v-divider></v-divider>
        <v-col
          class="py-0 mt-6"
          :class="
            question.id
              ? question.status != 'draft' || 'primary lighten-5'
              : 'error lighten-5'
          "
        >
          <v-card elevation="0" color="transparent">
            <v-card-title class="px-0">
              <div class="title">
                Nomor {{ sn != 0 ? `${sn + 1}.` : "" }}{{ index + 1 }}
              </div>
              <v-spacer></v-spacer>
              <div v-if="!isDisabled" class="text-right">
                <v-btn
                  :loading="saving || question.saving"
                  v-if="!question.id || question.status == 'draft'"
                  color="success"
                  icon
                  @click.stop="confirmSave(question)"
                >
                  <v-icon>$complete</v-icon>
                </v-btn>
                <v-btn icon @click="confirmDelete(index)">
                  <v-icon> $delete </v-icon>
                </v-btn>
              </div>
            </v-card-title>

            <v-card-text class="white mx-n3 width" style="width: auto">
              <exam-input-soal
                :disabled="isDisabled"
                :data="question"
                @update="$set(data.list_question, index, $event)"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
    </template>

    <v-col cols="12">
      <v-menu v-if="!isDisabled" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="accent-primary" elevation="0">
            Tambah soal ke group
            <v-icon class="ml-1">$arrdown</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="addQuestion">
            <v-list-item-title>Buat Baru</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            :to="{
              name: $route.name + '-import-questions',
              params: { index: sn },
            }"
          >
            <v-list-item-title>Ambil dari bank soal</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <!-- Save confirm dialog -->
    <v-dialog
      v-if="!disabled"
      v-model="save_question.show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span> <v-icon>$warning</v-icon> Simpan Pertanyaan </span>
        </v-card-title>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            Apakah anda ingin menyimpan pertanyaan ini?<br />
            <strong>
              <v-icon color="warning">$warning</v-icon>
              Pertanyaan yang telah disimpan ke bank soal tidak dapat di edit
              kembali.
            </strong>
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="cancel()"> Batal </v-btn>
          <v-btn
            class="white--text"
            color="primary"
            @click="saveQuestion(save_question.question, 'draft')"
          >
            Simpan draft
          </v-btn>
          <v-btn
            class="white--text"
            color="primary"
            @click="saveQuestion(save_question.question, 'publish')"
          >
            Simpan ke bank soal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="!isDisabled"
      v-model="delete_question.show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span> <v-icon>$warning</v-icon> Hapus Pertanyaan </span>
        </v-card-title>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            Apakah anda ingin menghapus pertanyaan ini ?
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" large @click="cancel()">
            Batal
          </v-btn>
          <v-btn
            class="white--text"
            color="primary"
            large
            @click="deleteQuestion(delete_question.id)"
          >
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AudioAttachment from "../../_inc/modal/AudioAttachment.vue";
import ImageAttachment from "../../_inc/modal/ImageAttachment.vue";
import EpotInputSoal from "./EpotInputSoal.vue";

export default {
  name: "exam-input-grup-soal",
  components: {
    ImageAttachment,
    AudioAttachment,
    ExamInputSoal: EpotInputSoal,
  },
  props: {
    data: Object,
    disabled: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    sn: { type: Number, default: 0 },
  },
  data() {
    return {
      questions: [],
      delete_question: {
        show: false,
        id: null,
      },

      save_question: {
        show: false,
        question: {},
      },
    };
  },
  computed: {
    isDisabled() {
      return this.disabled;
      // || !!this.data.id;
    },
  },
  methods: {
    addQuestion() {
      if (this.disabled) return;
      this.data.list_question.push({});
    },
    confirmSave(question) {
      this.save_question.show = true;
      this.save_question.question = question;
    },
    saveQuestion(question, status) {
      this.save_question.show = false;
      this.save_question.question = {};
      this.$set(question, "saving", true);

      let question_data = {
        id: "",
        instruction_text: "",
        instruction_media: "",
        type: "",
        question_text: "",
        question_media: "",
        point: 1,
        options: "",
        correct_answer: "",
        explaination_text: "",
        explaination_media: "",
        status: "",
      };

      Object.keys(question_data).forEach((key) => {
        let inst = question.instruction || {};
        let ques = question.question || {};
        switch (key) {
          case "instruction_text":
            question_data[key] = inst.text;
            break;
          case "instruction_media":
            question_data[key] = inst.media ? inst.media.id : "";
            break;
          case "question_text":
            question_data[key] = ques.text;
            break;
          case "question_media":
            question_data[key] = ques.media ? ques.media.id : "";
            break;
          case "status":
            question_data[key] = status;
            break;
          default:
            if (key in question) question_data[key] = question[key];
            break;
        }
      });

      let action = question_data.id ? "question/update" : "question/create";

      this.$store
        .dispatch(action, question_data)
        .then((res) => {
          // this.$set(question, "id", '');
          this.$set(question, "id", res.data.id);
          this.$set(question, "status", res.data.status);
          this.$delete(question, "saving");
          if (action.includes("create")) {
            this.$emit("success", `Question saved to ${status}`);
          } else {
            this.$emit("success", "Question updated");
          }
        })
        .catch((error) => {
          this.$emit("error", error);
          this.$delete(question, "saving");
        });
    },
    confirmDelete(id) {
      if (this.disabled) return;
      this.delete_question.show = true;
      this.delete_question.id = id;
    },
    cancel() {
      if (this.disabled) return;
      this.delete_question.show = this.save_question.show = false;
      this.delete_question.id = null;
      this.save_question.question = {};
    },
    deleteQuestion(id) {
      if (this.disabled) return;
      let index;

      index = this.data.list_question.findIndex(
        (question) => question.id == id
      );
      if (index == -1) {
        index = id;
      }
      this.data.list_question.splice(index, 1);

      this.delete_question.show = false;
      this.delete_question.id = null;
    },
    isMediaSet(type) {
      return (
        !this.data.with_media ||
        (this.data.media.type && this.data.media.type.includes(type))
      );
    },
  },
  watch: {
    group: {
      handler: function () {
        if (this.disabled) return;
        this.$emit("update", this.data);
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>