<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-tabs v-model="tabs" background-color="transparent">
          <v-tab class="text-capitalize">Pengaturan</v-tab>
          <v-tab class="text-capitalize" :disabled="!settingsFilled"
            >Soal</v-tab
          >
        </v-tabs>
        <v-tabs-items class="my-2 transparent" v-model="tabs">
          <v-tab-item>
            <v-card elevation="0" rounded="lg" outlined>
              <v-card-text class="pb-2">
                <form @submit.prevent="submit" class="pl-3 pr-3">
                  <v-row>
                    <!-- Nama paket (auto generated) -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Nama" rules="required"> -->
                      <p class="mb-2">Nama Paket</p>
                      <v-text-field
                        v-model="settings.name"
                        filled
                        required
                        outlined
                        autofocus
                        hide-details="auto"
                      ></v-text-field>
                      <!-- </validation-provider> -->
                    </v-col>
                    <!-- Timer -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Retake" rules="required"> -->
                      <p class="mb-2">Section</p>
                      <v-select
                        filled
                        v-model="settings.section"
                        :items="sections"
                        item-text="name"
                        item-value="name"
                        required
                        outlined
                        hide-details="auto"
                      >
                      </v-select>
                      <!-- </validation-provider> -->
                    </v-col>
                    <!-- Is question random -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Question" rules="required"> -->
                      <p class="mb-2">Select Part</p>
                      <v-select
                        filled
                        outlined
                        v-model="settings.part"
                        :items="
                          parts.filter(
                            (part) => part.section == settings.section
                          )
                        "
                        item-value="name"
                        item-text="name"
                        hide-details="auto"
                      >
                      </v-select>
                    </v-col>
                    <!-- Is option random -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Answer Choice" rules="required"> -->
                      <p class="mb-2">Random Question</p>
                      <v-select
                        :items="randoms"
                        item-value="val"
                        item-text="text"
                        filled
                        v-model="settings.random"
                        required
                        outlined
                        hide-details="auto"
                      ></v-select>
                      <!-- </validation-provider> -->
                    </v-col>
                    <!-- Section -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Question" rules="required"> -->
                      <div class="mb-2">Part direction</div>
                      <v-textarea
                        class=""
                        outlined
                        filled
                        color="primary"
                        v-model="settings.direction.text"
                        placeholder="Direction"
                        hide-details="auto"
                      >
                      </v-textarea>
                      <!-- </validation-provider> -->
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Answer Choice" rules="required"> -->
                      <div class="mb-2">Attachment</div>
                      <v-row no-gutters style="max-width: 100%">
                        <audio-attachment
                          :src="settings.direction.media"
                          @update="settings.direction.media = $event"
                        />
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-2">Close statement</div>
                      <v-textarea
                        outlined
                        filled
                        color="primary"
                        v-model="settings.close_statement.text"
                        placeholder="Statement"
                        hide-details="auto"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-2">Attachment</div>
                      <v-row no-gutters style="max-width: 100%">
                        <audio-attachment
                          :src="settings.close_statement.media"
                          @update="settings.close_statement.media = $event"
                        />
                      </v-row>
                    </v-col>
                  </v-row>
                </form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="background pa-4">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  large
                  :disabled="!settingsFilled"
                  @click="save()"
                  elevation="0"
                  >Simpan</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item> </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import EpotInputSoal from "./components/EpotInputSoal";
// import EpotInputGrupSoal from "./components/EpotInputGrupSoal.vue";
import AudioAttachment from "../_inc/modal/AudioAttachment.vue";

export default {
  name: "epot-detail",
  components: {
    // EpotInputSoal,
    // EpotInputGrupSoal,
    AudioAttachment,
  },
  data() {
    return {
      tabs: null,
      sections: [
        { id: 2, name: "Listening" },
        { id: 3, name: "Reading" },
        { id: 1, name: "Structure" },
      ],

      parts: [
        { id: 1, name: "Part A", section: "Structure" },
        { id: 2, name: "Part B", section: "Structure" },
        { id: 3, name: "Part A", section: "Listening" },
        { id: 4, name: "Part B", section: "Listening" },
        { id: 5, name: "Part C", section: "Listening" },
        { id: 6, name: "Tanpa Part", section: "Reading" },
      ],

      randoms: [
        { val: "yes", text: "Ya" },
        { val: "no", text: "Tidak" },
      ],

      settings: {
        name: null,
        section: null,
        part: null,
        random: null,
        direction: {
          text: null,
          with_media: false,
          media: null,
        },
        close_statement: {
          text: null,
          with_media: false,
          media: null,
        },
      },

      // questions: [],

      delete_question: {
        show: false,
        id: null,
      },

      snackbar: {
        show: false,
        message: "",
      },
    };
  },
  computed: {
    settingsFilled() {
      let status = true;

      Object.keys(this.settings).forEach((key) => {
        if (key == "direction" || key == "close_statement") {
          if (!this.settings[key].text) {
            status = false;
          }
        } else if (!this.settings[key]) {
          status = false;
        }
      });

      return status;
    },
  },
  created() {
    this.$store.dispatch('epot/detail', this.$route.params.id).then(res => {
      // console.log(res);
      this.settings = res.data
    })
  },
  methods: {
    confirmDelete(id) {
      this.delete_question.show = true;
      this.delete_question.id = id;
    },
    cancelDelete() {
      this.delete_question.show = false;
      this.delete_question.id = null;
    },
    deleteQuestion(id) {
      // FIXME: Lagi bisa yang index, yang id belum
      let index;

      index = this.questions.findIndex((question) => question.id == id);
      if (index == -1) {
        index = id;
      }
      this.questions.splice(index, 1);

      this.delete_question.show = false;
      this.delete_question.id = null;
    },
    save() {
      this.$store.dispatch("epot/update", {
        ...this.settings,
      }).then(() => {
        this.$router.push({ name: "epot-list" });
      });
    },
    add(question) {
      if (this.questions.find((val) => val.id == question.id) == undefined) {
        this.questions.push(question);
      } else {
        this.snackbar.show = true;
        this.snackbar.message = "Some questions already imported";
      }
    },
    addQuestion() {
      this.questions.push({});
    },
    addGroup() {
      this.questions.push({
        questions: [{}],
      });
    },
  },
};
</script>

<style>
.v-menu__content {
  margin-top: 10px;
  border-radius: 0px !important;
}
</style>
