<template>
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :items="items.list"
    :items-per-page.sync="items.limit"
    :page.sync="items.page"
    :show-select="showSelect"
    item-key="id"
    v-model="data_selected"
    class="transparent"
    :mobile-breakpoint="0"
    @toggle-select-all="toggleSelectAll"
    :server-items-length="items.total"
    loading-text="Loading... Please wait"
    @update:sort-by="sortBy"
    @update:sort-desc="sortDesc"
  >
    <!--
  class="spacing-y-5 transparent"
  :mobile-breakpoint="0"
  hide-default-header
  item-key="id"
  single-expand
  v-model="selected"
  :server-items-length="questions.total"
  :items-per-page.sync="limit"
  :page.sync="page"
  :headers="[{ value: 'question.text' }]"
  :items="filteredQuestions"
  :loading="table_loading"
  -->
    <template slot="header.data-table-select" slot-scope="{ props, on }">
      <v-simple-checkbox
        color="primary"
        v-model="props.value"
        @input="on.input(props.value)"
      ></v-simple-checkbox>
    </template>
    <template slot="item" slot-scope="props">
      <tr class="flat-b-1 rounded-lg">
        <td class="white rounded-l-lg" v-if="showSelect">
          <v-simple-checkbox
            color="primary"
            v-model="props.item.selected"
            @click="props.select(!props.isSelected)"
          ></v-simple-checkbox>
        </td>
        <td class="white" :class="showSelect || 'rounded-l-lg'">
          {{ props.item.code }}
        </td>
        <td class="white">
          <v-chip
            :ripple="false"
            class="ma-2 pa-1 text-capitalize"
            :color="getStatusClass(props.item.status)"
            outlined
            label
            small
            v-text="getStatusLabel(props.item.status)"
          ></v-chip>
        </td>
        <td class="white">
          {{ section(props.item.section_id).name }}
          <!-- <span>Listening</span> -->
        </td>
        <td class="white">
          <span v-if="props.item.section == 'R'">No part</span>
          <span v-else>{{ `Part ${props.item.part}` }}</span>
        </td>
        <td class="white">
          {{ props.item.admin.fullname }}
        </td>
        <td class="white rounded-r-lg">
          <div style="white-space: nowrap">
            <v-btn
              title="Detail"
              icon
              :to="{ name: 'exam-detail', params: { id: props.item.id } }"
            >
              <v-icon> $detail </v-icon>
            </v-btn>
            <template v-if="props.item.status == 2">
              <v-btn
                title="Archive"
                icon
                @click="$emit('update', props.item, 'archive')"
              >
                <v-icon>$archive </v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                title="Publish"
                icon
                @click="$emit('update', props.item, 'published')"
              >
                <v-icon> $publish </v-icon>
              </v-btn>
            </template>
            <v-btn
              :disabled="props.item.status == 2"
              title="Hapus paket"
              icon
              @click="$emit('delete', props.item)"
            >
              <v-icon> $delete </v-icon>
            </v-btn>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    items: { type: Object, default: () => ({}) },
    selected: { type: Array, default: () => [] },
    showSelect: { type: Boolean, default: () => true },
    loading: { type: Boolean, default: () => false },
  },
  data() {
    return {
      headers: [
        { text: "Kode Paket", align: "left", value: "code" },
        { text: "Status", align: "left", value: "status" },
        { text: "Section", align: "left", value: "section", sortable: false },
        { text: "Nama Part", align: "left", value: "part" },
        { text: "Penulis", align: "left", value: "author", sortable: false },
        { text: "Aksi", align: "left", value: "button", sortable: false },
      ],
      sections: [],
      table_loading: false,
      data_selected: [],
      sort: "updated_at",
      dir: "desc",
    };
  },
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
  },
  created() {
    this.fetchSection();
    this.data_selected = this.selected;
  },
  methods: {
    async fetchSection() {
      this.$store.dispatch("section/list").then((res) => {
        this.sections = res.data.list;
      });
    },
    getStatusCode(status) {
      if (status == "published") return 2;
      if (status == "draft") return 1;
      if (status == "archive") return 3;
    },
    section(section_id) {
      return this.sections.find((s) => s.id == section_id) || {};
    },
    async update(item, status) {
      // status = this.getStatusCode(status);
      this.table_loading = true;
      if (!Array.isArray(item)) {
        item = [item];
      }
      await item.forEach(async (epot) => {
        await this.$store
          .dispatch("exam/update", { ...epot, status })
          .then(() => {
            this.$emit("refresh-epot");
            this.table_loading = false;
          })
          .catch((error) => {
            this.table_loading = false;
            throw new Error(error);
          });
      });
    },
    getStatusClass(status) {
      if (status == 2) return "success";
      if (status == 1) return "default";
      if (status == 3) return "brown";
      return "color";
    },
    getStatusLabel(status) {
      if (status == 2) return "published";
      if (status == 1) return "draft";
      if (status == 3) return "archive";
      return "😕";
    },
    toggleSelectAll($event) {
      $event.items.forEach((item) => {
        this.$set(item, "selected", $event.value);
      });
    },
    sortBy($event) {
      // code, part, status, total_question, is_random_question, is_random_answer, created_at, updated_at
      // asc, desc
      this.sort = $event[0];
      this.$emit("refresh-list", this.sort, this.dir);
      // console.log("sort by", $event);
    },
    sortDesc($event) {
      this.dir = $event[0] ? "desc" : "asc";
      this.$emit("refresh-list", this.sort, this.dir);
      // console.log("dir", $event);
    },
  },
  watch: {
    data_selected: function () {
      this.$emit("select", this.data_selected);
    },
    "items.limit": function () {
      this.$emit("refresh-list", this.sort, this.dir);
    },
    "items.page": function () {
      this.$emit("refresh-list", this.sort, this.dir);
    },
  },
};
</script>
