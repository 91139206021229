<template>
  <v-card class="shadow-0" elevation="0" style="background: transparent">
    <!-- #NOTIFICATION -->
    <v-snackbar :color="notification.color" :timeout="notification.timeout" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="white" v-bind="attrs" @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- #DIIALOG DETAIL TRACKING -->
    <v-dialog v-model="dialog.detailTracking" persistent fullscreen>
      <v-card elevation="0">
        <v-card-title class="font-weight-bold">
          DETAIL TRACKING
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.detailTracking = false">ri-close-line</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <!-- <pre>
          {{ tracking }}
        </pre> -->
        <v-card-text class="px-0">
          <v-card class="rounded-lg pt-6 px-6 pb-15" elevation="0">
            <v-row>
              <v-col>
                <h4>Detail Kurir</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <span class="label">Waybill</span>
              </v-col>
              <v-col md="10">
                <span>{{ tracking.waybill_id }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <span class="label">Jenis Pengiriman</span>
              </v-col>
              <v-col md="10">
                <span>{{ tracking.courier.company }} - {{ tracking.courier.type }} </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <span class="label">Nama Driver</span>
              </v-col>
              <v-col md="10">
                <span>{{ tracking.courier.name }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <span class="label">No Telp Driver</span>
              </v-col>
              <v-col md="10">
                <span>{{ tracking.courier.phone }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <span class="label">Status</span>
              </v-col>
              <v-col md="10">
                <span>{{ tracking.status }}</span>
              </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <v-row>
              <v-col>
                <h4>Detail Tracking</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <span class="label">Dikirim dari</span>
              </v-col>
              <v-col md="10">
                <span>{{ tracking.origin.contact_name }} - {{ tracking.origin.address }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2" class="mb-3">
                <span class="label">Tujuan</span>
              </v-col>
              <v-col md="10">
                <span>{{ tracking.destination.contact_name }} - {{ tracking.destination.address }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <span class="label">Riwayat</span>
              </v-col>
              <v-col md="10">
                <div class="item"
                v-for="(histry,key) in tracking.history"
                :key="key" >
                  <div class="time inline">
                    <span>{{ histry.updated_at | time }}</span><br>
                    <span>{{ histry.updated_at | dateOnly }}</span>
                  </div>
                  <div class="timeline inline">
                    <span class="point"></span>
                  </div>
                  <div class="notes inline">
                    {{ histry.note }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- #DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 text-first d-flex mb-4" style="background-color: #652065;height:50px">
          <span class="white--text">
            Dialog Filter
          </span>
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.filtered = false" color="white">ri-close-line</v-icon>
        </v-card-title>
        <v-card-text style="height: 300px;" class="py-5">
          <v-select
            outlined
            dense
            :items="institusions"
            item-text="name"
            item-value="id"
            color="#652065"
            prepend-inner-icon="ri-building-line"
            placeholder="Pilih Lembaga"
            v-model="form.institusion_id">
          </v-select>
          <v-text-field
            outlined
            dense
            color="#652065"
            prepend-inner-icon="ri-ticket-2-line"
            placeholder="Filter Order ID"
            v-model="form.order_id">
          </v-text-field>
          <v-menu
            ref="menu"
            v-model="dialog.picker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="FormartedDateFilter"
                prepend-inner-icon="ri-calendar-line"
                outlined
                dense
                readonly
                label="Filter Tanggal Tes"
                v-bind="attrs"
                color="#652065"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-if="tab == 0"
              ref="picker"
              color="#652065"
              :max="new Date().toISOString().substr(0, 10)"
              v-model="form.filter_date"
              @input="dialog.picker = false"
            >
            </v-date-picker>
            <v-date-picker
              v-if="tab == 1"
              ref="picker"
              color="#652065"
              :max="new Date().toISOString().substr(0, 10)"
              v-model="form.filter_date_printed"
              @input="dialog.picker = false"
            >
            </v-date-picker>
            <v-date-picker
              v-if="tab == 2"
              ref="picker"
              color="#652065"
              :max="new Date().toISOString().substr(0, 10)"
              v-model="form.filter_date_error"
              @input="dialog.picker = false"
            >
            </v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            width="100"
            color="#652065"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="filter()"
          >
            Filter
          </v-btn>
          <v-btn
            width="100"
            elevation="0"
            color="yellow darken-2"
            class="text-capitalize white--text"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- #DIALOG FORM UPDATE -->
    <v-dialog v-model="modalUpdate" persistent width="500">
      <v-form ref="updateForm" @submit.prevent="update">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Update user</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span>Nama</span>
                <v-text-field
                  v-model="userUpdate.fullname"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Email</span>
                <v-text-field
                  disabled
                  v-model="userUpdate.email"
                  type="email"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required, rules.email]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Tempat lahir</span>
                <v-text-field
                  v-model="userUpdate.place_of_birth"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Tanggal lahir
                  <!-- <span class="text--secondary">(Format: YYYY-MM-DD)</span>
                  {{userUpdate.date_of_birth}} -->
                </div>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="FormartedDate"
                      append-icon="ri-calendar-line"
                      outlined
                      readonly
                      filled
                      v-bind="attrs"
                      v-on="on"
                      placeholder="YYYY-MM-DD"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    color="primary"
                    :rules="[rules.dateRules]"
                    :max="new Date().toISOString().substr(0, 10)"
                    v-model="userUpdate.date_of_birth"
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="
                userUpdate = {};
                $refs.updateForm.reset();
                modalUpdate = null;
              "
            >
              Batal
            </v-btn>
            <v-btn type="submit" color="primary" elevation="0" :loading="isUpdate">
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- #DIALOG FORM UPDATE DATA WORDPRESS -->
    <v-dialog v-model="modalUpdateWordpress" scrollable persistent width="500">
      <v-form ref="updateFormWordpress">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Update Data Wordpress</span>
            <v-spacer></v-spacer>
            <v-icon size="30" @click="modalUpdateWordpress = false">ri-close-line</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 550px">
            <v-row>
              <v-col cols="12">
                <span>Nama Lengkap</span>
                <v-text-field
                  v-model="form.wordpress.fullname"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Email</span>
                <v-text-field
                  v-model="form.wordpress.email"
                  type="email"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required, rules.email]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <span>ID Order</span>
                <v-text-field
                  v-model="form.wordpress.order_id"
                  outlined
                  type="number"
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Courier
                </div>
                <v-text-field
                  v-model="form.wordpress.courier"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Courier Type
                </div>
                <v-text-field
                  v-model="form.wordpress.courier_type"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Receiver
                </div>
                <v-text-field
                  v-model="form.wordpress.receiver"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Receiver Phone
                </div>
                <v-text-field
                  v-model="form.wordpress.receiver_phone"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Receiver Address
                </div>
                <v-text-field
                  v-model="form.wordpress.receiver_address"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Receiver Post Code
                </div>
                <v-text-field
                  v-model="form.wordpress.receiver_post_code"
                  outlined
                  filled
                  type="number"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Order Note
                </div>
                <v-textarea
                  v-model="form.wordpress.order_note"
                  outlined
                  filled
                  :rules="[rules.required]"
                >
                </v-textarea>
              </v-col>

              <div class="ml-5 body-1">Data Items</div>
              <v-col cols="12">
                <div>
                  Item Name
                </div>
                <v-text-field
                  v-model="form.wordpress.item.name"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Value
                </div>
                <v-text-field
                  v-model="form.wordpress.item.value"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Quantity
                </div>
                <v-text-field
                  v-model="form.wordpress.item.quantity"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Height
                </div>
                <v-text-field
                  v-model="form.wordpress.item.height"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Length
                </div>
                <v-text-field
                  v-model="form.wordpress.item.length"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Weight
                </div>
                <v-text-field
                  v-model="form.wordpress.item.weight"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Width
                </div>
                <v-text-field
                  v-model="form.wordpress.item.width"
                  outlined
                  filled
                  min="0"
                  type="number"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="closeUpdateWordpressData('all')"
            >
              Batal
            </v-btn>
            <v-btn color="primary" elevation="0" :loading="is_loading" @click="updateWordpressData(form.id)">
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- #DIALOG FORM UPDATE DATA ITEM WORDPRESS -->
    <v-dialog v-model="modalUpdateItemWordpress" scrollable persistent width="500">
      <v-form ref="updateFormItemWordpress">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Update Data Item Wordpress</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 550px">
            <v-row>
              <v-col cols="12">
                <div>
                  Item Name
                </div>
                <v-text-field
                  v-model="form.wordpress.item.name"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Value
                </div>
                <v-text-field
                  v-model="form.wordpress.item.value"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Quantity
                </div>
                <v-text-field
                  v-model="form.wordpress.item.quantity"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Height
                </div>
                <v-text-field
                  v-model="form.wordpress.item.height"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Length
                </div>
                <v-text-field
                  v-model="form.wordpress.item.length"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Weight
                </div>
                <v-text-field
                  v-model="form.wordpress.item.weight"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Width
                </div>
                <v-text-field
                  v-model="form.wordpress.item.width"
                  outlined
                  filled
                  min="0"
                  type="number"
                  hide-details="auto"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="closeUpdateWordpressData('item')"
            >
              Batal
            </v-btn>
            <v-btn color="primary" elevation="0" :loading="is_loading" @click="updateWordpressData(form.id)">
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- #DIALOG PREVIEW PRINT -->
    <v-dialog v-model="dialog.preview" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark class="bg-modal showTool" elevation="0">
          <v-btn icon dark @click="dialog.preview = false">
            <v-icon></v-icon>
          </v-btn>
          <v-toolbar-title>Preview Certificate</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog.preview = false" color="primary">
              <v-icon>ri-close-circle-fill</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <printPreview class="menu-modal" :data="certificate" />
      </v-card>
    </v-dialog>

    <!-- #DIALOG UBAH BATAL CETAK -->
    <v-dialog v-model="dialog.ubahBatalCetak" persistent max-width="375">
      <v-card class="pa-3">
        <v-card-title class="title">Peringatan !</v-card-title>
        <v-card-text>
          Apakah anda yakin ingin mengubah sertifikat ini menjadi belum tercetak ?
        </v-card-text>
        <v-card-actions class="pb-1">
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#652065"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="changeStatus(data_change, 'iya')"
          >
            Yakin
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="changeStatus(data_change, 'batal')"
          >
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- #DIALOG UBAH CETAK -->
    <v-dialog v-model="dialog.ubahCetak" persistent max-width="375">
      <v-card class="pa-3">
        <v-card-title class="title">Peringatan !</v-card-title>
        <v-card-text>
          Apakah anda yakin ingin mengubah sertifikat ini sebagai tercetak ?
        </v-card-text>
        <v-card-actions class="pb-1">
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#652065"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="changeStatus(data_change, 'iya')"
          >
            Yakin
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="changeStatus(data_change, 'batal')"
          >
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-row>
      <v-col cols="12" class="d-flex" md="12">
        <v-radio-group v-model="is_fisik" row @change="search()">
          <v-radio label="Fisik" v-bind:value="1"></v-radio>
          <v-radio label="Non-Fisik" v-bind:value="0"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" class="d-flex" md="6">
        <v-tabs v-model="tab" class="text-capitalize my-auto badged-tabs" color="primary" show-arrows="mobile">
          <v-tab class="text-capitalize">
            <v-badge :content="String(certif.total || '0')" inline>
              {{ is_fisik ? 'Belum Cetak' : 'Tidak Dicetak' }}
            </v-badge>
          </v-tab>
          <v-tab class="text-capitalize">
            <!-- <v-badge :content="String(certif.total || '0')" inline> -->
            Sudah Cetak
            <!-- </v-badge> -->
          </v-tab>
          <v-tab class="text-capitalize" v-if="is_fisik">
            <!-- <v-badge :content="String(certif.total || '0')" inline> -->
            Tidak dicetak
            <!-- </v-badge> -->
          </v-tab>
          <v-tab class="text-capitalize">
            <!-- <v-badge :content="String(certif.total || '0')" inline> -->
            Gugur Test
            <!-- </v-badge> -->
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" md="6">
        <div class="d-flex text-right">
          <v-tooltip bottom>
            <template #activator="{on, attrs}">
              <v-text-field
                v-model="searching"
                outlined
                dense
                prepend-inner-icon="ri-search-line"
                placeholder="Cari Data . . ."
                hide-details="auto"
                @keydown.enter="search"
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append v-if="searching">
                  <v-icon @click="searching = '';fetchAll()">ri-close-line</v-icon>
                </template>
              </v-text-field>
            </template>
            Tekan ENTER untuk mencari
          </v-tooltip>
          <!-- <v-autocomplete
            class="pt-1 mr-3 color-navy-soft"
            v-model="user"
            :items="users"
            outlined
            dense
            :loading="userLoading"
            :filter="userFilter"
            :search-input.sync="userQuery"
            clearable
            hide-details="auto"
            hide-no-data
            item-text="fullname"
            item-value="id"
            placeholder="Cari nama / email"
            prepend-inner-icon="$search"
            return-object>
            <div class="py-2" slot="item" slot-scope="{ item }">
              {{ item.fullname }} <br />
              <small class="color-navy-soft">{{ item.email }}</small>
            </div>
            <template v-slot:selection="{ attrs, item, selected }">
              <v-chip
                v-if="item"
                v-bind="attrs"
                color="grey lighten-2"
                :input-value="selected">
                <span class="subtitle-2 pr-5 text-capitalize">
                  {{ item.fullname }}
                </span>
                <v-icon size="25" color="#d31145" @click="user = {}">
                  $close
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialog.filtered = true" dense small elevation="0" class="mx-2" v-on="on" v-bind="attrs">
                <v-icon color="#652065">ri-filter-line</v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" @click="actionExport()" class="pt-0 mb-3 mr-0" dense elevation="0">
                <v-icon color="#4CAF50" left>ri-file-excel-2-line</v-icon>
              </v-btn>
            </template>
            <span>Export Excel</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- {{ items_selected }}
        {{ selected_title }} -->
        <!-- {{ selected_value }} -->
        <div class="mb-2 d-flex align-start" v-if="tab == 0 ? selected.belum_cetak.length > 0 : tab == 1 ? selected.sudah_cetak.length > 0 : selected.certif_error.length > 0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mr-3"
                outlined
                dark
                small
                v-bind="attrs"
                v-on="on">
                {{ selected_title }}
                <v-icon size="40" right>ri-arrow-drop-down-line</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                @click="selected_title = item.title;selected_value= item.value"
                v-for="(item, index) in tab == 0 ? items_selected[0].belum_cetak : tab == 1 ? items_selected[1].sudah_cetak : items_selected[2].error_certif"
                :key="index">
                <!-- {{ item }} -->
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn 
            outlined
            small
            elevation="0"
            @click="tab == 0 ? updatedStatus(selected.belum_cetak, selected_value) : tab == 1 ? updatedStatus(selected.sudah_cetak, selected_value) : updatedStatus(selected.certif_error, selected_value)"
            color="#652065" >
            Apply
          </v-btn>
        </div>
        <v-card class="mb-10">
          <div v-if="tab == 0">
            <v-data-table
              :loading="certifLoading"
              disable-sort
              :headers="headers_belum"
              :items="certifLoading ? [] : certif.list"
              :items-per-page.sync="certif.limit"
              :page.sync="certif.page"
              show-select
              v-model="selected.belum_cetak"
              item-key="id"
              :mobile-breakpoint="0"
              :server-items-length="certif.total"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
              <template slot="footer" slot-scope="{ props }">
                <v-row class="px-4">
                  <div class="pa-3">
                    <v-row align="center">
                      <div>
                        <v-subheader v-text="'Baris Per Halaman: '"></v-subheader>
                      </div>
                      <div>
                        <v-select
                          class="mt-0 pt-0"
                          style="width: 4rem"
                          v-model="certif.limit"
                          dense
                          :items="[10, 20, 50, 100]"
                          hide-details="auto"
                        >
                        </v-select>
                      </div>
                    </v-row>
                  </div>
                  <v-col>
                    <v-row class="mx-3" justify="end" align="center">
                      <div class="d-flex flex-nowrap align-center">
                        <v-subheader class="mr-2">Halaman:</v-subheader>
                        <v-select
                          v-model="certif.page"
                          dense
                          class="pa-0 ma-0 mr-2"
                          hide-details
                          :items="
                            Array.from(
                              {
                                length: Math.ceil(certif.total / certif.limit),
                              },
                              (_, i) => i + 1
                            )
                          "
                          style="width: 5rem; text-align: center !important"
                        >
                        </v-select>
                        <v-subheader>
                          Menampilkan data ke
                          {{ props.pagination.pageStart + 1 }} sampai {{ props.pagination.pageStop }} dari
                          {{ props.pagination.itemsLength }}
                        </v-subheader>
                      </div>
                      <v-btn icon small :disabled="certif.page == 1" @click="certif.page--">
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        :disabled="certif.page == Math.ceil(certif.total / certif.limit)"
                        @click="certif.page++"
                      >
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template slot="item.start_test" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.start_test | dateOnly }}
                </span>
              </template>
              <template slot="item.date_of_birth" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.member.date_of_birth | dateOnly }}
                </span>
              </template>
              <!-- <template slot="item.score" slot-scope="{ item }">
                <v-switch inset v-model="item.print_flag" @change="changeStatus(item)"> </v-switch>
              </template> -->
              <template slot="item.button" slot-scope="{ item }">
                <div class="d-flex text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        @click="filePDF(item.license)"
                        class="text-capitalize orange--text text--darken-1 pa-0"
                        target="blank"
                      >
                        <!-- :href="`https://www.yec.co.id/sertifikat-epot/?id=${item.id}`" -->
                        <v-icon size="20">ri-file-pdf-line</v-icon>
                      </v-btn>
                    </template>
                    <span>File Pdf</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize red--text pa-0"
                        @click="
                          dialog.preview = true;
                          certificate = item;
                        "
                      >
                        <v-icon size="20">$eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        @click="printPDF(item.license)"
                        class="text-capitalize green--text pa-0"
                        target="blank"
                      >
                        <!-- :href="`https://www.yec.co.id/sertifikat-epot-fisik/?id=${item.id}`" -->
                        <v-icon size="20">$print</v-icon>
                      </v-btn>
                    </template>
                    <span>Print</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="updateUser(item.member)"
                      >
                        <v-icon size="20">ri-user-settings-line</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit User</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="toogleChangeStatus(item)">
                        <v-icon size="20">$checkDouble</v-icon>
                      </v-btn>
                    </template>
                    <span>Beri Tanda Tercetak</span>
                  </v-tooltip> -->
                </div>
              </template>
              <template slot="item.printed" slot-scope="{ item }">
                <div class="d-flex justify-center">
                  <v-switch inset v-model="item.print_flag" @change="changeStatus(item)"> </v-switch>
                </div>
              </template>
            </v-data-table>
          </div>

          <div id="printMe" v-show="srt">
            <printOut :data="certificate" />
          </div>
          <!-- SECOND TABS -->
          <div v-if="tab == 1">
            <v-data-table
              :loading="certifLoading"
              disable-sort
              :headers="headers_tercetak"
              :items="certifLoading ? [] : certifPrinted.list"
              :items-per-page.sync="certifPrinted.limit"
              :page.sync="certifPrinted.page"
              show-select
              v-model="selected.sudah_cetak"
              item-key="id"
              :mobile-breakpoint="0"
              :server-items-length="certifPrinted.total"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
              <template slot="footer" slot-scope="{ props }">
                <v-row class="px-4">
                  <div class="pa-3">
                    <v-row align="center">
                      <div>
                        <v-subheader v-text="'Baris Per Halaman: '"></v-subheader>
                      </div>
                      <div>
                        <v-select
                          class="mt-0 pt-0"
                          style="width: 4rem"
                          v-model="certifPrinted.limit"
                          dense
                          :items="[10, 20, 50, 100]"
                          hide-details="auto"
                        >
                        </v-select>
                      </div>
                    </v-row>
                  </div>
                  <v-col>
                    <v-row class="mx-3" justify="end" align="center">
                      <div class="d-flex flex-nowrap align-center">
                        <v-subheader class="mr-2">Halaman:</v-subheader>
                        <v-select
                          v-model="certifPrinted.page"
                          dense
                          class="pa-0 ma-0 mr-2"
                          hide-details
                          :items="
                            Array.from(
                              {
                                length: Math.ceil(certifPrinted.total / certifPrinted.limit),
                              },
                              (_, i) => i + 1
                            )
                          "
                          style="width: 5rem; text-align: center !important"
                        >
                        </v-select>
                        <v-subheader>
                          Menampilkan data ke
                          {{ props.pagination.pageStart + 1 }} sampai {{ props.pagination.pageStop }} dari
                          {{ props.pagination.itemsLength }}
                        </v-subheader>
                      </div>
                      <v-btn icon small :disabled="certifPrinted.page == 1" @click="certifPrinted.page--">
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        :disabled="certifPrinted.page == Math.ceil(certifPrinted.total / certifPrinted.limit)"
                        @click="certifPrinted.page++"
                      >
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template slot="item.updated_at" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.updated_at | dateOnly }}
                </span>
              </template>
              <template slot="item.date_of_birth" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.member.date_of_birth | dateOnly }}
                </span>
              </template>
              <template slot="item.button" slot-scope="{ item }">
                <div class="d-flex text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize orange--text text--darken-1 pa-0"
                        @click="filePDF(item.license)"
                        target="blank"
                      >
                        <!-- :href="`https://www.yec.co.id/sertifikat-epot/?id=${item.license}`" -->
                        <v-icon size="20">ri-file-pdf-line</v-icon>
                      </v-btn>
                    </template>
                    <span>File Pdf</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize red--text pa-0"
                        @click="
                          dialog.preview = true;
                          certificate = item;
                        "
                      >
                        <v-icon size="20">$eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize green--text pa-0"
                        @click="printPDF(item.license)"
                        target="blank"
                      >
                        <!-- :href="`https://www.yec.co.id/sertifikat-epot-fisik/?id=${item.license}`" -->
                        <v-icon size="20">$print</v-icon>
                      </v-btn>
                    </template>
                    <span>Print</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="updateUser(item.member)"
                      >
                        <v-icon size="20">ri-user-settings-line</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit User</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize pink--text pa-0"
                        @click="confirmupdateWordpress(item,'item')"
                      >
                        <v-icon size="20">ri-file-edit-line</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Item</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="print(item)">
                        <v-icon size="20">$print</v-icon>
                      </v-btn>
                    </template>
                    <span>Print Out</span>
                  </v-tooltip> -->
                </div>
              </template>
              <template slot="item.printed" slot-scope="{ item }">
                <div class="d-flex justify-center">
                  <v-switch inset v-model="item.print_flag" @change="changeStatus(item)"> </v-switch>
                </div>
              </template>
              <template v-if="is_fisik == 1" slot="item.shipping.order_id" slot-scope="{ item }">
                <!-- #KONFIRMASI MANUAL -->
                <v-btn
                  v-if="item.shipping.status == 'placed'"
                  @click="confirmSend(item.shipping.order_id)">
                  Konfirmasi Kirim
                </v-btn>
                <!-- #ORDER MANUAL -->
                <!-- <v-btn
                  v-if="item.shipping.order_id == '' && item.wordpress.order_id != 0"
                  @click=" manualOrder(item.id)">
                  Order Manual
                </v-btn> -->
                <v-btn
                  v-if="item.shipping.order_id == ''"
                  @click="confirmupdateWordpress(item,'all')">
                  Order Manual
                </v-btn>
                <div class="teal--text font-weight-bold" v-if="item.shipping.status != 'placed' && item.shipping.status != ''">
                  Confirmed
                </div>
              </template>
              <template slot="item.no_resi" slot-scope="{ item }">
                <v-btn
                  class="pa-0 text-capitalize"
                  text
                  @click="trackingDetail(item.shipping.order_id)"
                  >{{ item.shipping.waybill_id != '' ? item.shipping.waybill_id : '-' }}
                </v-btn>
                <input type="hidden" :id="`waybill-id-${item.shipping.waybill_id}`" ref="shippingwaybillid" :value="`${item.shipping.waybill_id}`">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-show="item.shipping.waybill_id != ''" v-on="on" v-bind="attrs" color="primary" @click="copyLisensi(item.shipping.waybill_id)" class="ml-3">ri-file-copy-line</v-icon>
                  </template>
                  <span>Copy Resi</span>
                </v-tooltip>
              </template>
              <template slot="item.shipping.status" slot-scope="{ item }">
                <span>{{ item.shipping.status }}</span>
              </template>
            </v-data-table>
          </div>

          <!-- THIRD TABS -->
          <div v-if="tab == 2">
            <v-data-table
              :loading="certifLoading"
              disable-sort
              :headers="headers_error"
              :items="certifLoading ? [] : certifError.list"
              :items-per-page.sync="certifError.limit"
              :page.sync="certifError.page"
              show-select
              v-model="selected.certif_error"
              item-key="id"
              :mobile-breakpoint="0"
              :server-items-length="certifError.total"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
              <template slot="footer" slot-scope="{ props }">
                <v-row class="px-4">
                  <div class="pa-3">
                    <v-row align="center">
                      <div>
                        <v-subheader v-text="'Baris Per Halaman: '"></v-subheader>
                      </div>
                      <div>
                        <v-select
                          class="mt-0 pt-0"
                          style="width: 4rem"
                          v-model="certifError.limit"
                          dense
                          :items="[10, 20, 50, 100]"
                          hide-details="auto"
                        >
                        </v-select>
                      </div>
                    </v-row>
                  </div>
                  <v-col>
                    <v-row class="mx-3" justify="end" align="center">
                      <div class="d-flex flex-nowrap align-center">
                        <v-subheader class="mr-2">Halaman:</v-subheader>
                        <v-select
                          v-model="certifError.page"
                          dense
                          class="pa-0 ma-0 mr-2"
                          hide-details
                          :items="
                            Array.from(
                              {
                                length: Math.ceil(certifError.total / certifError.limit),
                              },
                              (_, i) => i + 1
                            )
                          "
                          style="width: 5rem; text-align: center !important"
                        >
                        </v-select>
                        <v-subheader>
                          Menampilkan data ke
                          {{ props.pagination.pageStart + 1 }} sampai {{ props.pagination.pageStop }} dari
                          {{ props.pagination.itemsLength }}
                        </v-subheader>
                      </div>
                      <v-btn icon small :disabled="certifError.page == 1" @click="certifError.page--">
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        :disabled="certifError.page == Math.ceil(certifError.total / certifError.limit)"
                        @click="certifError.page++"
                      >
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template slot="item.updated_at" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.updated_at | dateOnly }}
                </span>
              </template>
              <template slot="item.date_of_birth" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.member.date_of_birth | dateOnly }}
                </span>
              </template>
              <template slot="item.button" slot-scope="{ item }">
                <div class="d-flex text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize red--text pa-0"
                        @click="dialog.preview = true;certificate = item;">
                        <v-icon size="20">$eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="updateUser(item.member)">
                        <v-icon size="20">ri-user-settings-line</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit User</span>
                  </v-tooltip>
                </div>
              </template>
              <template slot="item.printed" slot-scope="{ item }">
                <div
                  :class="
                    item.print_flag === true
                      ? 'subtitle-2 font-weight-bold text-center teal--text'
                      : 'subtitle-2 font-weight-bold text-center red--text text--darken-2'
                  "
                >
                  {{ item.print_flag == true ? "Sudah Dicetak" : "Belum Dicetak" }}
                </div>
              </template>
            </v-data-table>
          </div>

          <!-- THIRD TABS -->
          <div v-if="tab == 3">
            <v-data-table
              :loading="certifLoading"
              disable-sort
              :headers="headers_gugur"
              :items="certifLoading ? [] : certifGugur.list"
              :items-per-page.sync="certifGugur.limit"
              :page.sync="certifGugur.page"
              :mobile-breakpoint="0"
              :server-items-length="certifGugur.total"
              loading-text="Loading... Please wait"
              hide-default-footer>
              <!-- show-select
              v-model="selected.certif_error"
              item-key="id" -->
              <template slot="footer" slot-scope="{ props }">
                <v-row class="px-4">
                  <div class="pa-3">
                    <v-row align="center">
                      <div>
                        <v-subheader v-text="'Baris Per Halaman: '"></v-subheader>
                      </div>
                      <div>
                        <v-select
                          class="mt-0 pt-0"
                          style="width: 4rem"
                          v-model="certifGugur.limit"
                          dense
                          :items="[10, 20, 50, 100]"
                          hide-details="auto"
                        >
                        </v-select>
                      </div>
                    </v-row>
                  </div>
                  <v-col>
                    <v-row class="mx-3" justify="end" align="center">
                      <div class="d-flex flex-nowrap align-center">
                        <v-subheader class="mr-2">Halaman:</v-subheader>
                        <v-select
                          v-model="certifGugur.page"
                          dense
                          class="pa-0 ma-0 mr-2"
                          hide-details
                          :items="
                            Array.from(
                              {
                                length: Math.ceil(certifGugur.total / certifGugur.limit),
                              },
                              (_, i) => i + 1
                            )
                          "
                          style="width: 5rem; text-align: center !important"
                        >
                        </v-select>
                        <v-subheader>
                          Menampilkan data ke
                          {{ props.pagination.pageStart + 1 }} sampai {{ props.pagination.pageStop }} dari
                          {{ props.pagination.itemsLength }}
                        </v-subheader>
                      </div>
                      <v-btn icon small :disabled="certifGugur.page == 1" @click="certifGugur.page--">
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        :disabled="certifGugur.page == Math.ceil(certifGugur.total / certifGugur.limit)"
                        @click="certifGugur.page++"
                      >
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template slot="item.updated_at" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.updated_at | dateOnly }}
                </span>
              </template>
              <template slot="item.start_test" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.start_test | datetime }}
                </span>
              </template>
              <template slot="item.date_of_birth" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.member.date_of_birth | dateOnly }}
                </span>
              </template>
              <template slot="item.button" slot-scope="{ item }">
                <div class="d-flex text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        @click="filePDF(item.license)"
                        class="text-capitalize orange--text text--darken-1 pa-0"
                        target="blank"
                      >
                        <!-- :href="`https://www.yec.co.id/sertifikat-epot/?id=${item.id}`" -->
                        <v-icon size="20">ri-file-pdf-line</v-icon>
                      </v-btn>
                    </template>
                    <span>File Pdf</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize red--text pa-0"
                        @click="
                          dialog.preview = true;
                          certificate = item;
                        "
                      >
                        <v-icon size="20">$eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        @click="printPDF(item.license)"
                        class="text-capitalize green--text pa-0"
                        target="blank"
                      >
                        <!-- :href="`https://www.yec.co.id/sertifikat-epot-fisik/?id=${item.id}`" -->
                        <v-icon size="20">$print</v-icon>
                      </v-btn>
                    </template>
                    <span>Print</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="updateUser(item.member)"
                      >
                        <v-icon size="20">ri-user-settings-line</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit User</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="toogleChangeStatus(item)">
                        <v-icon size="20">$checkDouble</v-icon>
                      </v-btn>
                    </template>
                    <span>Beri Tanda Tercetak</span>
                  </v-tooltip> -->
                </div>
              </template>
              <template slot="item.printed" slot-scope="{ item }">
                <div
                  :class="
                    item.print_flag === true
                      ? 'subtitle-2 font-weight-bold text-center teal--text'
                      : 'subtitle-2 font-weight-bold text-center red--text text--darken-2'
                  "
                >
                  {{ item.print_flag == true ? "Sudah Dicetak" : "Belum Dicetak" }}
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
  <!-- <v-card
    id="certif"
    class="shadow-0 certif"
    elevation="0"
    style="background: transparent">
    <v-row>
      <v-col cols="12" md="12" class="text-right py-0">
        <div class="d-flex float-right">
          <v-autocomplete
            class="pt-1 mr-3 color-navy-soft"
            v-model="user"
            :items="users"
            :loading="userLoading"
            :filter="userFilter"
            :search-input.sync="userQuery"
            hide-details="auto"
            hide-no-data
            item-text="fullname"
            item-value="id"
            placeholder="Cari nama / email"
            prepend-icon="$search"
            return-object
            style="width: 210px">
            <div class="py-2" slot="item" slot-scope="{ item }">
              {{ item.fullname }} <br />
              <small class="color-navy-soft">{{ item.email }}</small>
            </div>
          </v-autocomplete>
          <v-btn v-if="user.id" color="primary" elevation="0" @click="user = {}">
            Reset
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="12" class="">
        <v-data-table
          :loading="certifLoading"
          disable-sort
          :headers="headers"
          :items="certifLoading ? [] : certif.list"
          :items-per-page.sync="certif.limit"
          :page.sync="certif.page"
          item-key="id"
          :mobile-breakpoint="0"
          :server-items-length="certif.total"
          loading-text="Loading... Please wait"
          hide-default-footer>
          <template slot="footer" slot-scope="{ props }">
            <v-row class="px-4">
              <div class="pa-3">
                <v-row align="center">
                  <div>
                    <v-subheader v-text="'Row per page: '"></v-subheader>
                  </div>
                  <div>
                    <v-select
                      class="mt-0 pt-0"
                      style="width: 4rem"
                      v-model="certif.limit"
                      :items="[10, 20, 50, 100]"
                      hide-details="auto"
                    ></v-select>
                  </div>
                </v-row>
              </div>
              <v-col>
                <v-row class="mx-3" justify="end" align="center">
                  <div class="d-flex flex-nowrap align-center">
                    <v-subheader class="mr-2">Halaman:</v-subheader>
                    <v-select
                      v-model="certif.page"
                      dense
                      solo
                      class="pa-0 ma-0 mr-2"
                      hide-details
                      :items="
                        Array.from(
                          { length: Math.ceil(certif.total / certif.limit) },
                          (_, i) => i + 1
                        )
                      "
                      style="width: 5rem"
                    ></v-select>
                    <v-subheader>
                      Menampilkan data ke
                      {{ props.pagination.pageStart + 1 }} sampai
                      {{ props.pagination.pageStop }} dari
                      {{ props.pagination.itemsLength }}
                    </v-subheader>
                  </div>
                  <v-btn
                    icon
                    small
                    :disabled="certif.page == 1"
                    @click="certif.page--"
                  >
                    <v-icon>$prev</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    :disabled="
                      certif.page == Math.ceil(certif.total / certif.limit)
                    "
                    @click="certif.page++"
                  >
                    <v-icon>$next</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </template>
          <template slot="item.end_test" slot-scope="{ item }">
            {{ item.end_test | dateOnly }}
          </template>
          <template slot="item.date_of_birth" slot-scope="{ item }">
            {{ item.member.date_of_birth | dateOnly }}
          </template>
          <template slot="item.button" slot-scope="{ item }">
            <div class="d-flex">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :href="`https://www.yec.co.id/sertifikat-epot/?id=${item.license}`"
                    target="blank"
                  >
                    <v-icon>ri-file-pdf-line</v-icon>
                  </v-btn>
                </template>
                <span>File PDF</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      dialog.preview = true;
                      certificate = item;
                    "
                  >
                    <v-icon>$eye</v-icon>
                  </v-btn>
                </template>
                <span>Preview</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :href="`https://www.yec.co.id/sertifikat-epot-fisik/?id=${item.license}`"
                    target="blank"
                  >
                    <v-icon>$print</v-icon>
                  </v-btn>
                </template>
                <span>Print</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="updateUser(item.member)"
                  >
                    <v-icon>ri-user-settings-line</v-icon>
                  </v-btn>
                </template>
                <span>Edit user</span>
              </v-tooltip>
              <v-tooltip bottom class="bg-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="item.status == 'Sudah'"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog.ubahBatalCetak = true"
                  >
                    <v-icon>$replace</v-icon>
                  </v-btn>
                </template>
                <span>Batalkan tandai tercetak</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="item.status == 'Belum cetak'"
                    class="mr-1 color-navy"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog.ubahCetak = true"
                  >
                    <v-icon>$checkDouble</v-icon>
                  </v-btn>
                </template>
                <span>Tandai tercetak</span>
              </v-tooltip>
            </div>
          </template>
          <template slot="item.printed" slot-scope="{ item }">
            <v-switch
              v-model="item.print_flag"
              @change="toogleStatus(item)"
            ></v-switch>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div id="printMe" v-show="srt">
      <printOut :data="certificate" />
    </div>

  </v-card> -->
</template>
<script>
import XLSX from 'xlsx'
import printPreview from "./TemplateCertificate";
import printOut from "./TemplateCertificateCetak";
import moment from "moment";

export default {
  name: "certificate-fisik",
  components: {
    printOut,
    printPreview,
  },
  data() {
    return {
      is_fisik: 1,
      items_selected: [
        {
          belum_cetak: [
            { title: 'Tandai Tidak dicetak',value: "set_error_yet" },
            { title: 'Tandai Sudah Cetak', value: "set_print" },
            { title: 'Tandai Sertifikat Non-Fisik', value: "set_non_fisik" },
          ],
        },
        {
          sudah_cetak: [
            { title: 'Tandai Tidak dicetak',value: "set_error_print" },
            { title: 'Tandai Belum Cetak', value: "set_print_yet" },
            { title: 'Tandai Sertifikat Non-Fisik', value: "set_print_non_fisik" },
          ],
        },
        {
          error_certif: [
            { title: 'Tandai Sertifikat Tidak Error',value: "unset_error" }
          ],
        }
      ],
      selected_title: 'Tandai Tidak dicetak',
      selected_value: 'set_error_yet',
      isUpdate: null,
      is_loading: false,
      modalUpdate: null,
      modalUpdateWordpress: false,
      modalUpdateItemWordpress:false,
      srt: false,
      color: "",
      certif: {
        limit: 10,
        page: 1,
      },
      certifPrinted: {
        limit: 10,
        page: 1,
      },
      certifError: {
        limit: 10,
        page: 1,
      },
      certifGugur: {
        limit: 10,
        page: 1,
      },
      certifLoading: false,
      institusions: [],
      certificate: {},
      data_change: {},
      all: [],
      form: {
        institusion_id: "",
        filter_date: "",
        filter_date_printed: "",
        filter_date_error: "",
        order_id: "",
        sertificate_id: "",
        print_flag: false,
        id: '',

        wordpress: {
          fullname: '',
          email: '',
          order_id: '',
          is_fisik: '',
          courier: '',
          courier_type: '',
          receiver: '',
          receiver_phone: '',
          receiver_address: '',
          receiver_post_code: '',
          order_note: '',
          item : {
            name : '',
            value: 0,
            quantity: 0,
            height: 0,
            length: 0,
            weight: 0,
            width : 0,
          }
        }
      },
      tracking: {
        shipper: {
          name: "",
          email: "",
          phone: "",
          organization: ""
        },
        origin: {
          contact_name: "",
          contact_phone: "",
          address: "",
          note: "",
          postal_code: 0
        },
        destination: {
          contact_name: "",
          contact_phone: "",
          contact_email: "",
          address: "",
          note: "",
          postal_code: 0
        },
        courier: {
          tracking_id: "",
          waybill_id: "WYB-",
          company: "jne",
          name: "",
          phone: "",
          type: "reg",
          link: "",
          history: [
          ]
        },
        delivery: {
          datetime: "2021-09-24T22:58+07:00",
          note: "",
          type: "now"
        },
        items: [
          {
            description: "Goods",
            height: 10,
            length: 10,
            name: "SERTIFIKAT-059KBZDVZD8KCV",
            quantity: 1,
            value: 165000,
            weight: 200,
            width: 10
          }
        ],
        price: 9000,
        note: "note order",
        status: "picking_up"
      },
      process: {
        run: false,
        form: false,
      },
      dialog: {
        filter: false,
        preview: false,
        ubahCetak: false,
        ubahBatalCetak: false,
        filtered: false,
        detailTracking: false
      },
      has_print: [
        { text: "Dicetak", value: true },
        { text: "Belum Dicetak", value: false },
      ],
      tab: 0,
      tabs: [
        { value: 0, label: "Belum Cetak" },
        { value: 1, label: "Sudah Cetak" },
        { value: 2, label: "Tidak dicetak" },
        { value: 3, label: "Gugur Test" },
      ],
      headers_gugur: [
        { text: "Order ID", align: "center", value: "wordpress.order_id" },
        { text: "Kode Lisensi", align: "center", value: "license" },
        { text: "Email", align: "left", value: "member.email" },
        { text: "Nama", align: "left", value: "member.fullname" },
        { text: "Tanggal Lahir", align: "center", value: "date_of_birth", width: 200 },
        { text: "Tanggal Tes", align: "center", value: "start_test", width: 250 },
        // { text: "Tanggal Cetak", align: "center", value: "updated_at" },
        // { text: "Status Cetak", align: "center", value: "printed" },
        { text: "Aksi", align: "center", value: "button" },
      ],
      headers_error: [
        { text: "Order ID", align: "center", value: "wordpress.order_id" },
        { text: "Kode Lisensi", align: "center", value: "license" },
        { text: "Email", align: "left", value: "member.email" },
        { text: "Nama", align: "left", value: "member.fullname" },
        { text: "Tanggal Lahir", align: "center", value: "date_of_birth", width: 200 },
        { text: "Tanggal Cetak", align: "center", value: "updated_at", width: 200 },
        { text: "Status Cetak", align: "center", value: "printed" },
        { text: "Aksi", align: "center", value: "button" },
      ],
      headers_belum: [
        { text: "Order ID", align: "center", value: "wordpress.order_id" },
        { text: "Kode Lisensi", align: "center", value: "license" },
        { text: "Email", align: "left", value: "member.email" },
        { text: "Nama", align: "left", value: "member.fullname" },
        { text: "Tanggal Lahir", align: "center", value: "date_of_birth", width: 200 },
        { text: "Tanggal Tes", align: "center", value: "start_test", width: 250 },
        { text: "Tandai Tercetak", align: "center", value: "printed" },
        { text: "Aksi", align: "center", value: "button" },
      ],
      userUpdate: {},
      users: [],
      user: {},
      searching: "",
      userQuery: "",
      userLoading: false,
      selected: {
        belum_cetak: [],
        sudah_cetak: [],
        certif_error: []
      },
      showSelect: false,
      notification: {
        state: false,
        color: "",
        text: "",
        timeout: 3000
      },
      id: "",
      menu: null,
    };
  },
  computed: {
    headers_tercetak(){
      let header = []
      if(this.is_fisik == 1){
        header = [
          { text: "Order ID", align: "center", value: "wordpress.order_id", width: 150 },
          { text: "Kode Lisensi", align: "center", value: "license", width: 150 },
          { text: "Email", align: "left", value: "member.email", width: 200 },
          { text: "Nama", align: "left", value: "member.fullname", width: 150 },
          { text: "Tanggal Lahir", align: "center", value: "date_of_birth", width: 200 },
          { text: "Tanggal Cetak", align: "center", value: "updated_at", width: 200 },
          { text: "Tandai Belum Cetak", align: "center", value: "printed", width: 150 },
          // { text: "Konfirmasi Pengiriman", align: "center", value: "shipping.order_id", width: 150 },
          // { text: "Nomor Resi", align: "center", value: "no_resi", width: 300 },
          // { text: "Status Pengiriman", align: "center", value: "shipping.status", width: 150 },
          { text: "Aksi", align: "center", value: "button", width: 150 },
        ]
      }else{
        header = [
          { text: "Order ID", align: "center", value: "wordpress.order_id" },
          { text: "Kode Lisensi", align: "center", value: "license" },
          { text: "Email", align: "left", value: "member.email" },
          { text: "Nama", align: "left", value: "member.fullname" },
          { text: "Tanggal Lahir", align: "center", value: "date_of_birth", width: 200 },
          { text: "Tanggal Cetak", align: "center", value: "updated_at", width: 200 },
          { text: "Tandai Belum Cetak", align: "center", value: "printed" },
          { text: "Aksi", align: "center", value: "button" },
        ]
      }

      return header
    },
    isDisabled: function() {
      return this.certif.sertificate_id;
    },
    FormartedDateFilter() {
      let filter_data = "";
      if (this.tab == 0) {
        filter_data = this.form.filter_date ? moment(this.form.filter_date).format("D MMMM YYYY") : "";
      }
      if (this.tab == 1) {
        filter_data = this.form.filter_date_printed ? moment(this.form.filter_date_printed).format("D MMMM YYYY") : "";
      }
      if (this.tab == 2) {
        filter_data = this.form.filter_date_error ? moment(this.form.filter_date_error).format("D MMMM YYYY") : "";
      }

      return filter_data;
    },
    FormartedDate() {
      return this.userUpdate.date_of_birth ? moment(this.userUpdate.date_of_birth).format("D MMMM YYYY") : "";
    },
    rules: () => ({
      required: (value) => !!value || "Tidak boleh kosong",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email tidak valid";
      },
      dateRules: (v) => {
        if (!v) return "Tanggal Lahir Tidak Boleh Kosong";
        // const fullDate = v.split("-");
        // const year = fullDate[0];
        // const month = fullDate[1];
        // const day = fullDate[2];

        const fullDate = v.split(" ");
        const day = fullDate[0];
        const month = fullDate[1];
        const year = fullDate[2];

        if (parseInt(year) < 1900) return "Tahun tidak boleh kurang dari 1900";
        if (!parseInt(year) || year.length != 4) return "Format tanggal tidak valid";
        if (!parseInt(month) || month.length != 2) return "Format tanggal tidak valid";
        if (!parseInt(day) || day.length != 2) return "Format tanggal tidak valid";

        if (!Date.parse(`${year}-${month}-${day}`)) {
          return "Format tanggal tidak valid";
        }

        return true;
      },
    }),
  },
  watch: {
    tab: function(val) {
      if (val == 0) {
        this.initialize();
        this.searching = "";
        this.selected_value = 'set_error_yet'
        this.selected_title = 'Tandai Tidak dicetak'
      }
      if (val == 1) {
        this.initializePrinted();
        this.searching = "";
        this.selected_value = 'set_error_print'
        this.selected_title = 'Tandai Tidak dicetak'
      }
      if (val == 2) {
        this.initializeError();
        this.searching = "";
        this.selected_value = 'unset_error'
        this.selected_title = 'Tandai Sertifikat Tidak Error'
      }
      if (val == 3) {
        this.initializeGugur();
        this.searching = "";
        this.selected_value = 'unset_gugur'
        this.selected_title = 'Tandai Sertifikat Tidak Gugur'
      }
    },
    userQuery(val) {
      // Items have already been requested
      this.userLoading = true;

      this.$http
        .get("/users/v1/admin/list-member", {
          params: {
            q: val,
            page: 1,
            limit: 5,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          this.users = res.data.list;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.userLoading = false));
    },
    user() {
      this.page = 1;
      this.initialize(this.tab);
    },
    "certif.limit": function() {
      this.initialize();
    },
    "certif.page": function() {
      this.initialize();
    },
    //Printed
    "certifPrinted.limit": function() {
      this.initializePrinted();
    },
    "certifPrinted.page": function() {
      this.initializePrinted();
    },
    // Error
    "certifError.limit": function() {
      this.initializeError();
    },
    "certifError.page": function() {
      this.initializeError();
    },
    // Gugur
    "certifGugur.limit": function() {
      this.initializeGugur();
    },
    "certifGugur.page": function() {
      this.initializeGugur();
    },
    "userUpdate.fullname": function(value) {
      if (value) {
        this.userUpdate.fullname = value
          .split(" ")
          .map((val) => {
            return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
          })
          .join(" ");
      }
    },
    "userUpdate.place_of_birth": function(value) {
      if (value) {
        this.userUpdate.place_of_birth = value
          .split(" ")
          .map((val) => {
            return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
          })
          .join(" ");
      }
    },
  },
  mounted() {},
  created() {
    this.$root.$emit("setPageTitle", "Certificate B2B");
    this.initialize();
    this.initializeInstitution()
  },
  methods: {
    filePDF(id){
      let windowLoc = window.location.hostname
      let url = ''

      if(windowLoc == 'cms-epot.yureka.dev' || windowLoc == 'localhost'){
        url = `https://www.yec.co.id/sertifikat-epot-dev/?id=${id}`
      }else{
        url = `https://www.yec.co.id/sertifikat-epot/?id=${id}`
      }

      window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      );
    },
    printPDF(id){
      let windowLoc = window.location.hostname
      let url = ''

      if(windowLoc == 'cms-epot.yureka.dev' || windowLoc == 'localhost'){
        url = `https://www.yec.co.id/sertifikat-epot-fisik-dev/?id=${id}`
      }else{
        url = `https://www.yec.co.id/sertifikat-epot-fisik/?id=${id}`
      }

      window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      );
    },
    filter() {
      if (this.tab == 0) {
        this.dialog.filtered = false;
        this.initialize();
      }
      if (this.tab == 1) {
        this.dialog.filtered = false;
        this.initializePrinted();
      }
      if (this.tab == 2) {
        this.dialog.filtered = false;
        this.initializeError();
      }
      if (this.tab == 3) {
        this.dialog.filtered = false;
        this.initializeGugur();
      }
    },
    updatedStatus(item, status){
      let log_error = []
      switch (status) {
        // TAB BELUM CETAK
        case 'set_error_yet':
          item.forEach((data) => {
            log_error.push(data.id)
          })
          this.setErrorStatus(log_error)
          // console.log('set_error_belum_cetak', log_error);
          break;
        case 'set_print':
           item.forEach((data) => {
            log_error.push(data.id)
          })
          this.setStatusCetak(log_error, true)
          // console.log('set_sudah_cetak', log_error);
          break
          // non fisik
        case 'set_print_non_fisik':
           item.forEach((data) => {
            log_error.push(data.id)
          })
          this.setSertifFisik(log_error, 0)
          // console.log('set_print_non_fisik', log_error);
          break
          // fisik
        case 'set_fisik':
           item.forEach((data) => {
            log_error.push(data.id)
          })
          this.setSertifFisik(log_error, 1)
          // console.log('set_fisik', log_error);
          break

        // TAB BELUM CETAK
        case 'set_error_print':
           item.forEach((data) => {
            log_error.push(data.id)
          })
          this.setErrorStatus(log_error)
          // console.log('set_error_sudah_cetak', log_error);
          break;
        case 'set_print_yet':
           item.forEach((data) => {
            log_error.push(data.id)
          })
          this.setStatusCetak(log_error, false)
          // console.log('set_belum_cetak', log_error);
          break;
        case 'set_non_fisik':
           item.forEach((data) => {
            log_error.push(data.id)
          })
          this.setSertifFisik(log_error, 0)
          // console.log('set_non_fisik', log_error);
          break
          // fisik
        case 'set_print_fisik':
           item.forEach((data) => {
            log_error.push(data.id)
          })
          this.setSertifFisik(log_error, 1)
          // console.log('set_print_fisik', log_error);
          break

        // TAB TIDAK CETAK
        case 'unset_error':
          item.forEach((data) => {
            log_error.push(data.id)
          })
          this.unsetErrorStatus(log_error)
          // console.log('certif_error', log_error);
          break;
      }
    },
    setSertifFisik(data,fisik){
      let params = {
        member_order_id: data,
        is_fisik: fisik
      }

      // console.log(params);

      this.axios.put(`/users/v1/admin/order/update-order/`,params,{
        headers: { "Content-Type": "application/json" },
      })
      .then(() => {
        this.notification.state = true,
        this.notification.color = "#4CAF50"
        this.notification.text =  "Berhasil Tandai sebagai Sertifikat No-Fisik"
        this.selected = {
          belum_cetak: [],
          sudah_cetak: [],
          certif_error: []
        }
        this.fetchAll()
      }).catch(error => {
        console.log(error.response)
        this.notification.state = true,
        this.notification.color = "error"
        this.notification.text =  "Gagal Tandai sebagai Sertifikat No-Fisik"
        this.selected = {
          belum_cetak: [],
          sudah_cetak: [],
          certif_error: []
        }
        this.fetchAll()
      })
    },
    setErrorStatus(data){
      let params = {
        order_id: data
      }

      // console.log(params);
      this.axios.put(`/users/v1/admin/order/sertificate/set-sertificate-error`,params,{
        headers: { "Content-Type": "application/json" },
      })
      .then(() => {
        this.notification.state = true,
        this.notification.color = "#4CAF50"
        this.notification.text =  "Berhasil Tandai sebagai Sertifikat Tidak dicetak"
        this.selected = {
          belum_cetak: [],
          sudah_cetak: [],
          certif_error: []
        }
        this.fetchAll()
      })
    },
    unsetErrorStatus(data){
      let params = {
        order_id: data
      }
      this.axios.put(`/users/v1/admin/order/sertificate/unset-sertificate-error`,params,{
        headers: { "Content-Type": "application/json" },
      })
      .then(() => {
        this.notification.state = true,
        this.notification.color = "#4CAF50"
        this.notification.text =  "Berhasil Tandai sebagai Sertifikat Tidak Error"
        this.selected = {
          belum_cetak: [],
          sudah_cetak: [],
          certif_error: []
        }
        this.fetchAll()
      })
    },
    setStatusCetak(data, printed){
      let params = {
        order_id: data,
        print: printed
      }
      this.axios.put(`/users/v1/admin/order/sertificate/set-sertificate-printflag`,params,{
        headers: { "Content-Type": "application/json" },
      })
      .then(() => {
        this.notification.state = true,
        this.notification.color = "#4CAF50"
        this.notification.text =  printed == 1 ? "Berhasil Tandai sebagai Sertifikat Sudah Cetak" : "Berhasil Tandai sebagai Sertifikat Belum Cetak"
        this.selected = {
          belum_cetak: [],
          sudah_cetak: [],
          certif_error: []
        }
        this.fetchAll()
      })
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    search() {
      if(this.is_fisik == 1){
        this.selected_title = 'Tandai Tidak dicetak'
        this.selected_value = 'set_error_yet'
        this.items_selected[0].belum_cetak[2].title = "Tandai Sertifikat Non-Fisik"
        this.items_selected[1].sudah_cetak[2].title = "Tandai Sertifikat Non-Fisik"
        // value
        this.items_selected[0].belum_cetak[2].value = "set_non_fisik"
        this.items_selected[1].sudah_cetak[2].value = "set_print_non_fisik"
      }else{
        this.selected_title = 'Tandai Tidak dicetak'
        this.selected_value = 'set_error_yet'
        this.items_selected[0].belum_cetak[2].title = "Tandai Sertifikat Fisik"
        this.items_selected[1].sudah_cetak[2].title = "Tandai Sertifikat Fisik"
        // value
        this.items_selected[0].belum_cetak[2].value = "set_fisik"
        this.items_selected[1].sudah_cetak[2].value = "set_print_fisik"
      }
      if (this.tab == 0) {
        this.initialize(this.searching);
      }
      
      if (this.tab == 1) {
        this.initializePrinted(this.searching);
      }

      if (this.tab == 2) {
        this.initializeError(this.searching);
      }

      if (this.tab == 3) {
        this.initializeGugur(this.searching);
      }
      // this.initialize(this.searching);
      // this.initializePrinted(this.searching);
      // this.initializeError(this.searching);
    },
    cancelSearch() {
      this.dialog.filter = true;
      this.filter.print_flag = "";
      this.fetchAll();
    },
    resetFilter() {
      this.form.order_id = ""
      if (this.tab == 0) {
        this.form.filter_date = ""
        this.dialog.filtered = false;
        this.initialize();
      }
      if (this.tab == 1) {
        this.form.filter_date_printed = ""
        this.dialog.filtered = false;
        this.initializePrinted();
      }
      if (this.tab == 2) {
        this.form.filter_date_error = ""
        this.dialog.filtered = false;
        this.initializeError();
      }
      if (this.tab == 3) {
        this.form.filter_date_error = ""
        this.dialog.filtered = false;
        this.initializeGugur();
      }
      // this.form = {
      //   filter_date: "",
      //   filter_date_printed: "",
      //   filter_date_error: "",
      //   order_id: "",
      // };
      // this.dialog.filtered = false;
      // this.fetchAll();
    },

    // # METHOD EXPORT EXCEL
    actionExport(){
      if (this.tab == 0) {
        this.certif.limit = this.certif.total
        this.initialize('','export');
      }
      
      if (this.tab == 1) {
        this.certifPrinted.limit = this.certifPrinted.total
        this.initializePrinted('','export');
      }

      if (this.tab == 2) {
        this.certifError.limit = this.certifError.total
        this.initializeError('','export');
      }

      if (this.tab == 3) {
        this.certifGugur.limit = this.certifGugur.total
        this.initializeExport('','export');
      }
      // this.listData.limit = this.listData.total; 
      // this.initialize('','export')
    },
    ExportExcel() { // On Click Excel download button
      // console.log(this.listData);
      let json = []

      if (this.tab == 0) {
        this.certif.list.forEach((item) => {
          json.push({
            "Nama": item.member.fullname,
            "Lisensi": item.license,
            "Tanggal Tes": item.start_test,
            "Skor": item.score,
          })
        })
      }
      
      if (this.tab == 1) {
        this.certif.list.forEach((item) => {
          json.push({
            "Nama": item.member.fullname,
            "Lisensi": item.license,
            "Tanggal Tes": item.start_test,
            "Skor": item.score,
          })
        })
      }

      if (this.tab == 2) {
        this.certif.list.forEach((item) => {
          json.push({
            "Nama": item.member.fullname,
            "Lisensi": item.license,
            "Tanggal Tes": item.start_test,
            "Skor": item.score,
          })
        })
      }

      var wscols = [
      { width: 30 },  // first column
      { width: 25 }, // second column
      { width: 25 }, //...
      { width: 10 },
    ];
    
      // export json to Worksheet of Excel
      // only array possible
      var PoinWS = XLSX.utils.json_to_sheet(json) 
      PoinWS['!cols'] = wscols;
      // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons) 

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, PoinWS, 'animals') // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')   

      // export Excel file
      XLSX.writeFile(wb, `DATA_SERTIFIKAT_B2B.xlsx`) // name of the file is 'book.xlsx'
      this.notification.state = true,
      this.notification.color = "#4CAF50"
      this.notification.text =  "Export Excel Berhasil"

      // GET IF EXPORT ENDED
      if (this.tab == 0) {
        this.certif.page = 1
        this.certif.limit = 10
        this.initialize('');
      }
      
      if (this.tab == 1) {
        this.certifPrinted.page = 1
        this.certifPrinted.limit = 10
        this.initializePrinted('');
      }

      if (this.tab == 2) {
        this.certifError.page = 1
        this.certifError.limit = 10
        this.initializeError('');
      }
    },

    // # ACTION GET
    async fetchAll() {
      if (this.tab == 0) {
         await this.initialize();
      }
      
      if (this.tab == 1) {
         await this.initializePrinted();
      }

      if (this.tab == 2) {
        await this.initializeError();
      }

      if (this.tab == 3) {
        await this.initializeGugur();
      }

      // await this.initialize();
      // await this.initializePrinted();
      // await this.initializeError();
    },
    initialize(value,state) {
      this.certifLoading = true;
      // console.log(this.is_fisik);

      this.$set(this.certif, "list", []);

      this.$http
        .get("/users/v1/admin/list-sertificate", {
          params: {
            // member_id: this.user.id || "",
            print_flag: 0,
            is_b2b: 1,
            institusion_id: this.form.institusion_id,
            start_test: this.form.filter_date,
            order_id: this.form.order_id,
            with_error_sertificate: 0,
            q: value,
            is_fisik: this.is_fisik,
            sort_by: "start_test",
            sort_type: "asc",
            limit: this.certif.limit,
            page: this.certif.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          // let total = 0;
          res.data.list = res.data.list == null ? [] : res.data.list;
          this.certif = res.data;
          this.certifLoading = false;

          if (res.data.total == this.certif.list.length) {
            if (state == 'export') {
              this.ExportExcel()
              this.certifLoading = false;
            }
            this.certifLoading = false;
          }

          // if (!this.certif.list.length) {
          //   this.certifLoading = false;
          //   return;
          // }
          // this.certif.list.forEach((val) => {
          //   this.axios
          //     .get("https://shop.yec.co.id/wp-json/yec/v1/get-order-id/1", {
          //       params: {
          //         lisensi: val.license,
          //       },
          //     })
          //     .then((res) => res.data[0])
          //     .then((res) => {
          //       this.$set(val, "order_id", res.post_id);
          //     })
          //     .catch(() => {
          //       this.$set(val, "order_id", "-");
          //     })
          //     .finally(() => {
          //       total++;
          //       if (total == this.certif.list.length) {
          //         this.certifLoading = false;
          //       }
          //     });
          // });
        })
        .catch((error) => {
          console.error(error);
          this.certifLoading = false;
        });
    },
    initializePrinted(value, state) {
      this.certifLoading = true;
      this.$set(this.certif, "list", []);

      this.$http
        .get("/users/v1/admin/list-sertificate", {
          params: {
            // member_id: this.user.id || "",
            print_flag: 1,
            is_b2b: 1,
            institusion_id: this.form.institusion_id,
            start_test: this.form.filter_date_printed,
            order_id: this.form.order_id,
            with_error_sertificate: 0,
            is_fisik: this.is_fisik,
            q: value,
            sort_by: "updated_at",
            sort_type: "desc",
            limit: this.certifPrinted.limit,
            page: this.certifPrinted.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          // let total = 0;
          res.data.list = res.data.list == null ? [] : res.data.list;
          this.certifPrinted = res.data;
          this.certifLoading = false;

          if (res.data.total == this.certif.list.length) {
            if (state == 'export') {
              this.ExportExcel()
              this.certifLoading = false;
            }
            this.certifLoading = false;
          }

          // if (!this.certifPrinted.list.length) {
          //   this.certifLoading = false;
          //   return;
          // }
          // this.certifPrinted.list.forEach((val) => {
          //   this.axios
          //     .get("https://shop.yec.co.id/wp-json/yec/v1/get-order-id/1", {
          //       params: {
          //         lisensi: val.license,
          //       },
          //     })
          //     .then((res) => res.data[0])
          //     .then((res) => {
          //       this.$set(val, "order_id", res.post_id);
          //     })
          //     .catch(() => {
          //       this.$set(val, "order_id", "-");
          //     })
          //     .finally(() => {
                // total++;
                // if (total == this.certifPrinted.list.length) {
                //   this.certifLoading = false;
                // }
          //     });
          // });
        })
        .catch((error) => {
          console.error(error);
          this.certifLoading = false;
        });
    },
    initializeError(value, state) {
      this.certifLoading = true;
      this.$set(this.certif, "list", []);

      this.$http
        .get("/users/v1/admin/order/sertificate/list-sertificate-error", {
          params: {
            // member_id: this.user.id || "",
            max_score: 400,
            is_b2b: 1,
            institusion_id: this.form.institusion_id,
            start_test: this.form.filter_date_error,
            order_id: this.form.order_id,
            is_fisik: this.is_fisik,
            q: value,
            sort_by: "updated_at",
            sort_type: "desc",
            limit: this.certifError.limit,
            page: this.certifError.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          // let total = 0;
          res.data.list = res.data.list == null ? [] : res.data.list;
          this.certifError = res.data;
          this.certifLoading = false;

          if (res.data.total == this.certif.list.length) {
            if (state == 'export') {
              this.ExportExcel()
              this.certifLoading = false;
            }
            this.certifLoading = false;
          }

          // if (!this.certifError.list.length) {
          //   this.certifLoading = false;
          //   return;
          // }
          // this.certifError.list.forEach((val) => {
          //   this.axios
          //     .get("https://shop.yec.co.id/wp-json/yec/v1/get-order-id/1", {
          //       params: {
          //         lisensi: val.license,
          //       },
          //     })
          //     .then((res) => res.data[0])
          //     .then((res) => {
          //       this.$set(val, "order_id", res.post_id);
          //     })
          //     .catch(() => {
          //       this.$set(val, "order_id", "-");
          //     })
          //     .finally(() => {
          //       total++;
          //       if (total == this.certifError.list.length) {
          //         this.certifLoading = false;
          //       }
          //     });
          // });
        })
        .catch((error) => {
          console.error(error);
          this.certifLoading = false;
        });
    },
    initializeGugur(value, state) {
      this.certifLoading = true;
      this.$set(this.certif, "list", []);

      this.$http
        .get("/users/v1/admin/list-sertificate", {
          params: {
            // member_id: this.user.id || "",
            // max_score: 400,
            is_b2b: 1,
            is_disqualified: true,
            institusion_id: this.form.institusion_id,
            start_test: this.form.filter_date_error,
            order_id: this.form.order_id,
            // with_error_sertificate:  1,
            // is_fisik: this.is_fisik,
            q: value,
            sort_by: "updated_at",
            sort_type: "desc",
            limit: this.certifGugur.limit,
            page: this.certifGugur.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          // let total = 0;
          res.data.list = res.data.list == null ? [] : res.data.list;
          this.certifGugur = res.data;
          this.certifLoading = false;

          if (res.data.total == this.certif.list.length) {
            if (state == 'export') {
              this.ExportExcel()
              this.certifLoading = false;
            }
            this.certifLoading = false;
          }
        })
        .catch((error) => {
          console.error(error);
          this.certifLoading = false;
        });
    },
    initializeInstitution(){
      this.axios.get("/institusion/v1/get/institusion/list", {
        params: {
          limit: 1000000,
        },
      })
      .then((res) => res.data)
      .then((res) => {
        if (res.status === 200) {
          res.data.institusions = res.data.institusions == null ? [] : res.data.institusions;
          this.institusions = res.data.institusions;
        }
      })
      .catch((error) => {
        console.error(error);
        this.process.run = false;
        this.dialog.filtered = false;
        this.certifLoading = false;
      });
    },
    print(certificate) {
      this.certificate = certificate;
      setTimeout(() => {
        this.$htmlToPaper("printMe");
      }, 100);
    },
    // item, queryText, itemText
    userFilter() {
      return this.users;
    },
    getDetailCertif(id) {
      this.id = id;
      this.axios.get(`/storage/v1/media/user-detail/${this.id}`).then((response) => {
        let res = response.data.data;
        this.loading = false;
        if (response.status == 200) {
          this.loading = false;
          let certificate = res.public_url;
          window.open(certificate);
        }
      });
    },
    toogleChangeStatus(item) {
      this.data_change = item;
      if (item.print_flag == true) {
        this.dialog.ubahCetak = true;
      } else {
        this.dialog.ubahBatalCetak = true;
      }
    },
    changeStatus(item) {
      this.axios
        .put(`/users/v1/admin/order/update-print-flag/${item.id}`, {})
        .then(() => {
          this.notification.state = true,
          this.notification.color = "#4CAF50"
          this.notification.text = item.print_flag ? "Berhasil tandai tercetak" : "Tandai tercetak dibatalkan"
          this.dialog.ubahCetak = false;
          this.dialog.ubahBatalCetak = false;
          this.fetchAll();
        })
        .catch((error) => {
          this.$set(item, "print_flag", !item.print_flag);
          this.notification.state = true,
          this.notification.color = "error"
          this.notification.text = "Gagal tandai tercetak",
          console.error(error);
        });
    },
    updateUser(user) {
      this.modalUpdate = true;
      this.userUpdate = JSON.parse(JSON.stringify(user));
    },
    update() {
      if (this.$refs.updateForm.validate()) {
        const params = {
          fullname: this.userUpdate.fullname,
          place_of_birth: this.userUpdate.place_of_birth,
          date_of_birth: this.userUpdate.date_of_birth,
          email: this.userUpdate.email,
        };
        const url = `/users/v1/admin/recap/member/update/${this.userUpdate.id}`;

        this.axios
          .put(url, params)
          .then(() => {
            this.notification.state = true;
            this.notification.text = "Data berhasil diubah";
            this.notification.color = "#652065";
          })
          .catch((error) => {
            let message = error.message;
            if (error.response) {
              message = error.response.data.message;
            }

            this.notification.state = true;
            this.notification.color = "error";
            this.notification.text = "Error: " + message;
            console.error(error);
          })
          .finally(() => {
            this.fetchAll();
            this.userUpdate = {};
            this.modalUpdate = false;
            this.$refs.updateForm.reset();
          });
      }
    },
    confirmupdateWordpress(item, state){
      this.form = {
        id: item.id,
        wordpress: {
          fullname: item.wordpress.fullname,
          email: item.wordpress.email,
          order_id: item.wordpress.order_id,
          is_fisik: item.wordpress.is_fisik,
          courier: item.wordpress.courier,
          courier_type: item.wordpress.courier_type,
          receiver: item.wordpress.receiver,
          receiver_phone: item.wordpress.receiver_phone,
          receiver_address: item.wordpress.receiver_address,
          receiver_post_code: item.wordpress.receiver_post_code,
          order_note: item.wordpress.order_note,
          item : {
            name : item.wordpress.item.name,
            value: item.wordpress.item.value,
            quantity: item.wordpress.item.quantity,
            height: item.wordpress.item.height,
            length: item.wordpress.item.length,
            weight: item.wordpress.item.weight,
            width : item.wordpress.item.width,
          }
        }
      }
      // this.form.id = id
      if (state == 'item') {
        this.modalUpdateItemWordpress = true
      }else{
        this.modalUpdateWordpress = true
      }
    },
    closeUpdateWordpressData(state){
      console.log(state);
      this.form = {
        id: '',
        wordpress: {
          fullname: '',
          email: '',
          order_id: '',
          is_fisik: '',
          courier: '',
          courier_type: '',
          receiver: '',
          receiver_phone: '',
          receiver_address: '',
          receiver_post_code: '',
          order_note: '',
          item : {
            name : '',
            value: 0,
            quantity: 0,
            height: 0,
            length: 0,
            weight: 0,
            width : 0,
          }
        }
      }

      if (state == 'all') {
        this.$refs.updateFormWordpress.reset();
        this.modalUpdateWordpress = false;
      }else{
        this.$refs.updateFormItemWordpress.reset();
        this.modalUpdateItemWordpress = false
      }
    },
    updateWordpressData(id){
      this.is_loading = true
      if (this.$refs.updateFormWordpress.validate()) {
        const params = {
          fullname: this.form.wordpress.fullname,
          email: this.form.wordpress.email,
          order_id: parseInt(this.form.wordpress.order_id),
          is_fisik: 1,
          courier: this.form.wordpress.courier,
          courier_type: this.form.wordpress.courier_type,
          receiver: this.form.wordpress.receiver,
          receiver_phone: this.form.wordpress.receiver_phone,
          receiver_address: this.form.wordpress.receiver_address,
          receiver_post_code: parseInt(this.form.wordpress.receiver_post_code),
          order_note: this.form.wordpress.order_note,
          item : {
            name : this.form.wordpress.item.name,
            value: parseInt(this.form.wordpress.item.value),
            quantity: parseInt(this.form.wordpress.item.quantity),
            height: parseInt(this.form.wordpress.item.height),
            length: parseInt(this.form.wordpress.item.length),
            weight: parseInt(this.form.wordpress.item.weight),
            width : parseInt(this.form.wordpress.item.width),
          }
        };
        const url = `/users/v1/admin/order/update-order/wordpress/${id}`;

        this.axios
          .put(url, params)
          .then(() => {
            this.is_loading = false
            this.notification.state = true;
            this.notification.text = "Data berhasil diubah";
            this.notification.color = "#4CAF50";
            this.modalUpdateWordpress = false
            this.manualOrder(id)
          })
          .catch((error) => {
            let message = error.message;
            if (error.response) {
              message = error.response.data.message;
            }

            this.is_loading = false
            this.notification.state = true;
            this.notification.color = "error";
            this.notification.text = "Error: " + message;
            this.modalUpdateWordpress = false
            console.error(error);
          })
          .finally(() => {
            this.fetchAll();
            this.userUpdate = {};
            this.modalUpdateWordpress = false
            this.is_loading = false
            this.$refs.updateFormWordpress.reset();
          });
      }else{
        this.is_loading = false 
      }
    },
    confirmSend(id) {
      this.axios
        .get(`/users/v1/admin/order/shipping/confirm/${id}`, {})
        .then((response) => {
          if (response.status == 200) {
            this.notification.state = true,
            this.notification.color = "#4CAF50"
            this.notification.text = "Success: Order status updated to confirmed"
            this.fetchAll(this.tab);
            this.initializePrinted();
            // this.initializeNotSended(this.tab);
          }
        })
        .catch((error) => {
          let message = error.message;
          if (error.response) {
            message = error.response.data.message;
          }

          this.notification.state = true;
          this.notification.color = "error";
          this.notification.text = "Error: " + message;
          console.error(error);
        });
    },
    manualOrder(id){
      // v1/admin/order/shipping/create/615dc8e5357844be687cd1e7
      this.axios.get(`/users/v1/admin/order/shipping/create/${id}`).then((response) => {
        console.log('response',response);
        let res = response.data
        console.log('res', res);
        this.loading = false;
        if (res.status == 200) {
          this.notification.state = true,
          this.notification.color = "#4CAF50"
          this.notification.text = "Berhasil Membuat Order"
          this.fetchAll(this.tab);
          this.initializePrinted();
          // this.initializeNotSended(this.tab);
        }
      }).catch((error) => {
        let message = error.message;
        if (error.response) {
          message = error.response.data.message;
        }

        this.notification.state = true;
        this.notification.color = "error";
        this.notification.text = "Error: " + message;
        console.error(error);
      })
      .finally(() => {
        this.fetchAll();
        this.userUpdate = {};
        this.modalUpdate = false;
        this.$refs.updateForm.reset();
      });
    },
    trackingDetail(order_id){
      this.loading = false;
      this.axios.get(`/users/v1/admin/order/shipping/history/${order_id}`).then((response) => {
        let res = response.data;
        if (response.status == 200) {
          this.dialog.detailTracking = true
          this.loading = false;
          this.tracking = res.data;
          console.log(res);
        }else{
          this.loading = false;
          this.notification.state = true;
          this.notification.color = "error";
          this.notification.text = res.message;
        }
      }).catch((error) => {
        this.loading = false;
        let message = error.message;
        if (error.response) {
          message = error.response.data.message;
        }

        this.notification.state = true;
        this.notification.color = "error";
        this.notification.text = "Error: " + message;
      });
    },
    copyLisensi(index){
      /* Get the text field */
      var copyText = document.getElementById(`waybill-id-${index}`);

      /* Select the text field */
      copyText.select();
      // copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);
      
      /* Alert the copied text */
      this.notification.state = true;
      this.notification.color = "#4CAF50"
      this.notification.timeout = 1000
      this.notification.text = "Copied the text: " + copyText.value
    },
  },
};
</script>

<style>
body {
  font-size: 16px !important;
}
.v-dialog {
  border-radius: 0px !important;
}
.title {
  font-weight: 600;
  font-size: 18px;
}
.bg-modal {
  position: fixed !important;
  width: 100% !important;
  background-color: rgb(0 0 0 / 0%) !important;
}
.bg-tooltip {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 14px 16px;
}
.v-simple-checkbox .v-icon {
  cursor: pointer;
  color: #652065 !important;
}

/* CSS TIMELINE */
span.label {
  font-weight: 600;
  color: #888888;
}
.inline {
  display: inline-block;
  vertical-align: top;
}
.item .time {
  width: 130px;
}
.item .timeline {
  width: 100px;
  height: 100px;
  position: relative;
}
.item:not(:last-child) .timeline::after {
  content: '';
  position: absolute;
  width: 3px;
  /* background-color: #eee; */
  background-color: #652065;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  transform: translateY(10px);
}
span.point {
  position: absolute;
  width: 19px;
  height: 19px;
  /* background-color: #fff;
  border: 3px solid #eee; */
  background-color: #652065;
  border: 3px solid #652065;
  border-radius: 100%;
  left: -4px;
  right: 0;
  top: 3px;
  margin: auto;
  z-index: 1;
}

/* CSS TIMELINE */

@media print {
  @page {
    size: landscape;
  }
}
</style>
