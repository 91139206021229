<template>
  <v-col class="py-0">
    <v-text-field
      outlined
      filled
      label="Text jawaban singkat"
      hide-details="auto"
      v-model="correct"
    ></v-text-field>
  </v-col>
</template>

<script>
export default {
  name: "short-answer",
  props: {
    config: Object,
  },
  data() {
    return {
      correct: null,
    };
  },
  created() {
    if (this.config.correct != undefined) {
      this.correct = this.config.correct;
    }
  },
  watch: {
    correct() {
      this.$emit("update", { correct: this.correct });
    },
  },
};
</script>