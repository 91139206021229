<template>
  <v-row>
    <single-choice
      v-if="type == 'single_choice'"
      :disabled="disabled"
      :config="config.single"
      @update="$set(config, 'single', $event)"
    ></single-choice>

    <multiple-choice
      v-if="type == 'multiple_choice'"
      :disabled="disabled"
      :config="config.multi"
      @update="$set(config, 'multi', $event)"
    ></multiple-choice>

    <upload-file
      v-if="type == 'upload'"
      :disabled="disabled"
      :config="config.upload"
      @update="$set(config, 'upload', $event)"
    ></upload-file>

    <short-answer
      v-if="type == 'short'"
      :disabled="disabled"
      :config="config.short"
      @update="$set(config, 'short', $event)"
    ></short-answer>

    <sorting-answer
      v-if="type == 'sorting'"
      :disabled="disabled"
      :config="config.sorting"
      @update="$set(config, 'sorting', $event)"
    ></sorting-answer>
  </v-row>
</template>

<script>
import * as AnswerTypes from "./types";

export default {
  name: "answer-type",
  components: {
    "single-choice": AnswerTypes.SingleChoice,
    "multiple-choice": AnswerTypes.MultipleChoice,
    "upload-file": AnswerTypes.UploadFile,
    "short-answer": AnswerTypes.ShortAnswer,
    "sorting-answer": AnswerTypes.SortingAnswer,
  },
  props: {
    type: String,
    data: Object,
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      config: {
        single: {},
        multi: {},
        upload: {},
        short: {},
        sorting: {},
      },
    };
  },
  mounted() {
    let data = {};
    switch (this.type) {
      case "single_choice":
        data.options = this.data.options.map((opt) =>
          Object.assign({}, opt, { text: String(opt.text) })
        );
        data.correct = String(this.data.correct_answer[0]);
        this.config.single = data;
        break;
      case "multiple_choice":
        data.options = this.data.options.map((opt) =>
          Object.assign({}, opt, { text: String(opt.text) })
        );
        data.correct = this.data.correct_answer;
        this.config.multi = data;
        break;
      default:
        this.config[this.type] = this.data;
        break;
    }
  },
  methods: {
    update() {
      let config;
      let data = {};

      switch (this.type) {
        case "single_choice":
          config = this.config.single;
          data.options = config.options != undefined ? config.options : [];
          data.correct_answer =
            config.correct != undefined ? [config.correct] : [];
          this.$emit("update", data);
          break;
        case "multiple_choice":
          config = this.config.multi;
          data.options = config.options != undefined ? config.options : [];
          data.correct_answer =
            config.correct != undefined ? config.correct : [];
          this.$emit("update", data);
          break;
        case "upload":
          config = this.config.upload;
          this.$emit("update", config);
          break;
        case "short":
          config = this.config.short;
          this.$emit("update", config);
          break;
        case "sorting":
          config = this.config.sorting;
          this.$emit("update", config);
          break;
        default:
          break;
      }
    },
  },
  watch: {
    config: {
      handler: function () {
        this.update();
      },
      deep: true,
    },
    type: function () {
      this.update();
    },
  },
};
</script>