<template>
  <v-card class="shadow-0" elevation="0" style="background: transparent">
    <!-- NOTIFICATION -->
    <v-snackbar :color="color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- DIALOG CONFIRMATION PRINT -->
    <v-dialog v-model="dialog.confirmationPrint" persistent max-width="375">
      <v-card class="pa-3">
        <v-card-title class="title">Peringatan !</v-card-title>
        <v-card-text>
          Apakah anda yakin ingin mengubah sertifikat ini sebagai tercetak ?
        </v-card-text>
        <v-card-actions class="pb-1">
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#652065"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="changeStatus(data_change, 'iya')"
          >
            Yakin
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.run"
            @click="changeStatus(data_change, 'batal')"
          >
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="8" class="d-flex">
        <v-tabs
          v-model="tab"
          class="text-capitalize my-auto badged-tabs mb-5"
          color="primary"
          show-arrows="mobile">
          <v-tab class="text-capitalize">
            <v-badge :content="String(listNotSended.total || '0')" inline>
              Belum Dikirim
            </v-badge>
          </v-tab>
          <v-tab class="text-capitalize">
            <v-badge :content="String(listSended.total || '0')" inline>
              Sudah Dikirim
            </v-badge>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="searching"
          class="mr-3"
          outlined
          dense
          prepend-inner-icon="ri-search-line"
          placeholder="Cari Nama / Email . . ."
          hide-details="auto">
          <template v-slot:append v-if="searching">
            <v-icon @click="searching = ''">ri-close-line</v-icon>
          </template>
        </v-text-field>
        <!-- <v-autocomplete
          class="pt-1 mr-3 color-navy-soft"
          v-model="user"
          :items="users"
          :loading="userLoading"
          :filter="userFilter"
          :search-input.sync="userQuery"
          clearable
          hide-details="auto"
          hide-no-data
          item-text="fullname"
          item-value="id"
          placeholder="Cari nama / email"
          prepend-icon="$search"
          return-object>
          <div class="py-2" slot="item" slot-scope="{ item }">
            {{ item.fullname }} <br />
            <small class="color-navy-soft">{{ item.email }}</small>
          </div>
          <template v-slot:selection="{ attrs, item, selected }">
            <v-chip
              v-if="item"
              v-bind="attrs"
              color="grey lighten-2"
              :input-value="selected">
              <span class="subtitle-2 pr-5 text-capitalize">
                {{ item.fullname }}
              </span>
              <v-icon size="25" color="#d31145" @click="user = {}">
                $close
              </v-icon>
            </v-chip>
          </template>
        </v-autocomplete> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="mb-10">
          <div v-if="tab == 0">
            <v-data-table
              :loading="process.run"
              disable-sort
              :headers="headers_not_sended"
              :items="process.run ? [] : listNotSended.list"
              :items-per-page.sync="listNotSended.limit"
              :page.sync="listNotSended.page"
              item-key="id"
              :mobile-breakpoint="0"
              :server-items-length="listNotSended.total"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
              <template slot="footer" slot-scope="{ props }">
                <v-row class="px-4">
                  <div class="pa-3">
                    <v-row align="center">
                      <div>
                        <v-subheader
                          v-text="'Baris Per Halaman: '"
                        ></v-subheader>
                      </div>
                      <div>
                        <v-select
                          class="mt-0 pt-0"
                          style="width: 4rem"
                          v-model="listNotSended.limit"
                          dense
                          :items="[10, 20, 50, 100]"
                          hide-details="auto"
                        >
                        </v-select>
                      </div>
                    </v-row>
                  </div>
                  <v-col>
                    <v-row class="mx-3" justify="end" align="center">
                      <div class="d-flex flex-nowrap align-center">
                        <v-subheader class="mr-2">Halaman:</v-subheader>
                        <v-select
                          v-model="listNotSended.page"
                          dense
                          class="pa-0 ma-0 mr-2"
                          hide-details
                          :items="
                            Array.from(
                              {
                                length: Math.ceil(
                                  listNotSended.total / listNotSended.limit
                                ),
                              },
                              (_, i) => i + 1
                            )
                          "
                          style="width: 5rem; text-align: center !important"
                        >
                        </v-select>
                        <v-subheader>
                          Menampilkan data ke
                          {{ props.pagination.pageStart + 1 }} sampai
                          {{ props.pagination.pageStop }} dari
                          {{ props.pagination.itemsLength }}
                        </v-subheader>
                      </div>
                      <v-btn
                        icon
                        small
                        :disabled="listNotSended.page == 1"
                        @click="listNotSended.page--"
                      >
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        :disabled="
                          listNotSended.page ==
                          Math.ceil(listNotSended.total / listNotSended.limit)
                        "
                        @click="listNotSended.page++"
                      >
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template slot="item.print_date" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.print_date | datetime }}
                </span>
              </template>
              <template slot="item.button" slot-scope="{ item }">
                <div class="d-flex text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize orange--text text--darken-1 pa-0"
                        :href="`https://www.yec.co.id/sertifikat-epot/?id=${item.license}`"
                        target="blank"
                      >
                        <v-icon size="20">ri-file-pdf-line</v-icon>
                      </v-btn>
                    </template>
                    <span>File Pdf</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize red--text pa-0"
                        @click="dialog.preview = true"
                      >
                        <v-icon size="20">$eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize green--text pa-0"
                        :href="`https://www.yec.co.id/sertifikat-epot-fisik/?id=${item.license}`"
                        target="blank"
                      >
                        <v-icon size="20">$print</v-icon>
                      </v-btn>
                    </template>
                    <span>Print</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="updateUser(item.member)"
                      >
                        <v-icon size="20">ri-user-settings-line</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit User</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="
                          dialog.ubahCetak = true;
                          form.order_id = item.id;
                          form.print_flag = item.print_flag;
                        "
                      >
                        <v-icon size="20">$checkDouble</v-icon>
                      </v-btn>
                    </template>
                    <span>Beri Tanda Tercetak</span>
                  </v-tooltip>
                </div>
              </template>
              <template slot="item.printed" slot-scope="{ item }">
                <v-switch
                  inset
                  v-model="item.print_flag"
                  @change="changeStatus(item)"
                >
                </v-switch>
              </template>
            </v-data-table>
          </div>
          <div v-if="tab == 1">
            <v-data-table
              :loading="process.run"
              disable-sort
              :headers="headers_sended"
              :items="process.run ? [] : listSended.list"
              :items-per-page.sync="listSended.limit"
              :page.sync="listSended.page"
              item-key="id"
              :mobile-breakpoint="0"
              :server-items-length="listSended.total"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
              <template slot="footer" slot-scope="{ props }">
                <v-row class="px-4">
                  <div class="pa-3">
                    <v-row align="center">
                      <div>
                        <v-subheader
                          v-text="'Baris Per Halaman: '"
                        ></v-subheader>
                      </div>
                      <div>
                        <v-select
                          class="mt-0 pt-0"
                          style="width: 4rem"
                          v-model="listSended.limit"
                          dense
                          :items="[10, 20, 50, 100]"
                          hide-details="auto"
                        >
                        </v-select>
                      </div>
                    </v-row>
                  </div>
                  <v-col>
                    <v-row class="mx-3" justify="end" align="center">
                      <div class="d-flex flex-nowrap align-center">
                        <v-subheader class="mr-2">Halaman:</v-subheader>
                        <v-select
                          v-model="listSended.page"
                          dense
                          class="pa-0 ma-0 mr-2"
                          hide-details
                          :items="
                            Array.from(
                              {
                                length: Math.ceil(
                                  listSended.total / listSended.limit
                                ),
                              },
                              (_, i) => i + 1
                            )
                          "
                          style="width: 5rem; text-align: center !important"
                        >
                        </v-select>
                        <v-subheader>
                          Menampilkan data ke
                          {{ props.pagination.pageStart + 1 }} sampai
                          {{ props.pagination.pageStop }} dari
                          {{ props.pagination.itemsLength }}
                        </v-subheader>
                      </div>
                      <v-btn
                        icon
                        small
                        :disabled="listSended.page == 1"
                        @click="listSended.page--"
                      >
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        small
                        :disabled="
                          listSended.page ==
                          Math.ceil(listSended.total / listSended.limit)
                        "
                        @click="listSended.page++"
                      >
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <template slot="item.send_date" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.send_date | datetime }}
                </span>
              </template>
              <template slot="item.print_date" slot-scope="{ item }">
                <span class="text-center">
                  {{ item.print_date | datetime }}
                </span>
              </template>
              <template slot="item.button" slot-scope="{ item }">
                <div class="d-flex text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize orange--text text--darken-1 pa-0"
                        :href="`https://www.yec.co.id/sertifikat-epot/?id=${item.license}`"
                        target="blank"
                      >
                        <v-icon size="20">ri-file-pdf-line</v-icon>
                      </v-btn>
                    </template>
                    <span>File Pdf</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize red--text pa-0"
                        @click="dialog.preview = true"
                      >
                        <v-icon size="20">$eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize green--text pa-0"
                        :href="`https://www.yec.co.id/sertifikat-epot-fisik/?id=${item.license}`"
                        target="blank"
                      >
                        <v-icon size="20">$print</v-icon>
                      </v-btn>
                    </template>
                    <span>Print</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="updateUser(item.member)"
                      >
                        <v-icon size="20">ri-user-settings-line</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit User</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        small
                        text
                        min-width="30"
                        class="text-capitalize blue--text pa-0"
                        @click="
                          dialog.ubahCetak = true;
                          form.order_id = item.id;
                          form.print_flag = item.print_flag;
                        "
                      >
                        <v-icon size="20">$checkDouble</v-icon>
                      </v-btn>
                    </template>
                    <span>Beri Tanda Tercetak</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: {
        confirmationPrint: false,
      },
      process: {
        run: false,
      },
      listSended: {
        limit: 10,
        page: 1,
      },
      listNotSended: {
        limit: 10,
        page: 1,
      },
      tab: 0,
      tabs: [
        { value: 0, text: "Belum Dikirim" },
        { value: 1, text: "Sudah Dikirim" },
      ],
      headers_sended: [
        { text: "Order ID", align: "center", value: "order_id" },
        { text: "Email", align: "left", value: "email" },
        { text: "Nama", align: "left", value: "name" },
        {
          text: "Tanggal Cetak Sertifikat",
          align: "center",
          value: "print_date",
        },
        { text: "Tanggal Cetak Resi", align: "center", value: "send_date" },
      ],
      headers_not_sended: [
        { text: "Order ID", align: "center", value: "order_id" },
        { text: "Email", align: "left", value: "email" },
        { text: "Nama", align: "left", value: "name" },
        {
          text: "Tanggal Cetak Sertifikat",
          align: "center",
          value: "print_date",
        },
        { text: "Tandai Resi Terkirim", align: "center", value: "printed" },
      ],
      data_change: [],
      users: [],
      user: {},
      userQuery: "",
      searching:"",
      id: "",
      userLoading: false,
      notification: {
        state: false,
        text: "",
      },
      color: "",
    };
  },
  watch: {
    tab: function (val) {
      if (val == 0) {
        // this.initializeSended(val)
        this.initializeNotSended();
      }
      if (val == 1) {
        this.initializeSended();
        // this.initializeNotSended(val)
      }
    },
    searching: function(newval){
      if (this.tab == 0) {
        setTimeout(() => {
          this.initializeNotSended(newval);
        },800)
      }
      if (this.tab == 1) {
        setTimeout(() => {
          this.initializeSended(newval);
        },800)
      }
    },
    "listSended.limit": function () {
      this.initializeSended();
    },
    "listSended.page": function () {
      this.initializeSended();
    },
    "listNotSended.limit": function () {
      this.initializeNotSended();
    },
    "listNotSended.page": function () {
      this.initializeNotSended();
    },
    userQuery(val) {
      // Items have already been requested
      this.userLoading = true;

      this.$http
        .get("/users/v1/admin/list-member", {
          params: {
            q: val,
            page: 1,
            limit: 5,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          this.users = res.data.list;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.userLoading = false));
    },
    user() {
      this.page = 1;
      this.initializeSended();
      this.initializeNotSended();
    },
  },
  computed: {},
  created() {
    this.initializeSended();
    this.initializeNotSended();
  },
  methods: {
    userFilter() {
      return this.users;
    },
    toogleChangeStatus(item) {
      this.data_change = item;
      if (item.print_flag == true) {
        this.dialog.confirmationPrint = true;
      }
    },
    changeStatus(item) {
      this.axios
        .put(`/users/v1/admin/order/update-send-flag/${item.id}`, {})
        .then(() => {
          this.notification = {
            state: true,
            text: item.print_flag
              ? "Berhasil Tandai sebagi Terkirim"
              : "Tandai Terkirim dibatalkan",
          };
          this.color = "#652065";
          this.dialog.confirmationPrint = false;
          this.initializeSended(this.tab);
          this.initializeNotSended(this.tab);
        })
        .catch((error) => {
          this.color = "error";
          this.$set(item, "print_flag", !item.print_flag);
          this.notification = {
            state: true,
            text: "Gagal tandai Terkirim",
          };
          console.log(error);
        });
    },
    // async initializeSended(sort = "", dir = "asc") {
    //   this.process.run = true;
    //   this.$set(this.listSended, "list", []);
    //   axios.get(`/users/v1/admin/list-sertificate/send-list`,{
    //     params: {
    //       // member_id: this.user.id || "",
    //       limit: this.listSended.limit,
    //       page: this.listSended.page,
    //       // print_flag: flag,
    //       is_send: true,
    //       sort,
    //       dir
    //     }
    //   }).then(res => {
    //     this.listSended = res.data.data;
    //   })
    //   this.process.run = false;
    // },
    initializeSended(searching) {
      this.process.run = true;
      this.$set(this.listSended, "list", []);

      axios
        .get("/users/v1/admin/list-sertificate/send-list", {
          params: {
            query: searching,
            is_send: true,
            // max_score: score,
            limit: this.listSended.limit,
            page: this.listSended.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          let total = 0;
          res.data.list = res.data.list == null ? [] : res.data.list;
          this.listSended = res.data;

          if (!this.listSended.list.length) {
            this.process.run = false;
            return;
          }
          this.listSended.list.forEach((val) => {
            this.axios
              .get("https://shop.yec.co.id/wp-json/yec/v1/get-order-id/1", {
                params: {
                  lisensi: val.license,
                },
              })
              .then((res) => res.data[0])
              .then((res) => {
                if (val.license) {
                  this.$set(val, "order_id", res.post_id);
                } else {
                  this.$set(val, "order_id", '-');
                }
              })
              .catch(() => {
                this.$set(val, "order_id", "-");
              })
              .finally(() => {
                total++;
                if (total == this.listSended.list.length) {
                  this.process.run = false;
                }
              });
          });
        })
        .catch((error) => {
          console.error(error);
          this.certifLoading = false;
        });
    },
    initializeNotSended(searching) {
      this.process.run = true;
      this.$set(this.listNotSended, "list", []);

      this.$http
        .get("/users/v1/admin/list-sertificate/send-list", {
          params: {
            query: searching,
            is_send: false,
            // max_score: score,
            limit: this.listNotSended.limit,
            page: this.listNotSended.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          let total = 0;
          res.data.list = res.data.list == null ? [] : res.data.list;
          this.listNotSended = res.data;

          if (!this.listNotSended.list.length) {
            this.process.run = false;
            return;
          }
          this.listNotSended.list.forEach((val) => {
            this.axios
              .get("https://shop.yec.co.id/wp-json/yec/v1/get-order-id/1", {
                params: {
                  lisensi: val.license,
                },
              })
              .then((res) => res.data[0])
              .then((res) => {
                if (val.license) {
                  this.$set(val, "order_id", res.post_id);
                } else {
                  this.$set(val, "order_id", '-');
                }
              })
              .catch(() => {
                this.$set(val, "order_id", "-");
              })
              .finally(() => {
                total++;
                if (total == this.listNotSended.list.length) {
                  this.process.run = false;
                }
              });
          });
        })
        .catch((error) => {
          console.error(error);
          this.certifLoading = false;
        });
    },
    // async initializeNotSended(sort = "", dir = "asc") {
    //   this.process.run = true;
    //   this.$set(this.listNotSended, "list", []);
    //   axios.get(`/users/v1/admin/list-sertificate/send-list`,{
    //     params: {
    //       // member_id: this.user.id || "",
    //       limit: this.listNotSended.limit,
    //       page: this.listNotSended.page,
    //       // print_flag: flag,
    //       is_send: false,
    //       sort,
    //       dir
    //     }
    //   }).then(res => {
    //     this.listNotSended = res.data.data;
    //   })
    //   this.process.run = false;
    // },
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 80px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  color: #9cafc6 !important;
}
.theme--light.v-data-table th,
.theme--light.v-data-table .v-data-footer {
  border: 0px !important;
}
.max-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 0;
}
.search-field.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-bottom: 0px solid transparent !important;
}
.search-field.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: inherit;
}
.mx-width-85 {
  max-width: 114px !important;
}
.theme--light.v-label {
  color: #9cafc6 !important;
}
#tabsItem .v-simple-checkbox {
  font-weight: 100;
}
#tabsItem .ri-checkbox-blank-line:before {
  content: "\eb7f";
  color: #9cafc6 !important;
}
</style>
