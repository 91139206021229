<template>
  <v-row>
    <v-col cols="12">
      <v-card elevation="0" outlined>
        <v-data-table
          :headers="headers"
          :items="data.list || []"
          :items-per-page.sync="data.limit"
          :page.sync="data.page"
          :server-items-length="data.total"
          :loading="dataLoading"
          hide-default-footer
          disable-filtering
          disable-sort
        >
          <template slot="item.created_at" slot-scope="{ item }">
            <div>{{ item.created_at | datetime }}</div>
          </template>
          <template slot="footer" slot-scope="{ props }">
            <v-row class="px-4">
              <div class="pa-3">
                <v-row align="center">
                  <div>
                    <v-subheader v-text="'Row per page: '"></v-subheader>
                  </div>
                  <div>
                    <v-select
                      class="mt-0 pt-0"
                      style="width: 4rem"
                      v-model="data.limit"
                      :items="[10, 20, 50, 100]"
                      hide-details="auto"
                    ></v-select>
                  </div>
                </v-row>
              </div>
              <v-col>
                <v-row class="mx-3" justify="end" align="center">
                  <div class="d-flex flex-nowrap align-center">
                    <v-subheader class="mr-2">Halaman:</v-subheader>
                    <v-select
                      v-model="data.page"
                      dense
                      solo
                      class="pa-0 ma-0 mr-2"
                      hide-details
                      :items="
                        Array.from(
                          { length: Math.ceil(data.total / data.limit) },
                          (_, i) => i + 1
                        )
                      "
                      style="width: 5rem"
                    ></v-select>
                    <v-subheader>
                      Menampilkan data ke
                      {{ props.pagination.pageStart + 1 }} sampai
                      {{ props.pagination.pageStop }} dari
                      {{ props.pagination.itemsLength }}
                    </v-subheader>
                  </div>
                  <v-btn
                    icon
                    small
                    :disabled="data.page == 1"
                    @click="data.page--"
                  >
                    <v-icon>$prev</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    :disabled="data.page == Math.ceil(data.total / data.limit)"
                    @click="data.page++"
                  >
                    <v-icon>$next</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <v-row v-show="false">
          <v-col md="6" offset-md="3">
            <div class="title text-center">
              <v-img
                src="@/assets/images/data_empty.png"
                width="66.667%"
                class="mb-4 mx-auto"
              ></v-img>
              Lisensi tidak ditemukan
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    dataLoading: false,
  }),
  computed: {
    ...mapGetters({ data: "order/data" }),
    headers: () => [
      { text: "Order ID", value: "order_id" },
      { text: "Total", value: "total" },
      { text: "Terjual", value: "total_sold" },
      { text: "Digunakan", value: "total_used" },
      { text: "Tanggal dibuat", value: "created_at" },
    ],
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.dataLoading = true;
      this.$store.dispatch("order/list").finally(() => {
        this.dataLoading = false;
      });
    },
    dateTimeFormat(date) {
      date = new Date(date);
      return date.toLocaleString("ID").replaceAll("/", "-");
    },
  },
  watch: {
    "data.limit": function () {
      this.fetchData();
    },
    "data.page": function () {
      this.fetchData();
    },
  },
};
</script>

<style>
</style>