<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" class="card">
				<v-card elevation="0" style="background: transparent;border:1px solid #eee;">
          <v-card-text class="pb-0" style="background: #fff;">
            <form @submit.prevent="submit">
              <v-row>
                <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Nama" rules="required"> -->
                      <p class="color-navy mb-2 pl-5">Gretting Message</p>
                      <v-textarea class="pl-5" outlined filled color="primary" v-model="deskripsi" placeholder="Message">
											</v-textarea>
                      <!-- </validation-provider> -->
                </v-col>
                <v-col cols="12" md="6">
									<v-spacer></v-spacer>
									<div class="attachment">
                    <p class="color-navy mb-2">Attachment</p>
										<audio-attachment
                      class="mr-2"
                      :src="audio"
                      @set-attachment="setAudioAttachment"
                    />										
									</div>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
          <v-card-actions class="transparent pa-4">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              large
              class="text-capitalize"
              >Lanjut</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
	import AudioAttachmentModal from "@/views/_inc/modal/AudioAttachment";

	export default {
		name:'epot-greeting',
		data: () => ({
			audio: {},
			deskripsi:'',
			attach:true,
		}),
		components: {
			"audio-attachment": AudioAttachmentModal,
		},
		methods: {
			setAudioAttachment(src) {
				this.audio = src;
			},			
		}
	}
</script>
<style>
	.prev{
		border:1px solid #E3E9EF;
		padding: 10px;
		border-radius:5px;
	}
</style>