<template>
  <!-- FORNEXT: Dibuat satu template layout untuk import question (single/group) -->
  <v-row>
    <!-- FORNEXT: Dibuat model nested routing -->
    <v-col class="d-flex py-0" cols="12">
      <!-- FORNEXT: Font weight berubah ketika digabungkan dengan auth -->
      <div class="title my-auto">Daftar soal</div>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-card-actions class="py-0">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              class="color-navy-soft text-capitalize"
            >
              Sort by
              <v-icon right>$arrdown</v-icon>
            </v-btn>
          </v-card-actions>
        </template>
      </v-menu>
      <v-text-field
        label="Search"
        hide-details
        class="pt-1 mr-3 color-navy-soft mx-width-85 search-field"
        v-model="search"
        @keypress.enter="fetchQuestion()"
        @click:append="fetchQuestion()"
        clearable
        clear-icon="ri-close-fill"
        @click:clear="clearSearch()"
        ><v-icon slot="append" class="color-navy-soft"
          >$search</v-icon
        ></v-text-field
      >
    </v-col>

    <!-- 
      ===========================================================
      ====================== ADD TO SOAL =====================
      ===========================================================
    -->

    <v-col cols="12">
      <v-btn text style="background: #fee9d3" color="primary" @click="back">
        Kembali
      </v-btn>
      <v-spacer class="px-1 d-inline-block"></v-spacer>
      <v-btn
        elevation="0"
        v-if="!_.isEmpty(selected)"
        color="primary"
        @click="importQuestion"
      >
        Add soal to test
      </v-btn>
    </v-col>

    <!-- 
      ===========================================================
      ====================== DATA BANK SOAL =====================
      ===========================================================
    -->
    <v-col cols="12" class="pt-0">
      <!-- hide-default-footer -->
      <v-data-table
        class="spacing-y-5 transparent"
        :mobile-breakpoint="0"
        hide-default-header
        item-key="id"
        single-expand
        v-model="selected"
        :server-items-length="questions.total"
        :items-per-page.sync="questions.limit"
        :page.sync="questions.page"
        :headers="[{ value: 'question.text' }]"
        :items="questions.list"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        :headers-length="3"
        :footer-props="footerProps"
      >
      <!-- <v-data-table
        hide-default-header
        :headers="[
          { text: 'Text', value: 'question.text' },
          { text: '', value: 'data-table-expand' }
        ]"
        :items="questions.list"
        :loading="table_loading"
        loading-text="Sedang memuat"
        no-data-text="Data belum ada"
        no-results-text="Data belum ada"
        sort-by-text="Urutkan berdasarkan"
        :items-per-page.sync="questions.limit"
        :page.sync="questions.page"
        :server-items-length="questions.total"
        fixed-header
        item-key="id"
        single-expand
        v-model="selected"
        flat
        class="elevation-0 rounded-lg"
      >  -->
        <!-- <template slot="footer" slot-scope="{ props }">
          <v-row class="px-4">
            <div class="pa-3">
              <v-row align="center">
                <div>
                  <v-subheader v-text="'Baris Per Halaman: '"></v-subheader>
                </div>
                <div>
                  <v-select
                    class="mt-0 pt-0"
                    style="width: 4rem"
                    v-model="questions.limit"
                    dense
                    :items="[10, 20, 50, 100]"
                    hide-details="auto"
                  >
                  </v-select>
                </div>
              </v-row>
            </div>
            <v-col>
              <v-row class="mx-3" justify="end" align="center">
                <div class="d-flex flex-nowrap align-center">
                  <v-subheader class="mr-2">Halaman:</v-subheader>
                  <v-select
                    v-model="questions.page"
                    dense
                    class="pa-0 ma-0 mr-2"
                    hide-details
                    :items="
                      Array.from(
                        {
                          length: Math.ceil(questions.total / questions.limit),
                        },
                        (_, i) => i + 1
                      )
                    "
                    style="width: 5rem; text-align: center !important"
                  >
                  </v-select>
                  <v-subheader>
                    Menampilkan data ke
                    {{ props.pagination.pageStart + 1 }} sampai {{ props.pagination.pageStop }} dari
                    {{ props.pagination.itemsLength }}
                  </v-subheader>
                </div>
                <v-btn icon small :disabled="questions.page == 1" @click="questions.page--">
                  <v-icon>$prev</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  :disabled="questions.page == Math.ceil(questions.total / questions.limit)"
                  @click="questions.page++"
                >
                  <v-icon>$next</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </template> -->
        <template
          slot="item"
          slot-scope="{ item, isSelected, select, isExpanded, expand }"
          class="d-block"
        >
          <tr class="flat-b-1 rounded-lg" style="cursor: pointer" @click="expand(!isExpanded)">
            <td class="white rounded-l-lg">
              <v-simple-checkbox
                color="primary"
                v-model="item.selected"
                @click="select(!isSelected)"
              ></v-simple-checkbox>
            </td>
            <td class="white">
              <div :style="`width: ${questionTextWidth}px`" class="text-truncate">
                {{ item.question.text }}
              </div>
            </td>
            <td class="white rounded-r-lg">
              <v-btn icon @click="expand(!isExpanded)">
                <v-icon
                  :style="{ transform: isExpanded ? 'rotate(-180deg)' : '' }"
                  >$expand</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          <div class="d-flex flex-nowrap align-center">
          {{ props.pageStart }} - {{ props.pageStop }} dari total
          {{ props.itemsLength }} data
            <v-subheader class="mr-2 caption">Halaman:</v-subheader>
            <v-select
              v-model="questions.page"
              dense
              class="pa-0 ma-0 mr-2"
              hide-details
              :items="
                Array.from(
                  {
                    length: Math.ceil(questions.total / questions.limit),
                  },
                  (_, i) => i + 1
                )
              "
              style="width: 5rem; text-align: center !important"
            >
            </v-select>
          </div>
        </template>
        <template slot="expanded-item" slot-scope="{ item }">
          <tr>
            <td colspan="3" class="flat-b-1 white rounded-lg">
              <v-col :style="`width: ${contentWidth}px`">
                <epot-input-soal :data="item" disabled />
              </v-col>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import EpotInputSoal from "../epot/components/EpotInputSoal.vue";

export default {
  name: "import-questions",
  components: { EpotInputSoal },
  data() {
    return {
      table_loading: false,
      selected: [],
      expanded: [],
      expand: null,
      filter: "",
      search: null,
      questions: {
        list: [],
        total: 0,
        limit: 10,
        page: 1,
      },
      footerProps: {
        "show-current-page": true,
        "show-first-last-page": true,
        "items-per-page-options": [10, 30, 50, 100],
        "items-per-page-text": "Data per halaman",
        "page-text": "{0} - {1} dari total {2}"
      },

      // Pagination
      limit: 10,
      page: 1,
    };
  },
  computed: {
    contentWidth() {
      let sidebar = !this.$vuetify.breakpoint.mdAndUp || 256;
      return Number(this.$vuetify.breakpoint.width) - sidebar -90
    },
    questionTextWidth() {
      return this.contentWidth - 158;
    }
  },
  created() {
    this.fetchQuestion();
  },
  methods: {
    back() {
      this.$router.push(this.$route.path.split("/").slice(0, -1).join("/"));
    },
    fetchQuestion() {
      // console.log("fetchQuestion");
      this.table_loading = true;
      this.questions.list = [];
      
      this.$store
        .dispatch("question/list", {
          page: this.questions.page,
          limit: this.questions.limit,
          type: 'single_choice',
          q : this.search
        })
        .then((res) => {
          this.questions = res.data;
          this.table_loading = false;
        })
        .catch((error) => {
          this.table_loading = false;
          throw new Error(error)
        });
      // this.table_loading = false;
    },
    clearSearch () {
        this.search = '';
        this.fetchQuestion();        
      },
    importQuestion() {
      let index = null;
      if (this.$route.params.index != undefined) {
        index = this.$route.params.index;
      }
      this.$emit("import-questions", this.selected, index);
      this.back();
    },
  },
  watch: {
    "questions.limit": function() {
      this.fetchQuestion();
    },
    "questions.page": function() {
      this.fetchQuestion();
    },
  },
};
</script>
