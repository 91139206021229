import axios from "axios";
import url from "./api";

const offline = false;

const state = () => {
  return {
    questions: [],
  };
};

const mutations = {
  fetch(state, payload) {
    state.questions = payload.data.list;
  },
  create(state, payload) {
    let questions = JSON.parse(localStorage.getItem("ytc_questions")) || [];
    state.questions.push(payload.data);
    questions.push(payload.data);
    localStorage.setItem("ytc_questions", JSON.stringify(questions));
  },
  // FORNEXT: Untuk pengembangan berikutnya yang bisa mengedit soal yang telah disimpan dalam draft
  update(state, payload) {
    let questions = JSON.parse(localStorage.getItem("ytc_questions")) || [];
    let new_questions = questions.map((question) =>
      question.id == payload.data.id
        ? Object.assign(question, payload.data)
        : question
    );
    state.questions = new_questions;
    localStorage.setItem("ytc_questions", JSON.stringify(new_questions));
  },
  delete(state, id) {
    let index = state.questions.findIndex((question) => question.id == id);
    if (index == -1) return;
    state.questions.splice(index, 1);
  },
  clear(state) {
    state.questions = [];
  },
};

const getters = {
  detail: (state) => (id) => {
    return state.questions.find((question) => question.id == id);
  },
  list: () => (params) => {
    let offset = (params.page - 1) * params.page;
    let data = JSON.parse(localStorage.getItem("ytc_questions")) || [];
    return {
      limit: params.limit,
      list: data.slice().splice(offset, params.limit),
      page: params.page,
      total: data.length,
    };
  },
};

const actions = {
  async list({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      if (offline) {
        setTimeout(() => {
          resolve(url.success(getters.list(params)));
        }, 1000);
      } else {
        axios
          .get(url.question_list, {
            params: Object.assign(
              { page: 1, limit: 10, type: "single_choice" },
              params
            ),
          })
          .then((res) => {
            commit("fetch", res.data);
            resolve(res.data);
          })
          .catch((error) => {
            commit("clear");
            reject(error);
          });
      }
    });
  },

  async detail({ commit }, id) {
    return new Promise((resolve, reject) => {
      if (offline) {
        setTimeout(() => {
          let questions =
            JSON.parse(localStorage.getItem("ytc_questions")) || [];
          let question = questions.find((question) => question.id == id);
          if (question) {
            resolve(url.success(question));
          } else {
            reject(url.error("Question not found"));
          }
        }, 500);
      } else {
        axios
          .get(url.question_detail.replace("{id}", id))
          .then((res) => {
            // commit("create", res.data);
            resolve(res.data);
          })
          .catch((error) => {
            commit("error");
            reject(error);
          });
      }
    });
  },

  async detail_public({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(url.question_detail_public.replace("{id}", id))
        .then((res) => {
          commit("create", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("error");
          reject(error);
        });
    });
  },

  async create({ commit }, question) {
    return new Promise((resolve, reject) => {
      if (offline) {
        setTimeout(() => {
          try {
            if (!question.question_text) {
              reject(
                url.error({
                  question_text: "The question_text field is required",
                })
              );
            }
            if (!question.options.length) {
              reject(url.error({ options: "The options field is required" }));
            }
            question.id = url.get_id();
            question.question = {
              text: question.question_text,
              with_media: Boolean(question.question_media),
              media: question.question_media,
            };
            question.instruction = {
              text: question.instruction_text,
              with_media: Boolean(question.instruction_media),
              media: question.instruction_media,
            };
            question.explaination = {
              text: question.explaination_text,
              with_media: Boolean(question.explaination_media),
              media: question.explaination_media,
            };
            delete question.instruction_text;
            delete question.instruction_media;
            delete question.explaination_text;
            delete question.explaination_media;
            delete question.question_media;
            delete question.question_text;
            commit("create", { data: question });
            resolve(url.success(question));
          } catch (error) {
            reject(url.error(error));
          }
        }, 2000);
      } else {
        axios
          .post(url.question_create, question)
          .then((res) => {
            commit("create", res.data);
            resolve(res.data);
          })
          .catch((error) => {
            commit("error");
            reject(error);
          });
      }
    });
  },

  async update({ commit }, question) {
    return new Promise((resolve, reject) => {
      axios
        .put(url.question_update.replace("{id}", question.id), question)
        .then((res) => {
          commit("update", res.data);
          resolve(res.data);
        })
        .catch((error) => {
          commit("error");
          reject(error);
        });
    });
  },

  async delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url.question_delete.replace("{id}", id))
        .then((res) => {
          commit("delete", id);
          resolve(res.data);
        })
        .catch((error) => {
          commit("error");
          reject(error);
        });
    });
  },
};

export default { namespaced: true, state, mutations, actions, getters };
