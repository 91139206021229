import axios from "axios";
import url from "./api";

function get_structured_exam_data(exam_data) {
  const structured_exam_data = {
    section_id: "",
    is_random_question: false,
    part_direction: "",
    part_direction_media: "",
    close_statement: "",
    close_statement_media: "",
    question: [],
    status: 0,
    code: "",
    part: "",
  };

  let media_id;

  Object.keys(structured_exam_data).forEach((key) => {
    switch (key) {
      case "part_direction":
        structured_exam_data[key] = exam_data.part_direction.text;
        break;
      case "part_direction_media":
        media_id = exam_data.part_direction.media
          ? exam_data.part_direction.media.id
          : exam_data.part_direction.media_id;
        structured_exam_data[key] = exam_data.part_direction.with_media
          ? media_id
          : "";
        break;
      case "close_statement":
        structured_exam_data[key] = exam_data.close_statement.text;
        break;
      case "close_statement_media":
        media_id = exam_data.close_statement.media
          ? exam_data.close_statement.media.id
          : exam_data.close_statement.media_id;
        structured_exam_data[key] = exam_data.close_statement.with_media
          ? media_id
          : "";
        break;
      case "question":
        structured_exam_data[key] = exam_data.questions || exam_data.question;
        break;
      case "section_id":
        structured_exam_data[key] = exam_data.section || exam_data.section_id;
        break;
      case "status":
        structured_exam_data[key] = exam_data.status.value || exam_data.status;
        break;
      default:
        if (key in exam_data) structured_exam_data[key] = exam_data[key];
        break;
    }
  });

  return structured_exam_data;
}

const offline = false;

const state = () => {
  return {
    exams: [],
    next_code: "",
  };
};

// For local data purpose
const mutations = {
  fetch(state, payload) {
    state.exams = payload.data;
  },
  create(state, payload) {
    let exams = JSON.parse(localStorage.getItem("ytc_exams")) || [];
    exams.push(payload.data);
    state.exams.push(payload.data);
    localStorage.setItem("ytc_exams", JSON.stringify(exams));
  },
  update(state, payload) {
    let exams = JSON.parse(localStorage.getItem("ytc_exams")) || [];
    let old_exam_index = exams.findIndex((exam) => exam.id == payload.data.id);
    delete payload.data.selected;
    exams[old_exam_index] = payload.data;
    state.exams = exams;
    localStorage.setItem("ytc_exams", JSON.stringify(exams));
  },
  delete(state, id) {
    let index = state.exams.findIndex((exam) => exam.id == id);
    if (index == -1) return;
    state.exams.splice(index, 1);
  },
  next_code: (state) => (code) => {
    state.next_code = code;
  },
  success: () => true,
};

const getters = {
  detail({ state }, id) {
    return state.exams.find((exam) => exam.id == id);
  },
  list: () => (params) => {
    let data = JSON.parse(localStorage.getItem("ytc_exams")) || [];
    let offset = (params.page - 1) * params.page;

    if (params.status)
      data = data.filter((data) => data.status == params.status);

    return {
      limit: params.limit,
      list: data.slice().splice(offset, params.limit),
      page: params.page,
      total: data.length,
    };
  },
};

const actions = {
  async list({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      params = Object.assign({ page: 1, limit: 10 }, params);

      if (offline) {
        try {
          resolve(url.success(getters.list(params)));
        } catch (error) {
          reject(error);
        }
      } else
        axios
          .get(url.exam_list, {
            params,
          })
          .then((res) => {
            commit("success");
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
    });
  },
  async create({ commit }, data) {
    return new Promise((resolve, reject) => {
      data = get_structured_exam_data(data);
      if (offline) {
        try {
          data.id = url.get_id();
          commit("create", { data });
          resolve(url.success(data));
        } catch (error) {
          reject(url.error(error));
        }
      } else
        axios
          .post(url.exam_create, data, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            commit("create", { data: res.data });
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
    });
  },
  async detail({ commit }, data_id) {
    return new Promise((resolve, reject) => {
      if (offline) {
        try {
          let datas = JSON.parse(localStorage.getItem("ytc_exams")) || [];
          let data = datas.find((data) => data.id == data_id);
          if (!data) {
            reject(url.error(data));
          }
          resolve(url.success(data));
        } catch (error) {
          reject(url.reject(error));
        }
      } else
        axios
          .get(url.exam_detail.replace("{id}", data_id))
          .then((res) => {
            commit("success");
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
    });
  },
  async update({ commit }, data) {
    return new Promise((resolve, reject) => {
      let structured_data = get_structured_exam_data(data);

      if (offline) {
        setTimeout(() => {
          try {
            commit("update", { data: { ...structured_data, id: data.id } });
            resolve(url.success(data));
          } catch (error) {
            reject(url.error(error));
          }
        }, 1000);
      } else
        axios
          .put(url.exam_update.replace("{id}", data.id), structured_data, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            commit("update", { data: res.data });
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
    });
  },
  async delete({ commit }, data_id) {
    return new Promise((resolve, reject) => {
      if (offline) {
        try {
          let datas = JSON.parse(localStorage.getItem("ytc_exams")) || [];
          let index = state.datas.findIndex((data) => data.id == data_id);
          if (index == -1) throw new Error("data not found");
          state.datas.splice(index, 1);

          localStorage.setItem("ytc_exams", JSON.stringify(datas));
          resolve(url.success(true));
        } catch (error) {
          reject(error);
        }
      } else
        axios
          .delete(url.exam_delete.replace("{id}", data_id), {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            commit("success");
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
    });
  },
  async next_code({ commit }, data) {
    return new Promise((resolve, reject) => {
      // let p = true
      if (offline && 0) {
        setTimeout(() => {
          try {
            let datas = JSON.parse(localStorage.getItem("ytc_exams")) || [];
            let increment = Number(data.code.substr(2)) || datas.length + 1;
            let code =
              data.section_id + data.part + String(increment).padStart(4, 0);
            resolve(url.success(code));
          } catch (error) {
            reject(url.error(error));
          }
        }, 500);
      } else {
        axios
          .post(url.exam_next_code, data, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            commit("next_code", res.data.data);
            resolve(res.data);
          })
          .catch((error) => {
            commit("next_code", "");
            reject(error);
          });
      }
    });
  },
};

export default { namespaced: true, state, mutations, getters, actions };
