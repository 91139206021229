<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-tabs v-model="tabs" background-color="transparent">
          <v-tab class="text-capitalize">Pengaturan</v-tab>
          <v-tab class="text-capitalize" :disabled="!settingsFilled"
            >Soal</v-tab
          >
        </v-tabs>
        <v-col
          v-if="exam_loading"
          cols="12"
          class="px-0 pt-6 d-flex justify-center"
        >
          <v-progress-circular indeterminate color="color lighten-3" size="50">
            {{ state }}
          </v-progress-circular>
        </v-col>
        <v-tabs-items v-else class="my-2 transparent" v-model="tabs">
          <v-tab-item>
            <v-card elevation="0" rounded="lg" outlined>
              <v-card-text class="pb-2">
                <form @submit.prevent="submit" class="pl-3 pr-3">
                  <v-row>
                    <!-- Nama paket (auto generated) -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Nama" rules="required"> -->
                      <p class="mb-2">Kode Paket</p>
                      <v-text-field
                        v-model="settings.code"
                        disabled
                        filled
                        required
                        outlined
                        autofocus
                        hide-details="auto"
                      >
                        <template v-if="code_loading" slot="append">
                          <v-progress-circular
                            indeterminate
                            width="2"
                            size="20"
                          ></v-progress-circular>
                        </template>
                      </v-text-field>
                      <!-- </validation-provider> -->
                    </v-col>
                    <!-- Timer -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Retake" rules="required"> -->
                      <p class="mb-2">Section</p>
                      <v-select
                        filled
                        v-model="settings.section"
                        :items="sections"
                        item-text="name"
                        item-value="id"
                        required
                        outlined
                        hide-details="auto"
                        :disabled="this.$route.name != 'exam-create'"
                      >
                        <template v-if="section_loading" slot="append">
                          <v-progress-circular
                            indeterminate
                            width="2"
                            size="20"
                          ></v-progress-circular>
                        </template>
                      </v-select>
                      <!-- </validation-provider> -->
                    </v-col>
                    <!-- Is question random -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Question" rules="required"> -->
                      <p class="mb-2">Part</p>
                      <v-select
                        filled
                        outlined
                        v-model="settings.part"
                        :items="parts"
                        hide-details="auto"
                        @change="nextCode"
                        :disabled="this.$route.name != 'exam-create'"
                      >
                        <template v-if="part_loading" slot="append">
                          <v-progress-circular
                            indeterminate
                            width="2"
                            size="20"
                          ></v-progress-circular>
                        </template>
                      </v-select>
                    </v-col>
                    <!-- Is option random -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Answer Choice" rules="required"> -->
                      <template v-if="false">
                        <p class="mb-2">Random Question</p>
                        <v-select
                          :items="randoms"
                          item-value="val"
                          item-text="text"
                          filled
                          v-model="settings.is_random_question"
                          required
                          outlined
                          hide-details="auto"
                        ></v-select>
                      </template>
                      <!-- </validation-provider> -->
                    </v-col>
                    <!-- Section -->
                    <v-col cols="12" sm="6">
                      <!-- <validation-provider v-slot="{ errors }" name="Random Question" rules="required"> -->
                      <div class="mb-2">Part direction</div>
                      <v-textarea
                        class=""
                        outlined
                        filled
                        color="primary"
                        v-model="settings.part_direction.text"
                        placeholder="Direction"
                        hide-details="auto"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-2">Attachment</div>
                      <v-row no-gutters style="max-width: 100%">
                        <audio-attachment
                          v-if="isMediaSet('audio')"
                          class="mr-2 mb-2 d-inline-block"
                          :src="settings.part_direction"
                          @update="
                            $set(settings, 'part_direction', {
                              ...settings.part_direction,
                              ...$event,
                            })
                          "
                        />
                        <image-attachment
                          v-if="isMediaSet('image')"
                          class="d-inline-block"
                          :src="settings.part_direction"
                          @update="
                            $set(settings, 'part_direction', {
                              ...settings.part_direction,
                              ...$event,
                            })
                          "
                        />
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-2">Close statement</div>
                      <v-textarea
                        outlined
                        filled
                        color="primary"
                        v-model="settings.close_statement.text"
                        placeholder="Statement"
                        hide-details="auto"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="mb-2">Attachment</div>
                      <v-row no-gutters style="max-width: 100%">
                        <audio-attachment
                          :src="settings.close_statement"
                          @update="
                            $set(settings, 'close_statement', {
                              ...settings.close_statement,
                              ...$event,
                            })
                          "
                        />
                      </v-row>
                    </v-col>
                  </v-row>
                </form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="background pa-4">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  large
                  :loading="saving"
                  :disabled="!settingsFilled || loading"
                  @click="save()"
                  elevation="0"
                >
                  {{ $route.name != "exam-detail" ? "Lanjut" : "Simpan" }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>

          <!-- 
            ===========================================================
            ======================== DATA SOAL ========================
            ===========================================================
          -->
          <v-tab-item>
            <v-card elevation="0" color="transparent">
              <v-card-text class="pa-3">
                <v-row
                  v-if="
                    $route.name == 'exam-create' || $route.name == 'exam-detail'
                  "
                >
                  <v-col class="py-0">
                    <v-expansion-panels v-if="questions.length" flat>
                      <v-expansion-panel
                        v-for="(question, index) in questions"
                        cols="12"
                        class="pa-0 mb-4 rounded-lg flat-b-1 custom-panel"
                        :class="colorStatus(question)"
                        :key="question.id || index"
                      >
                        <v-expansion-panel-header>
                          <div class="title">
                            {{ !question.is_group ? "Nomor" : "Group" }}
                            {{ index + 1 }}
                          </div>
                          <v-spacer></v-spacer>
                          <div class="text-right">
                            <v-btn
                              :loading="question.saving"
                              v-if="hideSaveButton(question)"
                              color="success"
                              icon
                              @click.stop="confirmSave(question)"
                            >
                              <v-icon>$complete</v-icon>
                            </v-btn>
                            <v-btn
                              v-if="isCreateOrDraft"
                              icon
                              @click.stop="confirmDelete(question.id || index)"
                            >
                              <v-icon> $delete </v-icon>
                            </v-btn>
                          </div>
                          <template v-slot:actions>
                            <v-btn icon class="ml-2">
                              <v-icon>$expand</v-icon>
                            </v-btn>
                          </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content class="white">
                          <template v-if="!question.is_group">
                            <!-- FIXME: Hapusnya gimana itu kok masih acak-acak, aku hapus ini yang kehapus itu -->
                            <exam-input-soal
                              :disabled="!isCreateOrDraft"
                              :data="question.list_question[0]"
                              @update="$set(question.list_question, 0, $event)"
                            />
                          </template>

                          <template v-else>
                            <exam-input-grup-soal
                              :saving="question.saving"
                              :disabled="!isCreateOrDraft"
                              :data="question"
                              :sn="index"
                              @update="$set(questions, index, $event)"
                              @success="onSuccess($event)"
                              @error="onError($event)"
                            />
                            <!-- input grup soal -->
                          </template>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-col v-else cols="6" offset="3">
                      <div class="title text-center">
                        <v-img
                          src="@/assets/images/data_empty.png"
                          width="66.667%"
                          class="mb-4 mx-auto"
                        ></v-img>
                        Belum ada pertanyaan
                      </div>
                    </v-col>

                    <!-- Delete dialog -->
                    <v-dialog
                      v-if="isCreateOrDraft"
                      v-model="delete_question.show"
                      persistent
                      max-width="600px"
                    >
                      <v-card>
                        <v-card-title>
                          <span>
                            <v-icon>$warning</v-icon> Hapus Pertanyaan
                          </span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <div>
                          <v-card-text>
                            Apakah anda ingin menghapus pertanyaan ini?
                          </v-card-text>
                        </div>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="primary"
                            small
                            elevation="0"
                            @click="cancel()"
                          >
                            Batal
                          </v-btn>
                          <v-btn
                            class="white--text"
                            color="primary"
                            small
                            elevation="0"
                            @click="deleteQuestion(delete_question.id)"
                          >
                            Hapus
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <!-- Save confirm dialog -->
                    <!-- v-if="isCreateOrDraft" -->
                    <v-dialog
                      v-model="save_question.show"
                      persistent
                      max-width="600px"
                    >
                      <v-card>
                        <v-card-title>
                          <span>
                            <v-icon>$warning</v-icon> Simpan Pertanyaan
                          </span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <div>
                          <v-card-text>
                            Apakah anda ingin menyimpan pertanyaan ini?<br />
                            <strong>
                              <v-icon color="warning">$warning</v-icon>
                              Pertanyaan yang telah disimpan ke bank soal tidak
                              dapat di edit kembali.
                            </strong>
                          </v-card-text>
                        </div>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn outlined color="primary" @click="cancel()">
                            Batal
                          </v-btn>
                          <v-btn
                            class="white--text"
                            color="primary"
                            @click="
                              saveQuestion(save_question.question, 'draft')
                            "
                          >
                            Simpan draft
                          </v-btn>
                          <v-btn
                            class="white--text"
                            color="primary"
                            @click="
                              saveQuestion(save_question.question, 'publish')
                            "
                          >
                            Simpan ke bank soal
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-row v-if="isCreateOrDraft" no-gutters>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                            class="primary--text mr-2"
                            elevation="0"
                          >
                            Tambah Soal
                            <v-icon class="ml-1">$arrdown</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="addQuestion({})">
                            <v-list-item-title>Buat Baru</v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item
                            :to="{ name: $route.name + '-import-questions' }"
                          >
                            <v-list-item-title
                              >Ambil dari bank soal</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="white"
                            class="primary--text"
                            elevation="0"
                          >
                            Tambah Group Soal<v-icon class="ml-1"
                              >$arrdown</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="addGroup">
                            <v-list-item-title>Buat Baru</v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item
                            disabled
                            :to="{
                              name: $route.name + '-import-question-groups',
                            }"
                          >
                            <v-list-item-title
                              >Ambil dari bank soal (dalam
                              pengembangan)</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-spacer></v-spacer>
                      <v-menu offset-y v-if="!!questions.length">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :loading="saving"
                            class="white--text"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            elevation="0"
                          >
                            Submit
                            <v-icon class="ml-2">$arrdown</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="save(2)">
                            <v-list-item-title
                              >Simpan dan Publish</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item @click="save(1)">
                            <v-list-item-title
                              >Simpan di draft</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col class="py-0">
                    <router-view @import-questions="add"></router-view>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-snackbar top v-model="snackbar.show" :timeout="snackbar.timeout">
      <div v-html="snackbar.message"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import EpotInputSoal from "./components/EpotInputSoal";
import EpotInputGrupSoal from "./components/EpotInputGrupSoal";
import AudioAttachment from "../_inc/modal/AudioAttachment";
import ImageAttachment from "../_inc/modal/ImageAttachment.vue";

export default {
  name: "exam-form",
  components: {
    ExamInputSoal: EpotInputSoal,
    ExamInputGrupSoal: EpotInputGrupSoal,
    AudioAttachment,
    ImageAttachment,
  },
  data() {
    return {
      tabs: null,
      saving: false,
      exam_loading: true,
      code_loading: false,
      section_loading: false,
      part_loading: false,
      sections: [],
      state: "",

      randoms: [
        { val: true, text: "Ya" },
        { val: false, text: "Tidak" },
      ],

      settings: {
        code: "",
        section: "",
        part: "",
        is_random_question: false,
        part_direction: {},
        close_statement: {},
        questions: [],
      },

      old_settings: {},

      delete_question: {
        show: false,
        id: null,
      },

      save_question: {
        show: false,
        question: {},
      },

      snackbar: {
        show: false,
        message: "",
        timeout: 5000,
      },
    };
  },
  computed: {
    isDisabled() {
      return this.disabled;
    },
    settingsFilled() {
      let status = true;

      [
        "code",
        "section",
        "part",
        "is_random_question",
        "part_direction",
        "close_statement",
        "questions",
      ].forEach((key) => {
        if (
          key == "part_direction_media" ||
          key == "close_statement_media" ||
          key == "is_random_question"
        ) {
          return;
        } else if (!this.settings[key]) {
          status = false;
        }
      });

      return status;
    },
    questions() {
      return this.settings.questions;
    },
    isCreateOrDraft() {
      return (
        this.$route.name == "exam-create" ||
        (this.settings.status && this.settings.status.value == 1)
      );
    },
    parts() {
      let part = ["No part"];
      let selected_section = this.sections.find(
        (s) => s.id == this.settings.section
      );
      if (selected_section && selected_section.is_has_part)
        part = selected_section.available_part;
      return part;
    },
    loading() {
      return (
        this.saving ||
        this.exam_loading ||
        this.code_loading ||
        this.section_loading ||
        this.part_loading
      );
    },
  },
  mounted() {
    this.fetchSection().then(() => {
      if (!this.$route.name.includes("exam-detail")) {
        this.$emit("page-changed", 1, { title: "Create EPOT", link: "#" });
        this.nextCode().then(() => {
          this.exam_loading = false;
        });
      } else {
        this.$emit("page-changed", 1, { title: "Edit EPOT", link: "#" });
        this.fetchExam().then(async () => {
          this.exam_loading = false;
        });
      }
    });
  },
  methods: {
    fetchSection() {
      this.section_loading = this.part_loading = this.code_loading = true;
      return this.$store.dispatch("section/list").then(async (res) => {
        this.sections = res.data.list;
        this.settings.section = this.sections[0].id;

        this.section_loading = this.part_loading = this.code_loading = false;
        return res;
      });
    },
    fetchExam() {
      return this.$store
        .dispatch("exam/detail", this.$route.params.id)
        .then(async (res) => {
          let data = res.data;

          let total = data.questions.length;
          for (let index = 0; index < data.questions.length; index++) {
            let questions = data.questions[index];

            for (
              let index1 = 0;
              index1 < questions.list_question.length;
              index1++
            ) {
              let id = questions.list_question[index1];
              await this.$store
                .dispatch("question/detail", id)
                .then((res) => {
                  questions.list_question[index1] = res.data;
                  this.state = (((index + 1) / total) * 100).toFixed() + "%";
                })
                .catch((error) => {
                  console.error(error);
                });
            }

            data.questions[index] = questions;
          }

          data.section = data.section.id;

          this.settings = data;
          this.settings.part = this.settings.part || this.parts[0];

          this.old_settings = {
            code: this.settings.code,
            section_id: this.settings.section,
            part: this.settings.part,
          };

          this.$emit("page-changed", 1, { title: "Update EPOT", link: "#" });
          return res;
        })
        .catch((error) => {
          console.error(error);
          this.$router.push({ name: "exam-list" });
        });
    },
    add(question, index = null) {
      question.forEach((question) => {
        // Group
        if (question.is_group) {
          if (this.questions.find((val) => val == question) != undefined) {
            this.onError({ message: "The same group already imported" });
            return;
          }
          // console.log("kok kosong?");
          this.questions.push(question);
        }
        // Single
        else if (index == null) {
          if (
            this.questions
              .filter((filtered_question) => !filtered_question.is_group)
              .some((filtered_question) => {
                return filtered_question.list_question[0].id == question.id;
              })
          ) {
            this.onError({ message: "Some question(s) already imported" });
            return;
          }
          this.addQuestion(question);
        }
        // Single in group
        else if (index != null) {
          if (
            this.questions[index].list_question.some(
              (grouped_question) => grouped_question.id == question.id
            )
          ) {
            this.onError({ message: "Some question(s) already imported" });
            return;
          }
          this.questions[index].list_question.push(question);
        }
      });
    },
    addQuestion(question = {}) {
      this.questions.push({
        is_group: false,
        list_question: [question],
      });
    },
    addGroup() {
      this.questions.push({
        is_group: true,
        list_question: [{}],
      });
    },
    confirmDelete(id) {
      this.delete_question.show = true;
      this.delete_question.id = id;
    },
    deleteQuestion(id) {
      let index;

      index = this.questions.findIndex((question) => question.id == id);
      if (index == -1) {
        index = id;
      }
      this.questions.splice(index, 1);

      this.delete_question.show = false;
      this.delete_question.id = null;
    },
    confirmSave(question) {
      this.save_question.show = true;
      this.save_question.question = question;
    },
    cancel() {
      this.delete_question.show = this.save_question.show = false;
      this.delete_question.id = null;
      this.save_question.question = {};
    },
    async saveQuestion(questions, status) {
      this.save_question.show = false;
      this.save_question.question = {};
      this.$set(questions, "saving", true);

      let arr = questions.list_question;
      for (let i = 0; i < arr.length; i++) {
        let question = arr[i];
        let question_data = {
          id: "",
          instruction_text: "",
          instruction_media: "",
          type: "",
          question_text: "",
          question_media: "",
          point: 1,
          options: [],
          correct_answer: [],
          explaination_text: "",
          explaination_media: "",
          status: "",
        };

        Object.keys(question_data).forEach((key) => {
          let inst = question.instruction || {};
          let ques = question.question || {};
          switch (key) {
            case "instruction_text":
              question_data[key] = inst.text;
              break;
            case "instruction_media":
              question_data[key] = inst.media ? inst.media.id : "";
              break;
            case "question_text":
              question_data[key] = ques.text;
              break;
            case "question_media":
              question_data[key] = ques.media ? ques.media.id : "";
              break;
            case "status":
              question_data[key] = status;
              break;
            default:
              if (key in question) question_data[key] = question[key];
              break;
          }
        });

        let action = question_data.id ? "question/update" : "question/create";

        await this.$store
          .dispatch(action, question_data)
          .then((res) => {
            if (action.includes("create")) {
              this.onSuccess(`Question saved to ${status}`);
            } else {
              this.onSuccess(`Question updated`);
            }
            this.$set(questions.list_question, i, res.data);
            // this.saveGroupedQuestion(questions);
          })
          .catch((error) => {
            this.$delete(questions, "saving");
            // console.log(questions);
            this.onError(error);
          });
      }

      this.$delete(questions, "saving");
    },
    async saveGroupedQuestion(questions, status) {
      let list_question = questions.list_question.map((data) => data.id);
      await this.$store
        .dispatch("grouped_question/create", {
          ...questions,
          list_question,
          status,
        })
        .then((res) => {
          this.$set(questions, "id", res.data.id);
          this.$set(
            questions,
            "list_question",
            res.data.list_question.map((id) => ({ id: id }))
          );
          this.$delete(questions, "saving");
          this.save_question.show = false;
          this.save_question.question = {};
        });
    },
    // saveAllQuestion() {
    //   return new Promise((resolve, reject) => {})
    // },
    save(status = null) {
      if (this.$route.name != "exam-detail" && status == null) {
        this.tabs++;
      } else {
        this.saving = this.snackbar.show = true;
        this.snackbar.message = "Saving...";
        let epot = JSON.parse(JSON.stringify(this.settings));
        epot.part = epot.part == "No part" ? "" : epot.part;

        let question = epot.questions.map((question) => {
          let question_data = {
            ...question,
            media_id: question.with_media ? question.media.id : "",
            list_question: question.list_question.map((data) => data.id),
          };
          this.$delete(question_data, "media");
          return question_data;
        });

        if (
          question.some(({ list_question }) =>
            list_question.some((item) => !item)
          )
        ) {
          this.saving = false;
          this.tabs = 1;
          return this.onError({
            message: "Beberapa pertanyaan belum disimpan.",
          });
        }

        this.$delete(epot, "questions");

        if (this.$route.name == "exam-detail") {
          status = status || epot.status;
          this.$store
            .dispatch("exam/update", { ...epot, status, question })
            .then(() => {
              this.saving = false;
              this.$router.push({ name: "exam-list" });
            })
            .catch((error) => {
              this.saving = false;
              this.onError(error);
              throw new Error(error);
            });
        } else {
          this.$store
            .dispatch("exam/create", {
              ...epot,
              question,
              status,
            })
            .then(() => {
              this.saving = false;
              this.$router.push({ name: "exam-list" });
            })
            .catch((error) => {
              this.saving = false;
              this.onError(error);
              throw new Error(error);
            });
        }
      }
    },
    hideSaveButton(question) {
      if (question.is_group) {
        // return !question.id;
        return false;
      } else {
        return (
          question.list_question.every(
            (question) => question.status == "draft"
          ) || question.list_question.every((question) => !question.id)
        );
      }
    },
    colorStatus(question) {
      if (question.list_question.every((q) => !q.id)) {
        return "primary lighten-5";
      }
      // if (
      //   question.list_question.every((q) => q.status == "draft") ||
      //   question.list_question.some((q) => !q.id || q.status == "draft")
      // ) {
      //   return "primary lighten-5";
      // }
    },
    async nextCode() {
      if (this.$route.name.includes("exam-detail")) {
        return new Promise((resolve) => {
          resolve();
        });
      }
      this.code_loading = true;
      let data = {
        code: this.settings.code,
        section_id: this.settings.section,
        part: this.settings.part == "No part" ? "" : this.settings.part,
      };

      if (
        ["part", "section_id"].every(
          (key) => this.old_settings[key] == data[key]
        )
      ) {
        this.settings.code = this.old_settings.code;
        this.code_loading = false;
        return new Promise((resolve) => {
          resolve();
        });
      }

      return this.$store.dispatch("exam/next_code", data).then((res) => {
        this.code_loading = false;
        this.settings.code = res.data;
        return res;
      });
    },
    onSuccess(message) {
      Object.assign(this.snackbar, {
        show: true,
        message,
        timeout: 5000,
      });
    },
    onError(error) {
      this.snackbar.show = true;
      if (error.response) {
        let response = error.response.data;
        this.snackbar.message = Object.keys(response.validation)
          .map((key, index) => `${index + 1}. ${response.validation[key]}`)
          .join("<br/>");
      } else {
        this.snackbar.message = error.message;
      }
    },
    isMediaSet(type) {
      let disabled = this.isDisabled;
      let notset =
        this.settings.with_media ||
        (this.settings.media && this.settings.media.url);
      let isset =
        (this.settings.with_media ||
          (this.settings.media && this.settings.media.url)) &&
        this.settings.media &&
        this.settings.media.type == type;
      return (!disabled && !notset) || isset;
    },
  },
  watch: {
    "settings.section": function () {
      if (typeof this.old_settings.part == "undefined") {
        this.settings.part = this.parts[0];
        this.nextCode();
      } else if (this.old_settings.part == "") {
        this.settings.part = this.parts[0];
      }
      // if (!this.old_settings.code) return;
    },
    // "settings.part": function (val) {
    //   if (val == undefined) return;
    //   this.nextCode();
    // },
  },
};
</script>
