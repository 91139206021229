import axios from 'axios'
import url from './api'

const state = () => ({
  sections: []
})

const getters = {
  detail: state => id => state.sections.find(section => section.id == id)
}

const mutations = {
  fetch(state, payload) {
    state.sections = payload.data
  },
  create(state, payload) {
    state.sections.push(payload.data)
  },
  update(state, payload) {
    let old = state.sections.findIndex(data => data.id == payload.data.id)
    state.sections[old] = payload.data
  }
}

const actions = {
  async list({ commit }, params) {
    return new Promise((resolve, reject) => {
      params = Object.assign({ page: 1, limit: 10 }, params)
      axios.get(url.section_list, { params })
        .then(res => { commit('fetch', { data: res.data.list }); resolve(res.data) })
        .catch(error => reject(error))
    })
  },
  async detail({ commit, getters }, id) {
    return new Promise((resolve, reject) => {
      let data = getters.detail(id)
      if (data) resolve(data)
      axios.get(url.section_detail.replace('{id}', id))
        .then(res => { commit('create', { data: res.data }); resolve(res.data) })
        .catch(error => { reject(error) })
    })
  },
  async create({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(url.exam_create, data)
        .then(res => { commit('create', { data: res.data }); resolve(res.data) })
        .catch(error => { reject(error) })
    })
  },
  async update({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(url.exam_update.replace('{id}', data.id), data, {
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => { commit('update', { data: res.data }); resolve(res.data) })
        .catch(error => { reject(error) })
    })
  }
}

export default { namespaced: true, state, getters, mutations, actions }